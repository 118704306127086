import axios from 'axios'
export default {
    /**
     * 计算类
     */
    Count: {
        pageResize(that) {
            that.$nextTick(() => {
                // 获取顶部搜索内容的高度
                that.topHeight = parseInt(
                    window.getComputedStyle(that.$refs.topFixed).height
                );
                // 获取当前容器的高度
                that.mainHeight = parseInt(
                    window.getComputedStyle(that.$refs.communal).height
                );
                // 获取当前容器的高度   上下各撑开15
                that.pagingHeight =
                    parseInt(window.getComputedStyle(that.$refs.paging).height) + 15 * 2;
                // 内容的高度
                that.contentHeight =
                    that.mainHeight - that.topHeight - that.pagingHeight - 10;
            });
        },
        noPageResize(that) {
            //没有分页的容器高度
            that.$nextTick(() => {
                // 获取顶部搜索内容的高度
                that.topHeight = parseInt(
                    window.getComputedStyle(that.$refs.topFixed).height
                );
                // 获取当前容器的高度
                that.mainHeight = parseInt(
                    window.getComputedStyle(that.$refs.communal).height
                );
                // 内容的高度
                that.contentHeight =
                    that.mainHeight - that.topHeight;
            });
        },
    },

    /**
     * 表单校验类
     */
    Validate: {
        //姓名  
        checkName(rule, value, callback, text) {
            //姓名
            if (!value) {
                return callback(new Error("请输入" + text));
            } else {
                setTimeout(() => {
                    var strCheck = /^[\u4e00-\u9fa5]{0,}$/;
                    if (strCheck.test(value) == false) {
                        callback(new Error("请输正确的格式，只能是汉字！"));
                    } else {
                        callback();
                    }
                }, 500);
            }
        },
        // 手机号   11位
        checkTelphone(rule, value, callback, text) {
            //姓名
            if (!value || value.length < 11) {
                return callback(new Error("请输入11位手机号码"));
            } else {
                setTimeout(() => {
                    var strCheck = /^[0-9]{11}$/;
                    if (strCheck.test(value) == false) {
                        callback(new Error("请输正确的" + text + "格式，只能是数字！"));
                    } else {
                        callback();
                    }
                }, 500);
            }
        },
        // 座机号码  手机号
        checkPhoneAndTell(rule, value, callback, text) {
            console.log(value);
            if (!value) {
                return callback(new Error("请输入联系电话"));
            }
            setTimeout(() => {
                var isMob = /^[0-9]{11}$/;
                var strCheck = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}?$/; //带有区号的七到八位电话号码    0532-88901158   052-88901158
                if (strCheck.test(value) == false && isMob.test(value) == false) {
                    callback(new Error("请输入正确的格式"));
                } else {
                    callback();
                };
            }, 500);
        },
        // 密码   
        checkPwd(rule, value, callback, text) {
            //姓名
            if (!value) {
                return callback(new Error("请输入登录密码"));
            } else {
                setTimeout(() => {
                    // 6-12位密码（数字+字母） 可以有英文下划线 不能有空格 汉字 字符
                    // var strCheck = /^\d{6,12}$|^(?!\d+$)\w{6,12}$/;
                    // 6-12位密码（数字+字母） 包含*
                    var strCheck = /^([a-z0-9\.\*]){6,12}$/i;
                    if (strCheck.test(value) == false) {
                        callback(new Error("请输正确的格式，6-12位数字加字母！"));
                    } else {
                        callback();
                    }
                }, 500);
            }
        },
        //中英文
        checkChineseLetter(rule, value, callback, text, length) {
            if (!value) {
                if (rule.required) {
                    return callback(new Error("请输入正确的格式,中英文组合，" + text + "不能为空"));
                } else {
                    return callback();
                }
            } else {
                if (length != undefined) {
                    if (value.length < length.min) {
                        return callback(new Error("请输入正确的格式,中英文组合，" + text + "不能少于" + length.min + "字"));
                    };
                }
            }

            setTimeout(() => {
                var strCheck = /^[\u0391-\uFFE5A-Za-z]+$/;
                if (strCheck.test(value) == false) {
                    callback(new Error("请输入正确的" + text + "格式,中英文组合"));
                } else {
                    callback();
                };
            }, 500);
        },
        //身份证号
        checkIDNumber(rule, value, callback) {
            console.log();
            if (!value) {
                if (rule.required) {
                    if (!value) {
                        return callback(new Error('身份证号码不能为空!'))
                    } else if (value.length != 15 && value.length != 18) {
                        return callback(new Error('身份证号码长度有误!'))
                    }
                } else {
                    return callback();
                }
            }
            setTimeout(() => {
                var strCheck1 = /(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9]$)/;
                var strCheck = /(^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$)/;
                if (strCheck.test(value) == false && strCheck1.test(value) == false) {
                    return callback(new Error("请输入正确的格式，不能含有特殊字符！"));
                }
                if (strCheck.test(value) == true || strCheck1.test(value) == true) {
                    callback();
                }
            }, 500);
        },
        //姓名 中文
        checkName2(rule, value, callback, text) {
            //姓名
            if (!value) {
                return callback(new Error("请输入" + text + ""));
            }
            setTimeout(() => {
                var strCheck = /^[\u4e00-\u9fa5]{0,}$/;
                if (strCheck.test(value) == false) {
                    callback(new Error("请输入正确的" + text + "格式，只能是汉字！"));
                } else {
                    callback();
                }
            }, 500);
        },
        // 金额--只能是数字
        checkPirce(rule, value, callback) {
            if (!value) {
                if (rule.required) {
                    return callback(new Error('不能为空'))
                } else {
                    return callback()
                }
            }
            setTimeout(() => {
                    var val = value.toString().split('.')[1]
                    if (val !== undefined) {
                        if (val.length === 1) {
                            var strCheck = /^[0-9]+([.][0-9]{1}){0,1}$/
                            if (strCheck.test(value) === false) {
                                return callback(new Error('请输入正确的格式，只保留两位小数 ,如: 10.00或10.0'))
                            } else {
                                callback()
                            }
                        } else if (val.length === 2) {
                            var strCheck2 = /^([0-9]*)(\.[0-9]{2})?$|^(0\.[0-9]{2})$/
                            if (strCheck2.test(value) === false) {
                                return callback(new Error('请输入正确的格式，只保留两位小数 ,如: 10.00或10.0'))
                            } else {
                                callback()
                            }
                        } else {
                            return callback(new Error('请输入正确的格式，只保留两位小数 ,如: 10.00或10.0'))
                        }
                    } else {
                        return callback(new Error('请输入正确的格式，只保留两位小数 ,如: 10.00或10.0'))
                    }
                }, 500)
                // if (!value) {
                //   if (rule.required) {
                //     return callback(new Error('不能为空'))
                //   } else {
                //     return callback()
                //   }
                // }
                // setTimeout(() => {
                //   var strCheck = /^([0-9]*)(\.[0-9]{1,4})?$|^(0\.[0-9]{1,4})$/
                //   if (strCheck.test(value) === false) {
                //     return callback(new Error('最多保留四位小数 ,如: 10.0000'))
                //   } else {
                //     callback()
                //   }
                // }, 500)
        },
        // 整数或保留两位小数金额
        checkPrice2(rule, value, callback) {
            if (!value) {
                if (rule.required) {
                    return callback(new Error('不能为空'))
                } else {
                    return callback()
                }
            }
            setTimeout(() => {
                var strCheck = /^([0-9]*)(\.[0-9]{1,2})?$|^(0\.[0-9]{1,2})$/
                if (strCheck.test(value) === false) {
                    return callback(new Error('最多保留两位位小数 ,如: 10.00'))
                } else {
                    callback()
                }
            }, 500)
        },
    },
    /**
     * 公用接口方法
     */
    communalApi: {
        derivesXLS(options) { //导出
            axios.post(options.url, options.db, {
                    responseType: "arraybuffer"
                })
                .then(
                    res => {
                        const blob = new Blob([res.data], {
                            type: "application/octet-stream;charset=utf-8"
                        });
                        //   const fileName ='已收费.xlsx';
                        const fileName = res.headers["content-disposition"].match(
                            /filename=(\S*).xls/
                        )[1];
                        const elink = document.createElement("a");
                        elink.download = JSON.parse(fileName) + ".xls";
                        elink.style.display = "none";
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href);
                        document.body.removeChild(elink);
                    },
                    err => {}
                );
        },
        // 公用方法
        getTags(options, callback) {
            axios.post(options.url, options.db).then(
                res => {
                    if (res.data.code == 1) {
                        callback(res.data.data, options)
                    } else {
                        callback([], options)
                    }
                },
                err => {}
            );
        },
        // 公用表格数据接口方法
        getTableList(options, callback) {
            var list = [];
            var totals = 0;
            axios.post(options.url, options.db).then(
                res => {
                    if (res.data.code == 1) {
                        if (res.data.data != undefined && res.data.data.length > 0) {
                            list = res.data.data;
                            totals = res.data.count;
                            callback(list, totals)
                        }
                    } else {
                        list = [];
                        totals = 0;
                        callback(list, totals)
                    }
                },
                err => {}
            );
        },
        buttonMsg(options, that, callback) {
            // console.log(options)
            if (options.content1 !== undefined) { // 标题 + N行文字说明弹框
                const h = that.$createElement
                that.$msgbox({
                    title: options.title,
                    message: h('p', null, [
                        h('div', {
                            style: 'color: #ff8d1a'
                        }, options.content1),
                        h('div', {
                            style: 'color: #ff8d1a'
                        }, options.content2),
                        h('div', {
                            style: 'color: #ff8d1a'
                        }, options.content3)
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    axios.post(options.url, options.db).then(
                                        res => {
                                            if (res.data.code === '1') {
                                                that.$message({
                                                    type: 'success',
                                                    message: res.data.msg
                                                })
                                                callback() // 重新调用列表
                                            } else {
                                                that.$message.error(res.data.msg)
                                            }
                                        }
                                    )
                                }, 50)
                            }, 100)
                        } else {
                            done()
                        }
                    }
                }).then(action => {})
            } else { // 标题弹框
                that.$confirm(options.title, '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios.post(options.url, options.db).then(
                        res => {
                            if (res.data.code === '1') {
                                that.$message({
                                    type: 'success',
                                    message: res.data.msg
                                })
                                callback() // 重新调用列表
                            } else {
                                that.$message.error(res.data.msg)
                            }
                        }
                    )
                }).catch(() => {})
            }
        }
    }
}