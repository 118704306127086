<template>
    <div>
        <!-- 患者管理 -->
        <router-view/>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>