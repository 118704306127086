<template>
    <el-dialog
        title="系统提示"
        :visible="modalVisible"
        width="480px"
        top="20%"
        class="result"
        @close="closeModal"
        >
        <div style="padding:10px 60px 0">
            <el-form class="demo-ruleForm">
                <el-row>
                    <el-form-item>
                        <div>输入问诊小结: <span style="color:orange">{{resultInputError}}</span></div>
                        <el-input ref="resultInput" type="textarea" placeholder="编辑问诊小结，20-300字" minlength="20" maxlength="300" show-word-limit v-model="resultText"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <!-- <span :class="{'l-active':item.flag}" class="l-tag" v-for="(item,index) in langList1" :key="index" @click="selectLange(item,2)">{{item.title}}</span> -->
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <!-- 按钮 -->
        <div class="account-footer clearfix">
            <div><el-button @click="closeModal">取 消</el-button></div>
            <div><el-button type="primary" @click="sureResult">确 定</el-button></div>
        </div>
    </el-dialog>
</template>
<script>
import { FinishConsult } from "../../api/api";
import { scrypt } from "crypto";
export default {
    components:{
    },
    props: {
        modalVisible:Boolean,//是否显示
        modalInfo:Object,//详细对象 咨询id
        label:Array,
    },
    data() {
        return {
            resultText:'',
            resultInputError:'',//咨询小结错误提示
        }
    },
    mounted() {
    },
    methods:{  
        //确定结束咨询
        sureResult(){
            if(this.resultText.length == 0){
                this.$refs.resultInput.focus();
                this.resultInputError = '咨询小结不能为空';
            }else if(this.resultText.length < 20){
                this.$refs.resultInput.focus();
                this.resultInputError = '字数不能小于20,请重输';
            }else if(this.resultText.length > 300){
                this.$refs.resultInput.focus();
                this.resultInputError = '字数不能大于300,请重输';
            }else {
                var db = {
                    message_type : 7,
                    inputValue:this.resultText,
                };
                this.$emit('successResult',db);
            }
        },
        //结束问诊
        overGroup(){
            var db = {
                consult_id:this.modalInfo.consult_id,
                desc: this.resultText,
                label:this.label.join(','),
            }; 
            this.resultInputError = '';
            this.$axios.post(FinishConsult,db).then(res => {
                    if(res.data.code == 1){
                        this.$message.success('问诊已结束');
                    }else{
                        this.$message.error('结束问诊失败，请重试');
                    }
                }, err => {
                });   0
        },
        //关闭模态框
        closeModal(){
            this.$emit('closeModal',false);
        },
    },
}
</script>
<style lang="scss">
</style>
