
<template>
<!-- 康复管理查看 -->
    <div  class="communal system-account" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success"  style="margin-left: 0;" @click="addAlter('')" >新增</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :row-class-name="tableRowClassName"
                            :empty-text="tableTip"
                            >
                            <el-table-column
                            prop="title"
                            label="标题"
                            min-width="200"
                            show-overflow-tooltip
                            >
                            </el-table-column>
                            <el-table-column
                            v-if="lineType==2"
                            prop="type_name"
                            label="分类"
                            min-width="200"
                            show-overflow-tooltip
                            >
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="发布时间"
                            min-width="200"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right"  min-width="100">
                                <template slot-scope="scope">
                                    <el-button type="text" @click.stop="addAlter(scope.row.id)"  >编辑</el-button>
                                    <el-button type="text" @click.stop="operation(scope.row.id)" >删除</el-button>
                                </template>
                            </el-table-column>
                            </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100,200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
          <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { FoundDetailList } from "../../api/api"; //列表
import { DelFoundDetail } from "../../api/api"; //删除
export default {
  components:{
      operationBtn
  },
  data() {
    return {
      lineId: this.$route.query.lineId, //康复行id  
      lineType: this.$route.query.lineType, //类型   2为音乐
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      id: "", //保存点击行的账号id
      tableTip: "系统正在检索中…",
        // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
    };
  },
  created() {
    if(this.lineId!="" && this.lineId!=undefined && this.lineId!=null){
        this.getList();
    }
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content, //搜索内容
        line_id:this.lineId
      };
      this.$axios.post(FoundDetailList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无详情";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无详情";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无详情";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    submitForm(formName) {
      //新建账号
      this.$refs[formName].validate(valid => {
        if (valid) {
         
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addAlter(id) {//查看,修改   
      if(this.lineType==2){// 音乐新增
        this.$router.push({name:'contentRecoveryMusicAdd',query:{detailId:id,lineId:this.lineId,lineType:this.lineType}});
      }
      else{//   普通新增
        this.$router.push({name:'contentRecoveryCommonAdd',query:{detailId:id,lineId:this.lineId,lineType:this.lineType}});
      }
    },
    childByOperationBtn: function(showHide, dataCode) {
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    },
    operation(operationId){
      this.operationPsotName = "id";
      this.operationId = operationId;
      this.issue = "确定删除该条记录？";
      this.hintTxt = "删除后不可恢复";
      this.operationPsotUrl = DelFoundDetail; 
      this.showHide = true;
    },
  }
};
</script>

<style lang="scss">
.system-account {
  .login-hint {
    line-height: 40px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    padding-left: 6px;
    i {
      padding-right: 6px;
      font-size: 14px;
    }
  }
}
</style>
