<template>
    <!-- 右侧弹出页面 -->
    <div>
        <div class="mould" v-if="rightPage"> 
            <div class="mould-bg" @click="closeMould"></div>
            <div class="mould-box">
                <div class="mould-title">
                    会诊-聊天对话
                    <i class="el-icon-close" @click="closeMould"></i>
                </div>
                <!-- 未结束咨询 -->
                <div class="mould-content">
                    <div class="chat-box" v-if="item.status == 1">
                        <!-- 医生列表 -->
                        <div class="d-list" >
                            <ul>
                                <li style="color:#fff;border-top: 1px solid #ddd;">胡医生</li>
                                <li :class="{'d-active':item.flag}" v-for="(item,index) in doctorList" @click="cutDorctor(item)">{{item.doctor_name}}<span v-if="item.noread" class="no-read-user"></span></li>
                            </ul>
                        </div>
                        <div class="rigth-content" ref="rigthContent" v-loading="loading">
                            <!-- 用户列表 -->
                            <div class="u-list" ref="uList">
                                <ul>
                                    <li :class="{'u-active':item.flag}" v-for="(item,index) in userList" @click="cutUser(item)">{{item.user_name}}<span v-if="item.noread" class="no-read-user"></span></li>
                                </ul>
                            </div>
                            <!-- 用户标签 -->
                            <div class="user-tag" ref="userTag">
                                <userTags ref="userTags" :checkList="checkList" :notitle="true" @changeTags="changeTags"></userTags>
                            </div>
                            <!-- 聊天区域 -->
                            <div class="chat" ref="chat" :style="{height:chat+'px'}" >
                                <div class="show-msg" ref="showMsg" id="showMsg" :style="{height:showMsg+'px'}">
                                    <div style="color: #25bbdb;font-size: 14px;text-align: center;">{{loadingText}}</div>
                                    <div v-for="(item,index) in list">
                                        <!-- 用户发 -->
                                        <div v-if="item.isSelf == 1">
                                            
                                            <div class="no-self">
                                                <div class="name">{{item.nick}}:</div>

                                                <!-- 普通文本 -->
                                                <div class="text" v-if="item.desc == 1">{{item.text}}</div>

                                                <!-- 图片 -->
                                                <div class="img text-left" v-if="item.desc == 2"><img  @click="queryBigImg(item.text)" class="text-img" :src="item.text" alt=""></div>

                                                <!-- 语音 -->
                                                <div  v-if="item.desc == 3">
                                                    <!-- <img class="paly-img" src="../assets/image/horn-right.png" alt=""><span class="no-read"></span> -->
                                                    <audio :src="item.text" controls style="outline: none;height:30px"></audio>
                                                </div>

                                                <!-- 结果 -->
                                                <div class="text" v-if="item.desc == 5">{{item.text}} <el-button type="text" class="lb-btn" @click="resultInfo(item)">详情</el-button></div>
                                            </div>
                                        </div>

                                        <!-- 医生发 -->
                                        <div v-if="item.isSelf == 2">

                                            <div class="self">

                                                <div class="name">{{item.nick}}:</div>

                                                <!-- 普通文本 -->
                                                <div class="text" v-if="item.desc == 1 || item.desc == 7">{{item.text}}</div>

                                                <!-- 图片 -->
                                                <div class="img text-right" v-if="item.desc == 2"><img @click="queryBigImg(item.text)" class="text-img" :src="item.text" alt=""></div>

                                                <!-- 语音 -->
                                                <div class="voice" v-if="item.desc == 3">
                                                    <audio :src="item.text" controls style="outline: none;;height:30px"></audio>
                                                </div>

                                                <!-- 量表 -->
                                                <div class="lb" v-if="item.desc == 4"><el-button type="text" class="lb-btn">{{item.text}}</el-button></span></div>

                                                <!-- 转诊书 -->
                                                <div class="lb" v-if="item.desc == 8"><el-button type="text" class="lb-btn">{{item.text}}</el-button></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="replay-msg" ref="replayMsg">
                                    <div>
                                        <div ref="dragImg" class="upload-demo">
                                            <el-upload
                                                drag
                                                :action="actionUrl()"
                                                :on-success="handleAvatarSuccess"
                                                :show-file-list="false">
                                                <div class="el-upload__text" style="font-size:10px;">图片请拖至此</div>
                                            </el-upload>
                                        </div>
                                        <el-input ref="sendWidth" :style="{width:sendWidth + 'px'}" type="textarea" placeholder="编辑回复消息" class="textarea" :rows="4" v-model="inputValue" @keyup.enter.native="confirmChatInput"></el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 已结束 -->
                <div class="mould-content">
                    <div class="chat-box" v-if="item.status == 2">
                        <div class="rigth-content" ref="rigthContent" v-loading="loading" style="width:100%">
                            <!-- 聊天区域 -->
                            <!-- 用户标签 -->
                            <div class="user-tag user-tags" ref="userTag">
                                <userTags ref="userTags" :checkList="checkList" :notitle="true" @changeTags="changeTags"></userTags>
                            </div>
                            <div class="chat chats" ref="chat" :style="{height:chat+'px'}" >
                                <div class="show-msg" ref="showMsg" id="showMsg" :style="{height:showMsg+'px'}">
                                    <div style="color: #25bbdb;font-size: 14px;text-align: center;">{{loadingText}}</div>
                                    <div v-for="(item,index) in list">
                                        <!-- 用户发 -->
                                        <div v-if="item.isSelf == 1">
                                            
                                            <div class="no-self">
                                                <div class="name">{{item.nick}}:</div>

                                                <!-- 症状描述 -->
                                                <div class="text" v-if="item.desc == 1">症状描述：{{item.text}}</div>

                                                <!-- 普通文本 -->
                                                <div class="text" v-if="item.desc == 6">{{item.text}}</div>

                                                <!-- 图片 -->
                                                <div class="img text-left" v-if="item.desc == 2"><img  @click="queryBigImg(item.text)" class="text-img" :src="item.text" alt=""></div>

                                                <!-- 语音 -->
                                                <div  v-if="item.desc == 3">
                                                    <!-- <img class="paly-img" src="../assets/image/horn-right.png" alt=""><span class="no-read"></span> -->
                                                    <audio :src="item.text" controls style="outline: none;height:30px"></audio>
                                                </div>

                                                <!-- 结果 -->
                                                <div class="text" v-if="item.desc == 5">{{item.text}} <el-button type="text" class="lb-btn" @click="resultInfo(item)">详情</el-button></div>
                                            </div>
                                        </div>
                                        <!-- 医生发 -->
                                        <div v-if="item.isSelf == 2">

                                            <div class="self">

                                                <div class="name">{{item.nick}}:</div>

                                                <!-- 普通文本 -->
                                                <div class="text" v-if="item.desc == 6 || item.desc == 7">{{item.text}}</div>

                                                <!-- 图片 -->
                                                <div class="img text-right" v-if="item.desc == 2"><img @click="queryBigImg(item.text)" class="text-img" :src="item.text" alt=""></div>

                                                <!-- 语音 -->
                                                <div class="voice" v-if="item.desc == 3">
                                                    <audio :src="item.text" controls style="outline: none;;height:30px"></audio>
                                                </div>

                                                <!-- 量表 -->
                                                <div class="lb" v-if="item.desc == 4"><el-button type="text" class="lb-btn">{{item.text}}</el-button></span></div>

                                                <!-- 转诊书 -->
                                                <div class="lb" v-if="item.desc == 8"><el-button type="text" class="lb-btn">{{item.text}}</el-button></span></div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mould-foot" v-if="item.status == 1">
                    <el-button size="small" plain @click="resultModal">结束咨询</el-button>
                    <!-- <el-button size="small" type="primary" plain @click="openZhuanzhen">转诊申请</el-button> -->
                    <el-button size="small" type="primary" plain @click="selectLb">推送量表</el-button>
                    <el-button size="small" type="primary" @click="confirmChatInput">发送消息</el-button>
                </div>
            </div>
        </div>
        <!-- 推送量表 -->
        <el-dialog
            title="推送量表"
            :visible.sync="dialoglb"
            width="480px"
            top="20%"
            >
            <div style="padding:30px 60px 30px">
                <el-form label-width="80px" class="demo-ruleForm">
                    <el-row>
                        <el-checkbox-group v-model="checkedlb" @change="changeLb" style="max-height: 350px;overflow-y: auto;">
                            <el-checkbox v-for="(item,index) in scaleList" :label="item" :key="item.title" style="margin-bottom:10px">{{item.question_title}}</el-checkbox>
                        </el-checkbox-group>
                        <span v-if="checkedlbText" style="color:orange">量表不能为空，请选择</span>
                    </el-row>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialoglb=!dialoglb">取 消</el-button></div>
                <div><el-button type="primary" @click="lbConfirmChat">确 定</el-button></div>
            </div>
        </el-dialog>

        <!-- 结束咨询 -->
        <el-dialog
            title="系统提示"
            :visible.sync="resultZX"
            width="480px"
            top="20%"
            class="result"
            >
            <div style="padding:10px 60px 0">
                <el-form class="demo-ruleForm">
                    <el-row>
                       <el-form-item>
                            <div>输入问诊小结: <span style="color:orange">{{resultInputError}}</span></div>
                            <el-input ref="resultInput" type="textarea" placeholder="编辑问诊小结，20-300字" minlength="20" maxlength="300" show-word-limit v-model="resultText"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span :class="{'l-active':item.flag}" class="l-tag" v-for="(item,index) in langList1" :key="index" @click="selectLange(item,2)">{{item.title}}</span>
                        </el-form-item>
                        <!-- <div class="langes">
                            <div class="lange-box">
                                
                            </div>                                
                        </div> -->
                    </el-row>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="resultZX=!resultZX">取 消</el-button></div>
                <div><el-button type="primary" @click="sureResult">确 定</el-button></div>
            </div>
        </el-dialog>

        <!-- 大图 -->
        <el-dialog :visible.sync="dialogVisible" class="chat-imgs">
            <div class="imgs-box">
                <img class="dialog-img" width="100%" :src="dialogImageUrl" alt="" >
                <i class="el-icon-error" @click="dialogVisible=!dialogVisible"></i>
            </div>
        </el-dialog>

        <!-- 详情框 -->
        <!-- <modalInfo ref="modalInfo" :modalVisible="modalVisible" :modalInfo="modalInfo" @closeModal="closeModal"></modalInfo> -->

        <!-- 量表结果-->
        <testDetail ref="testdetail" :modalVisible="modalVisible" :modalInfo="modalInfo" :closeModal="closeModal"></testDetail>
    </div>
    
</template>
<script>
import { ConsultDoctorUser } from "../../api/api";
import { CreateTimUserSig } from "../../api/api";
import { ShowMessages } from "../../api/api";
import { MessagesCount } from "../../api/api";
import { SendGroupMsg } from "../../api/api";
import { ScaleListD } from "../../api/api";
import { publicUrl } from "../../api/api";
import { ConsultLabel } from "../../api/api";
import { FinishConsult } from "../../api/api";
import { LanguageList } from "../../api/api";
import { ScaleListChat } from "../../api/api";
import { HospitalListZZ } from "../../api/api";
import { HDoctorList } from "../../api/api";
import { AddReferral } from "../../api/api";
import { constants } from 'crypto';
import { setTimeout } from 'timers';
import userTags from '../userTags';
// import modalInfo from "../../views/dataCenter/components/modalInfo";
import testDetail from "../chat/testDetail";
import bus from "../bug";
import { truncate } from 'fs';
export default {
    components:{
        userTags,
        // modalInfo,
        testDetail
    },
    props: {
        rightPage: Boolean,
        item:Object,
    },
    data() {
        return {
            checkList:[],
            showMsg:0,//聊天高度
            chat:0,//聊天框高度
            sendWidth:'',//聊天框的宽度
            doctorList:[],//医生列表
            userList:[],//用户列表
            dialoglb:false,//推动量表框
            checkedlb:[],//被选中的量表值
            checkedlbText:false,//量表错误提示
            scaleList:[],//量表-列表
            dialogVisible:false,//图片弹框显示
            dialogImageUrl:'',//大图地址
            resultZX:false,//结束咨询弹框显示
            resultText:'',
            resultInputError:'',//咨询小结错误提示
            loading:false,
            modalVisible:false,//量表结果详情框显示
            modalInfo:{item:{},},//值
            langList:[],//常用语列表
            langList1:[],//小结
            Language:1,//默认常用语
            /**
             * webim
             */
            login:{},//登陆im用到医生id
            app: {
                sdkappid: '1400211646', // 填入创建腾讯云通讯应用获取到的 sdkappid
                accountType: '36862' // 填入在帐号体系集成配置中获取到的 accountType
            },
            list:[],//聊天列表
            pages: 1,
            count:0,//总页数
            pnums:20,//一页显示多少数量
            isNextPage: false,//是否有上一页
            loadingText:'',//加载文字
            inputValue:'',//发送文字
            message_type: 1,//默认为1
            sender_type: 2,//发送这类型2医生
            foreign_id:'',//消息类型为45时传对应的ID
            currentUser:{},//当前聊天用户
            currentDoctor:{},//当前咨询医生
            consult_id:'',//咨询id
            dlist:[],//医生列表
        };
    },
    mounted() {
        //在document添加滚动事件
        document.documentElement.addEventListener('scroll', this.handleScroll, true)
        this.list = [];
        if(this.rightPage && this.item.status == 1){
            this.getChatPersonList(this.item);
        }
    },
    methods:{  
        //移除监听事件
        removeEventListener(){
            //在组件销毁之前移除时间
            document.documentElement.removeEventListener('scroll', this.handleScroll, true);
        },
        //退出登陆
        logout(){
            //退出登录
            webim.logout();
        },
        //已结束标签查询
        overLabel(){
            this.$axios.post(ConsultLabel,{'consult_id': this.item.consult_id}).then(res => {
                if(res.data.code == 1){
                    this.$refs.userTags.checkLists = res.data.data;
                    this.getHeight();
                }else{
                    this.checkList = [];
                }
            }, err => {
            });
        },
        //上传图片地址
        actionUrl() {
            return publicUrl + "/chatapi/System/UploadChatImage";
        },
        //获取聊天的宽度
        getWidth(){
            var replayMsg = parseInt(
                window.getComputedStyle(this.$refs.replayMsg).width
            );
            var dragImg = parseInt(
                window.getComputedStyle(this.$refs.dragImg).width
            );
            this.sendWidth = replayMsg - dragImg - 2;    
            // console.log(this.sendWidth) 
        },
        //关闭模态框,传回父组件
        closeMould(){
            this.$emit('closeMould',false)
        },
        //获取聊天高度
        getHeight(){
            this.loading = true;
            setTimeout(() => {
                var box = parseInt(
                    window.getComputedStyle(this.$refs.rigthContent).height
                );
                var userTag = parseInt(
                    window.getComputedStyle(this.$refs.userTag).height
                );
                if(this.item.status == 1){
                    var uList = parseInt(
                        window.getComputedStyle(this.$refs.uList).height
                    );
                    var replayMsg = parseInt(
                        window.getComputedStyle(this.$refs.replayMsg).height
                    );
                    this.chat = box - uList - userTag - 21 ;
                    this.showMsg = this.chat - replayMsg - 2 - 19;
                }else{
                    this.chat = box - 100 - userTag;
                    this.showMsg = box - 120 - userTag;
                }
                this.loading = false;
            }, 1000);
                
            
        },
        //获取总条数
        getCount(){
            this.$axios.post(MessagesCount,{'consult_id': this.item.status == 1 ? this.currentUser.consult_id : this.item.consult_id}).then(res => {
                if(res.data.code == 1){
                    this.pages =  Math.ceil(res.data.data.count / this.pnums);
                    this.count = res.data.data.count;
                    this.getHistoryList();
                }else{
                    this.pages = 1;
                    this.count = 0;
                    this.getHistoryList();
                }
            }, err => {
            });
        },
        //获取历史消息
        getHistoryList(type){
            var that = this;
            var db = {
                consult_id: that.item.status == 1 ? that.currentUser.consult_id : that.item.consult_id,
                pages: that.pages,
                pnums: that.pnums,
            };
            this.loadingText = '加载中...'
            this.$axios.post(ShowMessages,db).then(res => {
                this.loadingText = '';
                if(res.data.code == 1){
                    this.count = res.count;
                    var arr = [];
                    if(res.data.data.length > 0){
                        var dbts = res.data.data;
                        for (var i = 0; i < dbts.length; i++) {
                            var obj = {
                                uid: dbts[i].uid,
                                nick: dbts[i].username,
                                headImg: dbts[i].headimg,
                                text: dbts[i].send_message,
                                desc: dbts[i].message_type,
                                ext: dbts[i].foreign_id,
                                isSelf: dbts[i].sender_type,
                                cid:dbts[i].consult_id,
                            }
                            //说明是患者发给医生的症状
                            if (obj.desc == 1) {
                                obj.other = dbts[i].other;
                            }
                            arr.push(obj);  
                        } 
                        if(this.item.status == 1){
                            var arrs = arr.concat(that.list);
                        }else{
                            var arrs = arr;
                        }
                        that.list = arrs;
                        if(type == undefined){
                            setTimeout(() => {
                                $("#showMsg")
                                    .children("div:last-child")[0]
                                    .scrollIntoView();
                            }, 300);
                        }
                    }    
                    this.getHeight();
                    if(this.item.status == 2){
                        this.overLabel();
                    }  
                }else{
                    this.getHeight();
                    if(this.item.status == 2){
                        this.overLabel();
                    } 
                    this.list = [];
                }
                    
            }, err => {
            });
        },
        //查询聊天医生，用户。多对多列表
        getChatPersonList(item){
            var db = {
                doctor_info_id:item.doctor_info_id,
                user_id:item.user_id,
                consult_id:item.consult_id,
            };
            this.loading = true;
            this.$axios.post(ConsultDoctorUser,db).then(res => {
                    this.loading = false;
                    if(res.data.code == 1){
                        if(res.data.data.length > 0){

                            this.doctorList = res.data.data;

                            for(var i = 0; i < res.data.data.length; i++){

                                var dbs = res.data.data[i];

                                if(dbs.flag){

                                    //默认当前医生列表  
                                    this.currentDoctor = dbs;

                                    for(var j = 0; j < dbs.users.length; j++){  
                                        if(dbs.users[j].flag){
                                            //默认当前用户列表 
                                            this.currentUser = dbs.users[j];
                                        }                                        
                                        dbs.users[j].noread = false;
                                    }
                                    this.userList = dbs.users;
                                    if(this.item.status == 1){
                                        this.getUsig(dbs.doctor_info_id);
                                    }
                                    this.getCount();
                                    this.getHeight(); 
                                };
                            };
                        }else{
                            this.doctorList = [];
                        }
                    }else{
                        this.doctorList = [];
                    }
                    this.getHeight(); 
                    this.getWidth();
                }, err => {
                });
        },
        //切换医生
        cutDorctor(db){
            var that = this;
            var arr = this.doctorList;
            
            for(var i = 0 ; i< arr.length; i++){
                arr[i].flag = false;
            };
            db.flag = !db.flag;
            var noreadNum = [];
            if(db.users.length > 0){
                for(var i = 0; i < db.users.length; i++){
                    db.users[i].flag = false;
                    db.users[0].flag = true;
                    db.users[0].noread = false;
                    this.currentUser = db.users[0];
                    if(db.users[i].noread){
                        noreadNum.push(db.users[i]);
                    }
                }
                if(noreadNum.length == 0) {
                    db.noread = false;
                }
            }
            this.userList = db.users;
            this.currentDoctor = db;
            this.list = [];
            this.getCount();
            this.Language = 1;
            //先退出登录
            webim.logout(function (resp) {
                if(resp.ErrorCode == 0){
                    // console.log('退出成功');
                    //在登录
                    that.getUsig(db.doctor_info_id);
                }
            });
        },
        //切换用户
        cutUser(db){
            var arr = this.userList;
            var noreadNum = [];
            for(var i = 0 ; i< arr.length; i++){
                arr[i].flag = false;
                if(arr[i].noread){
                    noreadNum.push(arr[i]);
                }
            }; 
            if(noreadNum.length == 0) {
                this.currentDoctor.noread = false;
            }
            db.flag = !db.flag;
            if(db.noread){
                db.noread = false;
            };
            this.currentUser = db;
            this.list = [];
            this.getCount();
        },
        //结束咨询
        resultModal(){
            this.resultZX = !this.resultZX;
            if(this.resultZX){
                this.resultText = '';
                this.Language = 2;
            }
        },
        //用户标签返回
        changeTags(val){
            this.checkList = val;
        },
        //确定结束咨询
        sureResult(){
            if(this.resultText.length == 0){
                this.$refs.resultInput.focus();
                this.resultInputError = '咨询小结不能为空';
            }else if(this.resultText.length < 20){
                this.$refs.resultInput.focus();
                this.resultInputError = '字数不能小于20,请重输';
            }else if(this.resultText.length > 300){
                this.$refs.resultInput.focus();
                this.resultInputError = '字数不能大于300,请重输';
            }else {
                this.resultInputError = '';
                var db = {
                    consult_id:this.currentUser.consult_id,
                    desc: this.resultText,
                    label:this.checkList.join(','),
                };
                this.$axios.post(FinishConsult,db).then(res => {
                    if(res.data.code == 1){
                        this.message_type = 7;
                        this.inputValue = this.resultText;
                        this.confirmChat();
                        this.resultZX = false;
                        this.Language = 1;
                    }else{
                        alert('结束咨询失败，请重试')
                    }
                }, err => {
                });
                
            }
        },
        //查看大图
        queryBigImg(src){
            this.dialogVisible = !this.dialogVisible;
            this.dialogImageUrl = src;
        },
        //获取聊天凭证
        getUsig(id){
            this.$axios.post(CreateTimUserSig,{ 'user_id': 'ls_doctor_' + id}).then(res => {
                if(res.data.code == 1){
                    this.login = res.data.data;
                    this.initIM();
                }else{
                    this.login = {};
                };  
            }, err => {
            });
        },
        //聊天初始化
        initIM(){
            var that = this;

            //当前用户身份
            var loginInfo = {
                'sdkAppID': that.app.sdkappid, //用户所属应用id,必填
                'appIDAt3rd': that.app.sdkappid, //用户所属应用id，必填
                'accountType': that.app.accountType, //用户所属应用帐号类型，必填
                'identifier': that.login.user_id, //当前用户ID,必须是否字符串类型，选填
                'identifierNick': that.item.user_name || '', //当前用户昵称，选填
                'userSig': that.login.UserSig, //当前用户身份凭证，必须是字符串类型，必填
            };
            //监听连接状态回调变化事件
            var onConnNotify = function(resp) {
                switch (resp.ErrorCode) {
                    case webim.CONNECTION_STATUS.ON:
                        webim.Log.warn('连接状态正常...');
                        break;
                    case webim.CONNECTION_STATUS.OFF:
                        webim.Log.warn('连接已断开，无法收到新消息，请检查下你的网络是否正常');
                        break;
                    default:
                        webim.Log.error('未知连接状态,status=' + resp.ErrorCode);
                        break;
                }
            };

            //监听事件
            var listeners = {
                "onConnNotify": webim.onConnNotify, //选填
                "onMsgNotify": function(msg) {
                    that.onMsgNotify(msg)
                },
            };

            //其他对象，选填
            var options = {
                'isAccessFormalEnv': true, //是否访问正式环境，默认访问正式，选填
                'isLogOn': false, //是否开启控制台打印日志,默认开启，选填
            };

            webim.login(loginInfo, listeners, options, function(resp) {
                if (resp.ActionStatus == 'OK') {
                    // console.log('登录成功'); 
                } else {
                    // console.log('登录失败');
                }
            });
        },
        //获取消息 私聊
        onMsgNotify(msg){ 
            var that = this;
            var msg;
            for (var i = 0; i < msg.length; i++) {
                that.addMsg(msg[i]); //处理新消息
            };
        },
        //接受新消息
        addMsg: function(msg) {
            var that = this;
            var fromAccount, fromAccountNick, sessType, subType, contentHtml;
            //解析消息
            //获取会话类型
            //webim.SESSION_TYPE.GROUP-群聊，
            sessType = msg.getSession().type();
            //获取消息子类型
            //会话类型为群聊时，子类型为：webim.GROUP_MSG_SUB_TYPE
            subType = msg.getSubType();
            switch (sessType) {
                case webim.SESSION_TYPE.GROUP: //群聊消息
                    switch (subType) {
                        case webim.GROUP_MSG_SUB_TYPE.COMMON:
                            contentHtml = that.convertMsgtoHtml(msg);  
                        case webim.GROUP_MSG_SUB_TYPE.TIP:
                            contentHtml = that.convertMsgtoHtml(msg);                      
                    }
            }
        },
        //解析
        convertMsgtoHtml(msg){
            var that = this;
            var html = "", fromAccount, elems, elem, type, content, desc, ext, isSelfSend;
            elems = msg.getElems();//获取消息包含的元素数组
            isSelfSend = msg.getIsSend(); //是不是自己发的消息

            fromAccount = msg.getFromAccount();//获取用户信息
            if (!fromAccount) {
                fromAccount = '';
            }
            /*拼接的数据 */
            var db = {
                uid: msg.fromAccount,//用户id
                nick: msg.fromAccountNick,//用户昵称
                headImg: msg.fromAccountHeadurl,//用户头像
                text: html,//要显示的消息
                desc: desc,//对与文字的描述，在这里统称为发送的类型
                ext: ext,//拓展字段。暂不用
                isSelf: isSelfSend ? '2' : '1',//是不是自己发的
            };
            
            for (var i in elems) {
                elem = elems[i];
                type = elem.getType();//获取元素类型
                content = elem.getContent();//获取元素对象

                switch (type) {
                    case webim.MSG_ELEMENT_TYPE.TEXT:
                        //普通文本 1
                        db.text = html += webim.convertTextMsgToHtml(content);
                        db.desc = 1;
                        db.ext = '';

                        if(this.currentUser.user_id == db.uid.substr(5)){
                            //说明是当前聊天人
                            db.nick = this.currentUser.user_name;
                            that.list.push(db)
                        }else if(this.currentDoctor.doctor_info_id == db.uid.substr(7)){
                            //说明是当前医生自己发的
                            db.nick = this.currentDoctor.doctor_name;
                            that.list.push(db)
                        }
                            
                        // var users = that.userList;//用户列表
                            
                        // for(var i = 0; i < users.length; i++){
                        //     if(!users[i].flag){
                        //         //不是当前聊天的人
                        //         if(db.uid.substr(5) == users[i].user_id){
                        //             //接收到了别人的消息
                        //             users[i].noread = true;
                        //         }  
                        //     }
                        // };
                        that.$set(that.userList,users); 
                        //页面使用set渲染也无效，就要强制列表刷新
                        that.$forceUpdate();
                        setTimeout(() => {
                            $("#showMsg")
                                .children("div:last-child")[0]
                                .scrollIntoView();
                        }, 300);

                        break;
                    case webim.MSG_ELEMENT_TYPE.CUSTOM:
                        //自定义 
                        html += webim.convertCustomMsgToHtml(content);
                        var p = html.split(',');

                        if (p.length > 0) {
                            var text = p[0].substr(5);
                            var desc = p[1].substr(6);
                            var ext = p[2].substr(5);

                            db.text = text;
                            db.desc = desc;
                            db.ext = ext;
                            console.log(this.currentUser.user_id,db.uid.substr(5));
                            console.log(this.currentDoctor.doctor_info_id,db.uid.substr(7));

                            if(this.currentUser.user_id == db.uid.substr(5)){
                                //说明是当前聊天人
                                db.nick = this.currentUser.user_name;
                                that.list.push(db)
                            }else if(this.currentDoctor.doctor_info_id == db.uid.substr(7)){
                                //说明是当前医生自己发的
                                db.nick = this.currentDoctor.doctor_name;
                                that.list.push(db)
                            }
                            
                            var users = that.userList;//用户列表
                            
                            for(var i = 0; i < users.length; i++){
                                if(!users[i].flag){
                                    //不是当前聊天的人
                                    if(db.uid.substr(8) == users[i].user_id){
                                        //接收到了别人的消息
                                        users[i].noread = true;
                                    }  
                                }
                            };
                            that.$set(that.userList,users); 
                            //页面使用set渲染也无效，就要强制列表刷新
                            that.$forceUpdate();
                            setTimeout(() => {
                                $("#showMsg")
                                    .children("div:last-child")[0]
                                    .scrollIntoView();
                            }, 300);

                            if(db.desc == 7){
                                //结束咨询
                                this.item.status = 2;
                                //退出登录
                                webim.logout()
                            }
                        }
                        break;
                    case webim.MSG_ELEMENT_TYPE.GROUP_TIP:
                        html += webim.convertTextMsgToHtml(content);
                        if(html.indexOf('进入房间') > 0){
                            //获取成员接口
                            that.consultMember();
                        }
                        break;
                }
            };
        },
        //获取成员列表
        consultMember(){
            var that = this;
            api.post(api.urlList.ConsultMember, 'POST', { 'consult_id': that.currentDoctor.consult_id }).then(res => {
                if (res.data.code == 1) {
                    this.dlist = res.data.data;
                }else{
                    this.dlist = [];
                }
            }).catch(err => {
            })
        },
        //按钮发送消息
        confirmChatInput(){
            this.message_type = 1;
            this.confirmChat();
        },
        //点击推送量表按钮
        selectLb(){
            this.dialoglb = !this.dialoglb; 
            this.getMsgList();
        },
        //获取量表
        getMsgList(){
            var that = this;
            this.$axios.post(ScaleListChat,{}).then(res => {
                    if(res.data.code == 1){
                        var info = res.data.data;
                        if(info.length > 0){ 
                            this.scaleList = info;
                        }else{
                            this.scaleList = [];
                        }                        
                    }else{
                        this.scaleList = [];
                    }
                        
                }, err => {
                });
        },  
        //选中量表
        changeLb(val){
            this.checkedlb = val;
            if(this.checkedlb.length > 0){
                this.checkedlbText = false;
            }
        },
        //量表发送消息
        lbConfirmChat(){
            console.log(this.checkedlb)
            if(this.checkedlb.length == 0){
                this.checkedlbText = true;
            }else {
                this.checkedlbText = false;

                for(var i = 0; i < this.checkedlb.length; i++){
                    this.message_type = 4;
                    this.inputValue = this.checkedlb[i].question_title;
                    this.foreign_id = this.checkedlb[i].question_id;
                    this.confirmChat();
                    if (i == this.checkedlb.length - 1) {
                        //表明是最后一个量表
                        this.dialoglb = false;
                        this.checkedlb = [];
                    }
                };
            }
        },
        //发送消息
        confirmChat(){ 
            var that = this;

            var db = {
                consult_id: that.currentUser.consult_id,
                send_message: that.inputValue,
                message_type: that.message_type,
                from_account: that.currentDoctor.doctor_info_id,
                sender_type: that.sender_type,
                foreign_id: that.foreign_id,
                to_account: that.currentUser.user_id,
            };

            this.$axios.post(SendMessage,db).then(res => {
                if(res.data.code == 1){
                    this.inputValue = '';
                    this.foreign_id = '';
                }else{
                    alert('发送失败')
                };  
            }, err => {
            });

        },
        //图片上传成功
        handleAvatarSuccess(res, file) {
            if(res.code == 1){
                this.message_type = 2;
                this.inputValue = res.data;
                this.confirmChat();
            }
        },
        //点击量表结果
        resultInfo(item){console.log(item)
            var row = {
                type : 2,
                records_id : item.ext,
                dialogTitle:item.text,
            };
            this.modalVisible = true;   
            this.$refs.testdetail.row = row; 
            this.$refs.testdetail.detail();
        },
        //关闭详情框
        closeModal(val){
            this.modalVisible = val;
        },
        //加载上一页
        handleScroll(){
            var scrollTop = $('#showMsg').scrollTop();
            if(scrollTop == 0){
                var pages = Math.ceil(this.count / this.pnums);
                if (this.pages > 1) {
                    this.pages = this.pages - 1;
                    this.getHistoryList('refresh');
                } else {
                    this.loadingText = '已经加载全部啦！'
                }
            }

        },
    },
}
</script>
<style lang="scss">
.chat-box{
    height: calc(100vh - 160px);
}
ul{
    margin: 0px;
    padding: 0px;
    font-size:14px;
    li{
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
}
.d-list{
    height: 100%;
    overflow-y: auto;
    width: 12%;
    border:1px solid #ddd;
    border-top: 0px;
    display: inline-block;
    vertical-align: top;
    li,.d-active{
        position: relative;
        padding: 8px 10px;
        border-bottom: 1px solid #ddd;
        border-top: none;
        text-align: center;
        background: #fff;
        color: #999;
        cursor: pointer;
    }
    .d-active{
        border: 1px solid #25bbdb;
        background: #25bbdb;
        color: #fff;
    }
    
}
.rigth-content{
    height: 100%;
    width: 87%;
    display: inline-block;
    vertical-align: top;
}
.user-tag,.user-tags{
    // height: 55px;
    overflow-y: auto;
    padding: 10px 10px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.user-tags{
    border: 1px solid #ddd;
    border-bottom: 0px;
}
.u-list{
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    ul{
        border: 1px solid #ddd;
        border-left: 0px;
    }
    li,.u-active{
        position: relative;
        display: inline-block;
        padding: 8px 15px;
        text-align: center;
        background: #fff;
        color: #999;
        cursor: pointer;
    }
    .u-active{
        border-bottom: 1px solid #25bbdb;
        color: #25bbdb;
    }   
}
.no-read-user{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 5px;
    height: 5px;
    display: inline-block;
    background: orange;
    border-radius: 50%;
}
.show-msg{
    padding: 10px 10px;
    overflow-y: auto;
    .name{
        color: #333;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .text{
        font-size: 12px;
        color: #666666;
    }
    .no-self,.self{
        margin-bottom: 10px;
        word-wrap: break-word;word-break: break-all;overflow: hidden;
    }
    .no-self{
        text-align: left;
    }
    .self{
        text-align: right;
    }
    .voice{
        position: relative;
        width: 150px;
        height: 35px;
        background: #f4f4f4;
        border-radius: 6px;
        .no-read{
            position: absolute;
            right: -10px;
            z-index: 100;
            top: 12px;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: red;
            margin-right: -8px;
        }
        .paly-img{
            position: absolute;
            left: 10px;
            z-index: 100;
            top: 7px;
            display: inline-block;
            width: 20px;
            height: 20px;;
        }
    }
    .img{
        .text-img{
            width: 200px;
            height: 100%;
        }
    }
}
.replay-msg {
    height: 100px;
    width: 100%;
    border: 1px solid #ddd;
    border-left:0px;
    .textarea{
        // width:84%;
        height: 100px;
        border: 0px;
        margin: 0px;
        display: inline-block;
        vertical-align: top;
    } 
    .upload-demo{
        display: inline-block;
        width: 17%;
        vertical-align: top;
    }   
}
// .lange,.langes{
//     width: 100%;
//     white-space: nowrap;
//     overflow-x: auto;
// }
// .lange-box{
//     padding: 10px 10px;
//     border-top: 1px solid #ddd;
// }
.l-tag,.l-active{
    color: #666;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 4px;
    border:1px solid #ddd;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    line-height: 15px;
}
.l-active{
    color: #25bbdb;
    border:1px solid #25bbdb;
}
// .langes{
//     white-space:initial;
//     overflow-x: hidden;
//     .lange-box{
//         border-top: 0px;
//     }
// }
.el-upload-dragger{
    height: 100px;
    width: 100px;
    line-height: 100px;
    display: inline-block;
}
.chat{
    // height: calc(100vh - 374px);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.chats{
    border: 1px solid #ddd;
}
.mould-box{
    width: 40%;
}
.mould-foot{
    padding: 20px 34px;
    text-align: right;
}
.tags-msg,.active{
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #dddddd;
    border-radius:4px;
    color: #666;
    margin-right: 15px;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
}
.active{
    border: 1px solid #25bbdb;
    color:#25bbdb;
}
.mould{
    .el-textarea__inner{
        border:none;
    }   
}
.result{
    .el-textarea__inner{
         min-height: 245px !important;
    }
}
audio:not([controls]){
    display: block !important;
}

</style>


