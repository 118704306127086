<template>
<!-- 系统设置-角色 -->
    <div  class="communal" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style"  >
            <el-row >
                <el-col :span="5" class="text-left">
                    <el-button type="success"  style="margin-left: 0;" @click="checkAdd('')">新增角色</el-button>
                </el-col>
                <el-col :span="19">
                    <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                    <el-button type="success" plain @click="serach">搜索</el-button>
                </el-col>
             </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <!--  :height="contentHeight" -->
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :row-class-name="tableRowClassName"
                            :empty-text="tableTip"
                            >
                            <el-table-column
                            prop="role_name"
                            label="角色"
                            
                            >
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="新增时间"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            label="状态"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right">
                            <template slot-scope="scope" v-if="scope.row.role_id!=1">
                            <el-button type="text" @click.stop="checkAdd(scope.row.role_id)">编辑</el-button>
                            <el-button type="text" @click.stop="operation(scope.row.role_id,'冻结')" v-if="scope.row.status=='正常'">冻结</el-button>
                            <el-button type="text" @click.stop="operation(scope.row.role_id,'解冻')" v-if="scope.row.status=='已冻结'">解冻</el-button>
                            </template>
                            </el-table-column>
                            </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100, 200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 新增账号 start -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="480px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                    <el-row>
                        <el-col :span="17">
                             <el-form-item label="角色名称" prop="roleName">
                                    <el-input v-model="ruleForm.roleName"  placeholder="请输入角色名称"></el-input>
                             </el-form-item>
                        </el-col>
                    </el-row>
                     <el-row>
                        <el-col :span="17">
                             <el-form-item label="权限" prop="treeId" >
                                 <el-input v-model="ruleForm.treeId"  v-show="false"></el-input>
                                <el-tree
                                    :data="jurisdictionList"
                                    show-checkbox
                                    default-expand-all
                                    node-key="node_id"
                                    ref="tree"
                                    highlight-current
                                    :props="defaultProps"
                                    @check="treeChange"
                                    >
                                </el-tree>
                             </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                <div><el-button type="primary"  @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 新增账号 end -->
          <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { NodeList } from "../../api/api"; //权限列表
import { EditRoleUser } from "../../api/api"; //修改
import { StartRoleUser } from "../../api/api"; //启用
import { DelRoleUser } from "../../api/api"; //冻结
import { RoleUserList } from "../../api/api"; //角色列表
import { AddRoleUser } from "../../api/api"; //新增
import { RoleUserInfo } from "../../api/api"; //详情
export default {
  components: {
    operationBtn
  },
  data() {
    var checkName = (rule, value, callback, text) => {
      this.common.Validate.checkName(rule, value, callback, "角色名称");
    };
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      dialogVisible: false,
      ruleForm: {
        roleName: "", //账号名称
        treeId: "" //权限id   半选中不携带父级id
      },
      rules: {
        roleName: [
          //5个汉字
          { required: true, trigger: "change", validator: checkName }
        ],
        treeId: [{ required: true, message: "请选择权限", trigger: "change" }]
      },
      tableTip: "系统正在检索中…",
      jurisdictionList: [], //权限列表
      defaultProps: {
        children: "list",
        label: "node_name"
      },
      dialogTitle: "", //弹框名称
      roleId: "", //点击表格保存的角色id
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
      treeIdAddParent: "" //半选中携带父级id
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
    that.getNodeList(); //权限列表
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //获取角色列表
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(RoleUserList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无角色记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无角色记录";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无角色记录";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    submitForm(formName) {
      console.log(this.ruleForm.treeId);
      //新建角色
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db = {};
          console.log(this.roleId);
          if (this.roleId != "") {
            //查看-修改
            postUrl = EditRoleUser;
            db = {
              role_id: this.roleId, //角色id
              name: this.ruleForm.roleName, //	角色名称
              node_id: this.ruleForm.treeId, //	角色权限用逗号相隔
              node_id_all: this.treeIdAddParent
            };
          } else {
            //新增
            postUrl = AddRoleUser;
            db = {
              name: this.ruleForm.roleName, //	角色名称
              node_id: this.ruleForm.treeId, //	角色权限用逗号相隔
              node_id_all: this.treeIdAddParent
            };
          }
          console.log(postUrl);
          this.$axios.post(postUrl, db).then(
            res => {
              if (res.data.code == 1) {
                this.getList();
                this.dialogVisible = false;
                // 清空点击表格角色id
                this.roleId = "";
              } else {
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getNodeList() {
      //权限列表
      this.$axios.post(NodeList, {}).then(
        res => {
          if (res.data.code == 1) {
            this.jurisdictionList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    },
    treeChange() {
      //选中的权限
      if (this.$refs.tree.getCheckedKeys().length > 0) {
        this.ruleForm.treeId = this.$refs.tree.getCheckedKeys().join(",");
      } else {
        this.ruleForm.treeId = "";
      }
      var sel = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      if (sel.length > 0) {
        this.treeIdAddParent = sel.join(",");
      } else {
        this.treeIdAddParent = "";
      }
    },
    checkAdd(roleId) {
      //查看角色   新增角色
      this.roleId = roleId;
      if (roleId != "") {
        //查看
        this.dialogTitle = "查看/修改";
        var db = {
          role_id: roleId
        };
        this.$axios.post(RoleUserInfo, db).then(
          res => {
            if (res.data.code == 1) {
              this.ruleForm.roleName = res.data.data.role_name;
              this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(res.data.data.node.split(","));
              });
              this.ruleForm.treeId = res.data.data.node;
              this.dialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      } else {
        //新增
        if (this.$refs["ruleForm"] != undefined) {
          this.$refs["ruleForm"].resetFields();
        }
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        });
        this.dialogTitle = "新增角色";
        this.dialogVisible = true;
      }
    },
    operation(operationId, operationName) {
      this.operationPsotName = "role_id";
      this.operationId = operationId; //修改组件id
      if (operationName == "冻结") {
        this.issue = "确定冻结该角色？";
        this.hintTxt = "冻结前请取消与所有账号的关联";
        this.operationPsotUrl = DelRoleUser;
      } else {
        this.issue = "确定冻解冻该角色？";
        this.hintTxt = "解冻后可正常与其他账号关联";
        this.operationPsotUrl = StartRoleUser;
      }
      this.showHide = true; //显示组件
    },
    childByOperationBtn: function(showHide, dataCode) {
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    }
  }
};
</script>

<style lang="scss">
</style>
