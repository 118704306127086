<template>
    <!-- 业务统计-交易记录 -->
    <div id="userManageList" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="8">初次咨询日期：</el-col>
                    <el-col :span="8">出生日期：</el-col>
                    <el-col :span="8">可疑症状：</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-date-picker
                            v-model="form.dateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            class="el-long"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="8">
                        <el-date-picker
                            v-model="form.birTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            class="el-long"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="7">
                        <el-select v-model="form.label" clearable placeholder="请选择" class="el-long">
                            <el-option v-for="item in tags" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-10">
                    <el-col :span="8">
                        <el-input
                            class="el-long"
                            v-model="form.txt"
                            placeholder="请输入关键字查询"
                            @keyup.enter.native="onSubmit"
                        ></el-input>
                    </el-col>
                    <el-col :span="5" :offset="10">
                        <el-button type="primary" plain @click="onSubmit">搜索</el-button>
                        <el-button type="primary" plain @click="exportLists(list)">导出</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        style="width: 100%"
                        :height="contentHeight"
                        @selection-change="handleSelectionChange"
                        v-loading="loading"
                    >
                        <!-- 勾选 -->
                        <el-table-column type="selection" width="60"></el-table-column>
                        <el-table-column prop="record_id" label="病案ID" min-width="100"></el-table-column>
                        <el-table-column prop="record_name" label="姓名" min-width="100"></el-table-column>
                        <el-table-column prop="sex" label="性别" min-width="80"></el-table-column>
                        <el-table-column prop="virtual_card_number" label="身份证号" min-width="170"></el-table-column>
                        <el-table-column prop="birthday" label="出生日期" min-width="130"></el-table-column>
                        <el-table-column prop="consult_time" label="初次咨询日期" min-width="180"></el-table-column>
                        <el-table-column
                            prop="label"
                            label="可疑症状"
                            min-width="200"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="name" label="操作" fixed="right">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    @click.stop="skipInfo(scope.row.record_id,1)"
                                >查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { LabelList } from "../../api/api";
import { UserRecordsList } from "../../api/api";
import { ExportPatientU } from "../../api/api";
import { constants } from "crypto";
import { truncate } from "fs";
export default {
    components: {},
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form: {
                dateTime: [], //初次问诊日期
                birTime: [], //出生日期
                label: "", //症状 传中文
                txt: "" //文字
            },
            tags: [], //症状标签列表
            list: [], //表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip: "系统正在检索中…",
            loading: false,
            selectUser: [],

            // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName: "" //接口键
            // 操作性按钮弹框值   end
        };
    },
    created() {
        this.getTags();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };

        this.getUserList();
    },

    methods: {
        //获取用户列表
        getUserList() {
            var that = this;
            var db = {
                from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                birthday_from_date:
                    this.form.birTime != null ? this.form.birTime[0] : "",
                birthday_to_date:
                    this.form.birTime != null ? this.form.birTime[1] : "",
                txt: this.form.txt,
                label: this.form.label, //常住地址
                pnums: this.psize,
                pages: this.pages
            };
            this.loading = true;
            this.$axios.post(UserRecordsList, db).then(
                res => {
                    this.loading = false;
                    if (res.data.code == 1) {
                        if (res.data.data.length > 0) {
                            this.list = res.data.data;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = "暂无记录";
                        }
                    } else {
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = "暂无搜索结果";
                    }
                },
                err => {
                    this.loading = false;
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = "暂无搜索结果";
                }
            );
        },
        //获取交易类型
        getTags() {
            var that = this;

            this.$axios.post(LabelList, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.tags = res.data.data;
                        } else {
                            this.tags = [];
                        }
                    } else {
                        this.tags = [];
                    }
                },
                err => {
                    this.tags = [];
                }
            );
        },
        //查看跳转
        skipInfo(id) {
            this.$router.push({
                path: "/userManage/viewInfo",
                query: { id: id }
            });
        },
        //搜索
        onSubmit() {
            this.pages = 1;
            this.getUserList();
        },
        handleCurrentChange(val) {
            this.pages = val;
            this.getUserList();
        },
        handleSizeChange() {
            this.psize = val;
            this.getUserList();
        },
        //被勾选中的用户
        handleSelectionChange(val) {
            this.selectUser = val;
        },
        //导出
        exportLists() {
            var arr = this.selectUser;
            var ids = [];
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    ids.push(arr[i].record_id);
                }

                var db = {
                    record_ids: ids.join(","),
                    from_date:
                        this.form.dateTime != null ? this.form.dateTime[0] : "",
                    to_date:
                        this.form.dateTime != null ? this.form.dateTime[1] : ""
                };
                this.$axios
                    .post(ExportPatientU, db, {
                        responseType: "arraybuffer"
                    })
                    .then(
                        res => {
                            let blob = new Blob([res.data], {
                                type: "application/vnd.ms-excel"
                            });
                            const fileName = res.headers[
                                "content-disposition"
                            ].match(/filename=(\S*).xls/)[1];
                            const elink = document.createElement("a");
                            elink.download = JSON.parse(fileName) + ".xls";
                            elink.href = window.URL.createObjectURL(blob);
                            elink.click();
                            window.URL.revokeObjectURL(elink.href);
                        },
                        err => {}
                    );
            } else {
                this.$message.info("至少选择一个用户进行导出");
            }
        }
    }
};
</script>
<style lang="scss">
.label {
    font-size: 14px;
    color: #666666;
    margin-right: 10px;
}
.search-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.table {
    height: calc(100vh - 167px - 100px - 40px - 20px);
    .props-rows {
        padding: 10px 0px 10px 45px;
        .title {
            color: #999999;
            margin-right: 20px;
        }
    }
}
.rows-tag {
    color: #666666;
    margin-right: 20px;
}
.table-item {
    font-size: 18px;
    color: #666666;
    letter-spacing: 0;
    padding: 20px 30px 10px 30px;
    span {
        margin-left: 10px;
    }
}
</style>
