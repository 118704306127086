<template>
    <div class="userTags">
        <!-- 用户标签组件 -->
        <el-row>
            <el-col :span="2" class="tag-name" v-if="!notitle">
                <div class="name">用户标签</div>
            </el-col>
            <el-col :span="notitle ? 24 : 22">
                <div class="box group">
                    <el-checkbox-group v-model="checkLists" @change="handleCheckedChange">
                        <el-checkbox v-for="item in tags" :label="item.content" :class="{checks:notitle}"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { LabelListZI } from "../api/api";
import { constants } from 'crypto';
export default {
    components:{
    },
    props: {
        checkList: Array,
        notitle:Boolean,//显示用户标签四个字
    },
    data() {
        return {
            checkLists:this.checkList,
            tags:[],//标签数组  
        };
    },
    created() {
    },
    mounted() {
        this.getTags();
    },
    methods:{
        //获取病症标签
        getTags(){
            var that = this;

            this.$axios.post(LabelListZI,{}).then(res => {
                    if(res.data.code == 1){
                        if(res.data.data != undefined && res.data.data.length > 0){
                            this.tags = res.data.data;
                        }else{
                            this.tags = [];
                        }
                    }else{
                        this.tags = [];
                    }
                        
                }, err => {
                this.tags = [];
                });
        },

        //更新后的值返回父组件
        handleCheckedChange(val){
            this.$emit('changeTags',val);
        },
    },
}
</script>
<style lang="scss">
.checks{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
}
</style>
