<template>
    <div class="details-content">
        <!-- 新增及编辑  通知 -->
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane label="新增通知" name="first" lazy>
               <div class="details-container">
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                       <el-row>
                           <el-col :span="12">
                               <el-form-item label="通知标题" prop="msgName">
                                    <el-input v-model="ruleForm.msgName" maxlength="20" placeholder="请输入标题名称，20字以内"></el-input>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                                <el-form-item label="通知内容" prop="content">
                                    <el-input v-model="ruleForm.content" v-show="false"></el-input>
                                     <div class="editor-container" style="line-height:22px;">
                                        <UE :defaultMsg=defaultMsg :config=config ref="ue" v-if="ue"></UE>
                                     </div>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24" class="text-left">
                                <el-button type="success" :loading="loading"  @click="submitForm('ruleForm')">{{loading?'发布中':'发布'}}</el-button>
                           </el-col>
                       </el-row>
                   </el-form>
               </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import UE from "../../components/editor";
import { AddNotice } from "../../api/api"; //添加
import { EditNotice } from "../../api/api"; //修改
import { NoticeInfo } from "../../api/api"; //详情
export default {
  components: {
    UE
  },
  data() {
    var checkContent = (rule, value, callback, text) => {
      // 获取富文本内容
      let contentUE = this.$refs.ue.getUEContent();
      if (contentUE != "" && contentUE != undefined) {
        this.ruleForm.content = contentUE;
        callback();
      } else {
        callback(new Error("请填写资讯内容"));
      }
    };
    return {
      id: this.$route.query.id, //通知id
      activeName: "first",
      defaultMsg: "", //富文本默认值
      config: {
        initialFrameWidth: null,
        initialFrameHeight: 350
      },
      ruleForm: {
        msgName: "", //通知标题
        content: "" //通知内容
      },
      rules: {
        msgName: [
          { required: true, message: "请输入通知标题", trigger: "change" }
        ],
        content: [
          { required: true, trigger: "change", validator: checkContent }
        ]
      },
      ue: false,
      loading: false,
    };
  },
  created() {
    if (this.id != "") {
      //详情
      var db = { id: this.id };
      this.$axios.post(NoticeInfo, db).then(
        res => {
          if (res.data.code == 1) {
            this.ruleForm.content = res.data.data.content;
            this.defaultMsg = res.data.data.content;
            this.ue = true;
            this.ruleForm.msgName = res.data.data.title;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    } else {
      this.ue = true;
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contentManageMsg" });
    },
    submitForm(formName) {
      //保存
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.id != "") {
            //修改
            postUrl = EditNotice;
            db = {
              title: this.ruleForm.msgName, //	标题
              content: this.ruleForm.content, //	内容
              id: this.id
            };
          } else {
            //添加
            postUrl = AddNotice;
            db = {
              title: this.ruleForm.msgName, //	标题
              content: this.ruleForm.content //	内容
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              this.loading = true;
              if (res.data.code == 1) {
                this.loading = false;
                this.goBack();
              } else {
                this.loading = false;
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
          
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
