<template>
<!-- 科普管理 -->
    <div  class="communal" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style" >
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success"  style="margin-left: 0;" @click="addEdit('')">新增广告</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                 <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :empty-text="tableTip"
                            :row-class-name="tableRowClassName"
                            >
                            <el-table-column
                            prop="site"
                            label="广告位置"
                            min-width="100"
                            >
                            </el-table-column>
                             <el-table-column
                            prop="title"
                            label="广告名称"
                            min-width="170"
                            show-overflow-tooltip
                            >
                            </el-table-column>
                            <el-table-column
                            label="广告缩略图"
                            min-width="300"
                            >
                            <template   slot-scope="scope">            
                               <img :src="scope.row.img"  min-width="70" height="70" max-width="70" />
                             </template>   
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="发布时间"
                            min-width="170"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="statusname"
                            label="状态"
                            min-width="100"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right" width='150'>
                            <template slot-scope="scope" >
                                <el-button type="text" @click.stop="addEdit(scope.row.id)">编辑</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'上架')" v-if="scope.row.status=='2'">上架</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'下架')" v-if="scope.row.status=='1'">下架</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'删除')" v-if="scope.row.status=='2'">删除</el-button>
                            </template>
                            </el-table-column>
                            </el-table>
                    </template>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 50, 100, 2000]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
        <!-- 弹框 -->
         <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="480px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="上传背景图" prop="uploadPictures">
                    <uoloadSingleImg 
                        v-model="ruleForm.uploadPictures" 
                        ref="uoloadSingleImg" 
                        form="上传" 
                        :uploadUrl="uploadUrl" 
                        :fileList="fileList" @childByValue="childByValue" :tip="tip" :uploadSize="2" 
                        :uoloadSingleType="'img'" 
                        :uoloadSingleStatus='uoloadSingleStatus'
                        v-if="dialogVisible"
                    ></uoloadSingleImg>        
                </el-form-item>
                <el-form-item label="跳转地址">
                    <el-row>
                        <el-col :span="12">
                            <el-select v-model="linkVal" placeholder="请选择" @change="linkChange">
                                <el-option
                                v-for="(item,index) in link"
                                :key="index"
                                :label="item.name"
                                :value="item.node_id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                               <el-select v-model="linkTypeVal" placeholder="请选择" >
                                <el-option
                                v-for="(item,index) in linkType"
                                :key="index"
                                :label="item.name"
                                :value="item.node_id">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-form-item>
              </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                <div><el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import operationBtn from "../../components/operationBtn";
import { BannerList } from "../../api/api"; //列表
import { UpperBanner } from "../../api/api"; //上架
import { LowerBanner } from "../../api/api"; //下架
import { AddBanner } from "../../api/api"; //添加
import { BannerInfo } from "../../api/api"; //详情
import { EditBanner } from "../../api/api"; //修改
import { DelBanner } from "../../api/api"; //删除
import { UploadBannerImage } from "../../api/api"; //上传图片
import { VueNodeList } from "../../api/api"; //栏目列表
import { FindNodeValueList } from "../../api/api"; //查询对应的列表数据
export default {
  components: {
    operationBtn,
    uoloadSingleImg
  },
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      content: "", //搜索框内容
      tableTip: "系统正在检索中…",
      list: [], //数据
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
      dialogVisible: false,
      dialogTitle: "",
      uploadUrl: "",
      tip: "请上传694*240尺寸的图片", //上传提示信息
      fileList: [], //默认显示图片
      uoloadSingleStatus: "",
      danger: true,
      ruleForm: {
        name: "", //名称
        uploadPictures: "" //图片
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        uploadPictures: [
          { required: true, message: "请上传广告图", trigger: "change" }
        ]
      },
      bannerId: "", //当前点击的行id
      link: [], //链接栏目列表
      linkVal: "", //栏目值
      linkType: [], //栏目对应详情值
      linkTypeVal: "" //栏目详情值
    };
  },
  created() {
    this.uploadUrl = UploadBannerImage;
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
    // 获取链接栏目列表
    this.getLink();
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(BannerList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无广告";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无广告";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无广告";
        }
      );
    },
    addEdit(id) {
      this.clearForm();
      //新增
      if (id == "") {
        //新增
        this.uoloadSingleStatus = "add";
        this.dialogTitle = "新增广告";
        this.dialogVisible = true;
      } else {
        //修改
        this.uoloadSingleStatus = "edit";
        this.dialogTitle = "广告信息";
        // 详情
        var db = {
          id: id
        };
        this.$axios.post(BannerInfo, db).then(
          res => {
            if (res.data.code == 1) {
              this.ruleForm.name = res.data.data.title;
              this.fileList = res.data.data.img;
              var imgUrl = [];
              for (var i = 0; i < this.fileList.length; i++) {
                imgUrl.push(this.fileList[i].url);
              }
              this.ruleForm.uploadPictures = imgUrl.join(",");
              this.linkVal = res.data.data.page_id;
              if (this.linkVal != "") {
                this.getLinkTypeList(this.linkVal, "edit");
              }
              this.linkTypeVal = res.data.data.page_value;
              // 地址
              this.dialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      }
      this.bannerId = id;
    },
    operation(operationId, operationName) {
      this.operationPsotName = "id";
      this.operationId = operationId; //修改组件id
      if (operationName == "上架") {
        this.operationPsotUrl = UpperBanner;
        this.issue = "确定上架该广告？";
        this.hintTxt = "上架后该广告可以用户端政策浏览";
      } else if (operationName == "下架") {
        this.operationPsotUrl = LowerBanner;
        this.issue = "确定下架该广告？";
        this.hintTxt = "下架后该广告将从用户端下隐藏";
      } else {
        //删除
        this.operationPsotUrl = DelBanner;
        this.issue = "确定删除该广告？";
        this.hintTxt = "删除后该广告将从系统内清除";
      }
      this.showHide = true; //显示组件
    },
    childByOperationBtn: function(showHide, dataCode) {
      //接收按钮式操作子组件传值
      this.showHide = showHide;
      if (dataCode == 1) {
        //接口调用成功，调用其他接口
        this.getList();
      }
    },
    childByValue(val, type) {
      this.fileList = [val];
      this.ruleForm.uploadPictures = val;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.bannerId == "") {
            //新增
            postUrl = AddBanner;
            db = {
              title: this.ruleForm.name, //标题
              img: this.ruleForm.uploadPictures, //图片地址
              node_id: this.linkVal, //	跳转的id值
              value_id: this.linkTypeVal //		参数的id值
            };
          } else {
            //修改
            postUrl = EditBanner;
            db = {
              title: this.ruleForm.name, //标题
              img: this.ruleForm.uploadPictures, //图片地址
              node_id: this.linkVal, //	跳转的id值
              value_id: this.linkTypeVal, //		参数的id值
              id: this.bannerId //广告id
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              if (res.data.code == 1) {
                this.getList();
                this.dialogVisible = false;
                this.clearForm();
              } else {
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clearForm() {
      //重置表单
      if (this.$refs["ruleForm"] != undefined) {
        this.$refs["ruleForm"].resetFields();
        this.fileList = [];
        this.ruleForm.uploadPictures = "";
        this.linkVal = "";
        this.linkTypeVal = "";
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "2") {
        return "freeze";
      }
    },
    getLink() {
      //获取链接栏目列表
      this.$axios.post(VueNodeList, {}).then(
        res => {
          if (res.data.code == 1) {
            this.link = res.data.data;
          } else {
            this.link = [];
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    },
    linkChange() {
      //选择栏目，加载分类
      console.log(this.linkVal);
      if(this.linkVal==''){
        this.linkTypeVal=''
      }else{
        this.getLinkTypeList(this.linkVal, "add");
      }
    },
    // 获取栏目对应分类
    getLinkTypeList(id, type) {
      this.$axios.post(FindNodeValueList, { node_id: id }).then(
        res => {
          if (res.data.code == 1) {
            this.linkType=[];
            // this.linkTypeVal = "";
            this.linkType = res.data.data;
          } else {
            this.linkType = [];
          }
          if (type == "add") {
            this.linkTypeVal = "";
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    }
  }
};
</script>

<style lang="scss">
</style>
