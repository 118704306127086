<template>
    <div>
        <viewInfo></viewInfo>
    </div>
</template>

<script>
import viewInfo from "./viewInfo";

export default {
 components:{
        viewInfo,
    },
}
</script>

<style>

</style>
