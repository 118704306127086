<template>
    <!-- 用户档案信息 -->
    <div id="userCase" class="userCase">
        <div>
            <div class="info">
                <h2>基本信息</h2>
                <div>
                    <el-row :gutter="24" class="mt-20">
                        <el-col :span="8">
                            <span class="label">病历号 :</span><span class="label-span">{{info.record_no}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="label">姓名 :</span><span class="label-span">{{info.record_name}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="label">出生日期 :</span><span class="label-span">{{info.birthday}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24" class="mt-20">
                        <el-col :span="8">
                            <span class="label">性别 :</span><span class="label-span">{{info.sex}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="label">身份证号 :</span><span class="label-span">{{info.card_number}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="label">常住地址 :</span><span class="label-span">{{info.area_name}}</span>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="info">
                <h2>病例信息</h2>
                <div class="box record" v-if="info.list.length > 0"  v-loading="loading">
                    <div class="record-item" v-for="(item,index) in info.list"> 
                        <div class="times">
                            <p>{{item.adddate}}</p>
                            <p>{{item.addtime}}</p>
                        </div>
                        <div class="record-info">
                            <i class="icon"></i>
                            <div class="title" >
                                <span class="lb-name" v-if="item.type == 1">{{item.title}}-{{item.doctor_name}}-{{item.hospital_name}}</span>
                                <span class="lb-name" v-if="item.type == 2">{{item.title}}-{{item.doctor_name}}-{{item.hospital_name}}</span>
                                <span class="lb-name" v-if="item.type == 3">{{item.title}}</span>
                                <span class="lb-name" v-if="item.type == 4">{{item.title}}</span>
                                <span class="lb-name" v-if="item.type == 6">{{item.title}}-{{item.hospital_name}}</span>
                                <span class="lb-name" v-if="item.type == 7">{{item.title}}-{{item.doctor_name}}-{{item.hospital_name}}</span>
                                <div class="lb-name" v-if="item.type == 10">{{item.title}}-
                                  <a :href="item.psg_img[0].url" target="_blank" style="color:#25bbdb">{{item.psg_img[0].name}}</a>
                                </div>
                                <el-button type="text" @click="recordInfo(item)" v-if="item.type != 2 && item.type !=3 && item.type != 7 && item.type != 10">详细</el-button>
                            </div>
                            <div class="result" v-if="item.message != undefined">
                                <span style="color:#999" class="font-s14">测评结果:</span><span class="result-text">{{item.message}}</span>
                            </div>
                        </div>
                    </div>                      
                </div>
                <div class="c99 font-s14" v-else style="width:80px;text-align: right;">暂无记录</div>
            </div>
        </div>
        <!-- 详情框 -->
        <testDetail ref="testdetail" :modalVisible="modalVisible" :modalInfo="modalInfo" :closeModal="closeModal"></testDetail>
    </div>
</template>
<script>
import testDetail from "../../components/chat/testDetail";
import { UserRecordInfo } from "../../api/api";
import { constants } from 'crypto';
// import chat from "../../components/chat";
export default {
    components:{
        testDetail,
        // chat
    },
    data() {
        return {
            id: this.$route.query.id,
            loading:false,

            /**
             * 用户病例档案
             */
            info:{list:[]},//病历信息

            /**
             * 详情组件的值
             */
            modalVisible: false,//传给子组件 详情框显示 modalInfo组件
            modalInfo:{item:{},},//传给子组件 详情框显示 modalInfo组件

            /**
             * 聊天组件
             */
            // rightPage:false,
            // item:{},//详情参数
        };
    },
    created(){

    },
    mounted(){
        this.getRecoredInfo();
    },
    methods:{
        //获取病历信息
        getRecoredInfo(){
            this.loading = true;
            this.$axios.post(UserRecordInfo,{'record_id':this.id}).then(res => {
                if(res.data.code == 1){
                    this.info = res.data.data;
                    this.info.list = res.data.data.list != null ? res.data.data.list : []
                    this.loading = false;
                }else{
                    this.info = {list:[]};
                    this.$message.error(res.data.msg);
                    this.loading = false;
                };
            }, err => {
            });
        },
        //点击详情
        recordInfo(item){
            if(item.type == 1){
                //咨询时
                this.rightPage = !this.rightPage;
                this.item = item;
                this.$refs.chat.getHeight();
                if(item.status == 1){
                    this.$refs.chat.getChatPersonList(this.item);
                }else{
                    this.$refs.chat.getCount(); 
                }
            }else if(item.type == 4 || item.type == 6){ 
                //量表  4自主测评 6医院下测评
                 var row = {
                    records_id : item.id,
                    dialogTitle:item.title,
                };
                this.modalVisible = true;   
                this.$refs.testdetail.row = row; 
                this.$refs.testdetail.detail();
            }
        },
        //关闭详情框
        closeModal(val){
            this.modalVisible = val;
        },
        //切换档案
        handleClick(e){
            this.userCut = e.name;
            this.getRecoredInfo();
        },

        /**
         * 聊天组件
         */
        // closeMould(val){
        //     this.rightPage = !this.rightPage;
        //     this.$refs.chat.logout();
        //     this.$refs.chat.removeEventListener();
        //     this.$refs.chat.list=[];
        // },
        // //关闭详情框
        // closeModal(val){
        //     this.modalVisible = val;
        // },
        // //用户标签返回
        // changeTags(val){
        //     this.checkList = val;
        // },
    },
    
}
</script>
<style lang="scss" scoped>
.info{
    h2{
        // margin-top: 0px;
        font-weight: normal;
        color: #25bbdb;
        font-size: 18px;
    }
    .label{
        width: 80px;
        display: inline-block;
        text-align: right;
        font-size: 14px;
        color: #999999;
    }
    .label-span{
        font-size: 14px;
        color: #666666;
        margin-left: 10px;
    }
}
.info{
    .box{
        width: 100%;
        display: inline-block;
        vertical-align: top;
        max-height: calc(100vh - 502px);
        overflow-y: auto;
    }
    .record-item{
        .times{
            display: inline-block;
            vertical-align: top;
            text-align: right;
            font-size: 14px;
            color: #666;
            padding-right: 20px;
            p{
                margin: 0px;
            }
        }
        .record-info{
            position: relative;
            width: 80%;
            display: inline-block;
            vertical-align: top;
            padding-left: 10px;
            padding-bottom: 30px;
            border-left: 1px solid #ddd;
            .title{
                width: 100%;
                margin-bottom: 10px;
            }
            .lb-name{
                font-size: 14px;
                color: #333;
            }
            button{
                margin-left: 10px;
                padding: 0px;
            }
            .result-text{
                font-size: 14px;
                color: #2A2A2A;
                margin-left: 10px;
            }
            .icon{
                position: absolute;
                top: 3px;
                left: -5px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #ddd;
            }
        }
    }
}
</style>