<template>
  <div class="print">
    <h1>{{ testDetail.print_title }}</h1>
    <h3>测评结果单</h3>
    <div v-if="testDetail.userinfo.length>0" class="header">
      <el-row :gutter="24">
        <el-col v-for="(item,index) in testDetail.userinfo" :key="index" :span="12" class="el-row">
          <span class="label">{{ item.name }}</span>
          <span class="txt">{{ item.value }}</span>
        </el-col>
      </el-row>
    </div>

    <div class="contents">
      <div class="scale-result">
          <div>
            <div v-for="(item,index) in testDetail.lines" :key="index" class="tab">
              <h5 class="title-all-bg c06 font-s16">{{ item.title }}</h5>
              <div v-if="item.column.length > 0 && item.column!=undefined" class="tabTitle c38c flex-start">
                <div v-for="(itm,index2) in item.column" :key="index2" :style="'width:'+100/item.column.length+'%'">{{ itm }}</div>
              </div>
              <template v-if="item.lines.length > 0 && item.lines!==undefined">
                <div v-for="(itm,index2) in item.lines" :key="index2" class="tabList borderee flex-start">
                  <div v-for="(valindex,index3) in itm" :key="index3" :style="'width:'+100/item.column.length+'%'">{{ valindex.val }}</div>
                </div>
              </template>
            </div>
          </div>
          <!-- 评估说明 -->
          <!-- 评估建议 -->
          <div v-if="testDetail.dbs.length>0" class="explain bg-ff">
            <div v-for="(item,index) in testDetail.dbs" :key="index">
              <p v-if="item.title!=='评估说明'" class="p-title">{{ item.title }}：</p>
              <p v-if="item.title==='评估说明'" class="p-title p-star">
                {{ item.title }}：
                <!-- 程度 -->
                <!-- <el-radio-group v-model="degree">
                  <el-radio v-for="(val,index2) in degreeList" :key="index2" :label="val.id">{{ val.title }}</el-radio>
                </el-radio-group>-->
              </p>
              <div v-for="(itm,index2) in item.db" :key="index2" style="padding:0px">
                <div class="font-s28 c99" style="padding:5px 0">{{ itm.title }}</div>
                <span v-if="item.img_flag == false" class="font-s28 c333">
                  <div v-if="item.title!='评估说明' && item.title!='评估建议'" class="p-t-10 p-b-10">{{ itm.state }}</div>
                  <!-- 富文本 -->
                  <div v-if="item.title==='评估说明' || item.title==='评估建议'">
                    {{itm.state}}
                  </div>
                  <!-- 富文本结束 -->
                </span>
                <div v-if="item.img_flag == true">
                  <el-image style="width:100%;height: 100%" :src="itm.state"></el-image>
                  <!-- <img :src="itm.state" style="height:70%;width:200px" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="foot">
      <div class="fl">
        <div class="fr">签名：</div>
      </div>
      <div class="fr" style="margin-top:30px"><span style="margin-right:5px">仙居县第五人民医院</span> 报告日期：{{ testDetail.print_date }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      testDetail: {
        userinfo: [], // 用户信息
        row: [], // 测评时间
        print_date: '',
        print_title: '',
        list: [], // 结果
        lines: [],
        dbs: []
      }
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.print {
  font-size: 16px;
  padding: 30px;
  h1 {
    font-size: 32px;
    text-align: center;
  }
  h3 {
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    margin: 10px 0px;
    margin-bottom: 20px;
  }
  .header {
    font-size: 16px;
    padding: 20px 0px;
    padding-bottom: 0px;
    border-top: 2px solid #000 !important;

    .el-row {
      margin-bottom: 10px;
    }
    .txt {
      margin-left: 5px;
    }
  }
  .header2 {
    font-size: 16px;
    padding: 20px 0px;
    padding-bottom: 0px;
    border-top: 2px solid #000 !important;

    .el-row {
      margin-bottom: 10px;
    }
    .txt {
      margin-left: 5px;
    }
  }
  .contents {
    padding: 10px 0px;
    border-bottom: 2px solid #000;
    font-size: 16px;
    h5 {
      padding-left: 10px;
      border-left: 5px solid #000;
      font-size: 16px;
    }
    .ml-15 {
      margin-left: 15px;
      font-size: 16px;
    }
  }
  .foot {
    margin-top: 20px;
    overflow: hidden;
    font-size: 16px;
  }
}

// 量表结果详情   测评管理   患者管理
.test-table {
  margin: 15px 0px;
  font-size: 16px;
  th {
    font-weight: normal;
    border: 0px test-table;
  }
  table th:last-child {
    border-top-right-radius: 6px;
  }
  table th:first-child {
    border-top-left-radius: 6px;
  }
  .table-th {
    background: red;
    border-radius: 6px;
  }
  td.item,
  th.item {
    text-align: center;
    width: 14%;
    padding: 10px 20px;
  }
  td.item {
    border-bottom: 1px solid #f4f4f4;
  }
  td.item > span {
    color: #333;
  }
  td.item > em {
    font-style: normal;
    color: #ff0000;
    font-size: 12px;
  }
}
.quesion {
  h5 {
    margin: 10px 0px;
    font-size: 16px;
  }
}
</style>
