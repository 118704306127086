<template>
  <div>
    <!-- 登录 -->
    <div class="login" v-if="!showHide">
      <!-- <img class="bg_index" src="../../assets/image/bg.png"> -->
      <div class="container">
        <div class="content">
          <div class="userinfo">
            <el-row class="account">
              <el-input placeholder="请输入账户名" v-model="username" autofocus="autofocus">
                <i slot="prefix" class="el-input__icon el-icon-account el-icon-loginsize"></i>
              </el-input>
            </el-row>

            <el-row class="pwd">
              <el-input placeholder="请输入密码" v-model="pwd" @keyup.enter.native="login" type="password">
                <i slot="prefix" class="el-input__icon el-icon-pwd el-icon-loginsize"></i>
              </el-input>
            </el-row>

            <el-row class="hint">{{ hint }}</el-row>

            <el-row class="operation">
              <el-button class="el-long login-btn" :loading="loading" @click="login">{{ loading ? "登 录 中" : "登 录" }}</el-button>
            </el-row>

            <el-row class="c99 font-s14 text-right " style="cursor: pointer;">
              <el-col :span="24">
                <el-button type="text" @click="find" style="color:#25bbdb">重置密码</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="footer">
          <p>浙江泛众网络科技有限公司提供技术及方案支持</p>
          <p>
            Copyright&copy;-现在 浙江泛众网络科技有限公司<a
              target="_blank"
              href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.7y9jhqsfz.110.778d7dc6Dm18Zb#/Integrated/index"
              style="margin-left:20px;color:#999"
              >浙ICP备：浙ICP备18049890号-2</a
            >
          </p>
          <div class="c99" style="margin-top: 10px;">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802009538" style="display: flex;align-items: center;justify-content:center;">
              <img src="https://www.fcsleep.com/img/bei.d0289dc0.png" alt="" />
              <span class="c99"> 浙公网安备 33010802009538号</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 找回密码 -->
    <findAccount v-if="showHide" :showHide="showHide" @childFindAccount="childFindAccount"></findAccount>
  </div>
</template>
<script>
import findAccount from "./../login/findAccount";
import { constants } from "crypto";
import { Login } from "../../api/api";
export default {
  components: {
    findAccount,
  },
  data() {
    return {
      from: this.$route.query.from, //退出的来源页面  判断是否显示找回密码
      loading: false,
      username: "", //账户
      pwd: "", //密码
      hint: "",
      // 找回密码是否显示
      showHide: false,
    };
  },
  created() {
    if (this.from == "退出") {
      this.showHide = false;
    } else if (this.from == "修改密码") {
      this.showHide = true;
    }
  },
  methods: {
    login() {
      var check = /^[0-9]{11}$/;
      if (this.username == "" || check.test(this.username) == false) {
        this.hint = "请输入11位手机号码";
      } else if (this.pwd == "") {
        this.hint = "请输入密码";
      } else {
        this.hint = "";
        var db = {
          username: this.username, //	用户名
          password: this.pwd, //	密码
        };
        this.$axios.post(Login, db).then(
          (res) => {
            this.loading = true;
            if (res.data.code == 1) {
              //   成功，进入页面
              this.loading = false;
              let username = res.data.data.username; //账户名称
              sessionStorage.setItem("username", JSON.stringify(username));
              // this.$router.push("/");
              if (res.data.data.node_rule_list.length > 0) {
                if (res.data.data.node_rule_list[0].list.length > 0) {
                  this.$router.push(res.data.data.node_rule_list[0].list[0].link);
                } else {
                  this.$router.push(res.data.data.node_rule_list[0].link);
                }
              }
            } else {
              this.loading = false;
              this.pwd = "";
              // this.$message.error(res.data.msg);
            }
          },
          (err) => {
            this.loading = false;
            // this.$message.error(err.data.msg);
          }
        );
      }
    },
    find() {
      this.showHide = true;
    },
    childFindAccount: function(showHide) {
      //接收按钮式操作子组件传值
      this.showHide = showHide;
    },
  },
};
</script>

<style lang="scss">
.login {
  // 登录
  min-width: 1200px;
  min-height: 880px;
  // overflow: hidden;
  position: relative;
  background: url("../../assets/image/bg.jpg") 100% no-repeat;
  background-size: cover;
  .bg_index {
    position: absolute;
    // left: 0%;
    // top: 0%;
    left: 50%;
    top: 50%;
    margin-left: -960px;
    margin-top: -440px;
  }
  .foot {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    text-align: center;
    .footer {
      display: inline-block;
      p {
        margin: 8px 0px;
        color: #999;
        font-size: 14px;
      }
    }
  }
  .container {
    position: absolute;
    // left: 10%;
    right: 20%;
    top: 28%;
    // width: 1024px;
    // left:20%;
    // top:39%;
    // width: 1200px;
    height: 356px;
    background: transparent;
  }
  .content {
    width: 360px;
    height: 296px;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    // margin-left: 603px;
    // margin-left: 755px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    .userinfo {
      .el-icon-loginsize {
        -webkit-background-size: 32px 32px;
        background-size: 32px 32px;
        margin: 11px 5px;
      }
      .account {
        .el-icon-account {
          background: url("../../assets/image/account.png") 0 0 no-repeat;
          height: 32px;
          width: 32px;
        }
        margin-top: 30px;
        border: 1px #25bbdb solid;
        border-radius: 5px;
      }
      .pwd {
        .el-icon-pwd {
          background: url("../../assets/image/pwd.png") 0 0 no-repeat;
          height: 32px;
          width: 32px;
        }
        margin-top: 15px;
        border: 1px #25bbdb solid;
        border-radius: 5px;
      }
      .el-input--prefix .el-input__inner {
        padding-left: 55px;
        font-size: 20px;
        color: #999999;
        border: none;
        height: 50px;
        line-height: 50px;
      }
      .hint {
        font-size: 14px;
        color: red;
        height: 30px;
      }
      .operation {
        .login-btn {
          background: #25bbdb;
          border-radius: 5px;
          font-size: 20px;
          color: #ffffff;
          padding: 16px 0px;
          border: none;
        }
      }
    }
  }

  // .logo{
  //     position:absolute;
  //     top:13%;
  //     left: 18%;
  //     width:500px;
  //     height: 360px;
  //     background: url('../../assets/image/logo_login.png') no-repeat;
  //     background-size: contain;
  // }
  // .content {
  //     position:absolute;
  //     top:25%;
  //     width:100%;
  //     width: 1920px;
  //     background: url('../../assets/image/bg.png') no-repeat;
  //     background-size: cover;
  //     height: 470px;
  //     .container {
  //         position:absolute;
  //         top:1%;
  //         right:20%;
  //         width: 25%;
  //         height: 56%;
  //         padding: 5% 0%;
  //         background:#fff;
  //
  //     }
  // }
}
</style>
