<template>
  <div class="details-content">
    <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first" lazy>
        <div class="details-container">
          <userInfo></userInfo>
        </div>
      </el-tab-pane>
      <el-tab-pane label="案例信息" name="second" lazy>
        <div class="details-container">
          <userCaseInfo></userCaseInfo>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import userInfo from "./userInfo";
import userCaseInfo from "./userCaseInfo";
export default {
  components: {
    userInfo,
    userCaseInfo,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "userList",
      });
    },
  },
};
</script>
<style lang="scss"></style>