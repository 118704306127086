<template>
    <div  class="communal" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container"  >
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="7">检测日期：</el-col>
                    <el-col :span="7">上传日期：</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="7">
                        <el-date-picker class="el-long" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date" value-format="yyyy-MM-dd" :editable="false"></el-date-picker>
                    </el-col>
                    <el-col :span="7">
                        <el-date-picker class="el-long" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date2" value-format="yyyy-MM-dd" :editable="false"></el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-input v-model="search.content" placeholder="请输入关键字查询"></el-input>
                    </el-col>
                    <el-col :span="3">
                        <el-button type="success" plain @click="serach">搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div class="pd-10">
                    <el-button type="success" plain @click="add">上传</el-button>
                </div>
                <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" :height="contentHeight-60"  :empty-text="tableTip">
                    <el-table-column prop="name" label="姓名" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="sex" label="性别" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="age" label="年龄" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="telphone" label="联系方式" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="card_number" label="身份证号" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="patients_time" label="检测时间" min-width="120" show-overflow-tooltip/>
                    <el-table-column prop="addtime" label="上传时间" min-width="120" show-overflow-tooltip/>
                    <el-table-column label="操作" fixed="right" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click.stop="operation(scope.row.psg_id,'查看')">查看</el-button>
                            <el-button type="text" @click.stop="operation(scope.row.psg_id,'删除')">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 50, 100, 2000]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 弹框 -->
        <el-dialog
            title="上传"
            :visible.sync="dialogVisible"
            width="500px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px"  >
                    <el-form-item label="姓名"  prop="name" :rules="{ required: true, message: '请选择', trigger: ['blur', 'change']}">
                        <el-autocomplete
                            popper-class="my-autocomplete"
                            v-model="ruleForm.name"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入"
                            class="el-long"
                            @select="handleSelect">
                            <template slot-scope="{ item }">
                                <div class="name">
                                    <span style="float: left">{{ item.username }}</span>
                                    <span style="float: left; color: #8492a6; font-size: 13px" v-if="item.card_number!=''">({{ item.card_number }})</span>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item label="联系方式"  prop="telphone">
                        <el-input v-model="ruleForm.telphone" :disabled="this.ruleForm.record_id!=''"  maxlength="11"  placeholder="请输入联系方式"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idCard">
                        <el-input v-model="ruleForm.idCard"  maxlength="18" :disabled="this.ruleForm.record_id!=''"   placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="检测日期"  prop="time" :rules="{ required: true, message: '请选择', trigger: ['blur', 'change']}">
                        <el-date-picker v-model="ruleForm.time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="el-long"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="psg报告" prop="file" :rules="{ required: true, message: '请上传', trigger: ['blur', 'change']}">
                        <el-input v-model="ruleForm.file" v-show="false"></el-input>
                        <el-upload
                            class="upload-demo i-block mr-20"
                            :action="actionUrlFile()"
                            :on-success="handleAvatarSuccessFile"
                            :before-upload="beforeAvatarUploadFile"
                            :on-remove="handleRemoveFile"
                            :file-list="ruleForm.fileList"
                            :on-preview="handlePictureCardPreviewFile"
                            :limit="1"
                            :on-exceed="handleExceed"
                            >
                            <el-button  size="small" type="primary" icon="el-icon-plus" plain >上传</el-button>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisibleFile">
                            点击下载查看：<a :href="dialogFileData.url" target="_blank">{{dialogFileData.name}}</a>
                        </el-dialog> 
                    </el-form-item>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                <div><el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 调用按钮操作性弹框 -->
        <operationBtn v-if="showHide" :parameter="parameter" :issue="issue" :hintTxt="hintTxt" :showHide="showHide" :operationId="operationId" :operationPsotUrl="operationPsotUrl" :operationPsotName="operationPsotName" @childByOperationBtn="childByOperationBtn"></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import {PsgPatientsList,FindRecordList,FindRecordPsgInfo,AddPsgPatients,EditPsgPatients,DelPsgPatients,UploadRecordPsg} from '../../api/api.js'
export default {
    components: {
        operationBtn
    },
  data() {
    var checkTelphone = (rule, value, callback, text) => {
        if(this.ruleForm.record_id!='' ){
            callback()
        }else{
            this.common.Validate.checkTelphone(rule, value, callback, "联系方式");
        }
    };
     var checkIdCard = (rule, value, callback, text) => {
        if(this.ruleForm.record_id!='' ){
            callback()
        }else{
            this.common.Validate.checkIDNumber(rule, value, callback, "身份证号");
        }
    };
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      search:{
          date:'',
          date2:'',
          content:''
      },
      list:[],
      tableTip: "系统正在检索中…",
      //   弹框
      addOrEdit:'',//新增add还是编辑edit 
      dialogVisible:false,
      recordList:[],//用户信息
      dialogVisibleFile:false,//查看文件
      dialogFileData:'',//查看文件地址
      operationId:'',//操作行id
      ruleForm:{
        name:'',
        record_id:'',  //提交用
        telphone:'',
        idCard:'',
        time:'',
        fileList:[],
        file:'',//提交文件  json格式
      },
      rules:{
        telphone: [
          { required: true, trigger: "change", validator: checkTelphone }
        ],
        idCard: [{ required: true, trigger: "change", validator: checkIdCard }]
      },
       // 操作性按钮弹框值   start
        issue: "",
        hintTxt: "",
        showHide: false,
        operationId: "",
        operationPsotUrl: "",
        operationPsotName: "",
        parameter:'',//参数
        // 操作性按钮弹框值   end
    };
  },
  mounted() {
    var that=this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
       that.common.Count.pageResize(that);
    };
  },
  created () {
    this.getList();
    this.getRecordList()
  },
  methods: {
    getRecordList(){//用户信息
        this.$axios.post(FindRecordList, {}).then(
            res => {
                if (res.data.code == 1) {
                    this.recordList = res.data.data;
                }else{
                     this.recordList =[]
                } 
            },
            err => {}
        );
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach(){
      this.pages='1'
      this.getList();
    },
    getList(){
        var from_date = "";
        var to_date = "";
        if (this.search.date != "" && this.search.date != null) {
            if (this.search.date.length>0){
                from_date = this.search.date[0];
                to_date = this.search.date[1];
            }
        }
        var add_from_date = "";
        var add_to_date = "";
        if (this.search.date2 != "" && this.search.date2 != null) {
            if (this.search.date2.length>0){
                add_from_date = this.search.date2[0];
                add_to_date = this.search.date2[1];
            }
        }
        var db = {
            from_date:from_date,
            to_date:to_date,
            add_from_date:add_from_date,
            add_to_date:add_to_date,
            txt:this.search.content,
            pages: this.pages,
            pnums: this.psize,
        }
        this.$axios.post(PsgPatientsList, db).then(
            res => {
                if (res.data.code == 1) {
                    if (res.data.data != undefined && res.data.data.length > 0) {
                        this.list = res.data.data;
                        this.totals = res.data.count;
                    } else {
                        this.list = [];
                        this.totals = res.data.count;
                        if (operationName == undefined) {
                            this.tableTip = "暂无记录";
                        } else {
                            this.tableTip = "暂无搜索结果";
                        }
                    }
                } else {
                    this.list = [];
                    this.tableTip = "暂无记录";
                }
            },
            err => {
                this.list = [];
                this.tableTip = "暂无记录";
            }
        );
    },
    operation(id,operationName){
        this.operationId = id;
        this.operationPsotName = "psg_id";
        if (operationName == "删除") {
            this.parameter = {
                psg_id:id
            };
            this.issue = "确定删除该报告？";
            this.hintTxt = "删除后该报告从目标用户的档案中消失";
            this.operationPsotUrl = DelPsgPatients;
            this.$nextTick(()=>{
                this.showHide = true;
            })
        } else {
            //查看
            this.addOrEdit='edit'
            this.$axios.post(FindRecordPsgInfo, {psg_id:id}).then(
                res => {
                    if (res.data.code == 1) {
                        var form={
                            name:res.data.data.username,
                            record_id:res.data.data.record_id,
                            telphone:res.data.data.telphone,
                            idCard:res.data.data.card_number,
                            time:res.data.data.patients_time,
                            fileList:res.data.data.psg_img,
                            file:JSON.stringify(res.data.data.psg_img),
                        }
                        this.ruleForm=form
                        this.dialogVisible = true;
                    }else{
                    } 
                },
                err => {}
            );
        }
    },
    childByOperationBtn: function (showHide, dataCode) {
        this.showHide = showHide;
        if (dataCode == 1) {
            this.getList();
        }
    },
    add(){//新增
        this.addOrEdit='add'
        this.clearFrom()
        this.dialogVisible=true
    },
    clearFrom(){
     var form={
        name:'',
        record_id:'',  //提交用
        telphone:'',
        idCard:'',
        time:'',
        fileList:[],
        file:'',//提交文件  json格式
      }
      this.ruleForm=form
    },
    submitForm(formName) {//添加 修改
        this.$refs[formName].validate((valid) => {
            if (valid) {
              var db={
                record_id :this.ruleForm.record_id,// 档案id
                psg_img  :this.ruleForm.file,//图片文件
                patients_time   :this.ruleForm.time,//监测时间
                card_number  :this.ruleForm.idCard,//身份证
                name  :this.ruleForm.name,//名称
                telphone :this.ruleForm.telphone,// 联系方式
              }
              var postUrl=''
              if(this.addOrEdit=='add'){
                postUrl=AddPsgPatients
              }else{
                db.psg_id=this.operationId
                postUrl=EditPsgPatients
              }
               this.$axios.post(postUrl, db).then(
                    res => {
                        if (res.data.code == 1) {
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            this.dialogVisible=false
                            this.getList()
                        }else{
                            this.$message.error(res.data.msg);
                            this.dialogVisible=false
                        } 
                    },
                    err => {}
                );
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
    querySearch(queryString, cb) {
        var restaurants = this.recordList;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
        if(results.length==0){
            this.ruleForm.telphone=''
            this.ruleForm.idCard=''
            this.ruleForm.record_id=''
        }
    },
    createFilter(queryString) {
        return (restaurant) => {
            return (restaurant.username.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    },
    handleSelect(item) {
        this.ruleForm.name=item.username
        this.ruleForm.telphone=item.telphone
        this.ruleForm.idCard=item.card_number
        this.ruleForm.record_id=item.record_id
    },
    handleRemoveFile(file, fileList){//移除
        var arr=this.ruleForm.fileList
        for(var j=0;j<arr.length;j++){
            if(file.time==arr[j].time){
                this.ruleForm.fileList.splice(j, 1);
            }
        }
        this.setFileVal()
    },
    handleExceed(files, fileList){
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleAvatarSuccessFile(res, file) {//上传成功
        if(res.code==1){
            var jsonFile={
                url:res.data,
                name:file.name
            }
            this.ruleForm.fileList.push(jsonFile)
        }
        this.setFileVal()
    },
    handlePictureCardPreviewFile(file) { //查看文件
        this.dialogFileData = file;
        this.dialogVisibleFile = true;
    },
    setFileVal(){
        if(this.ruleForm.fileList.length>0){
            this.ruleForm.file=JSON.stringify(this.ruleForm.fileList)
        }else{
            this.ruleForm.file=''
        }
    },
    actionUrlFile() {
        return UploadRecordPsg;
    },
    beforeAvatarUploadFile(file) {
        const extension =
            file.name.substring(file.name.lastIndexOf(".")) === ".xls";
        const extension2 =
            file.name.substring(file.name.lastIndexOf(".")) === ".xlsx";
        const extension3 =
            file.name.substring(file.name.lastIndexOf(".")) === ".XLS";
        const extension4 =
            file.name.substring(file.name.lastIndexOf(".")) === ".XLSX";
        const extension5 =
            file.name.substring(file.name.lastIndexOf(".")) === ".doc";
        const extension6 =
            file.name.substring(file.name.lastIndexOf(".")) === ".pdf";
        const extension7 =
            file.name.substring(file.name.lastIndexOf(".")) === ".docx";
        if (!extension && !extension2 && !extension3 && !extension4 && !extension5 && !extension6 && !extension7) {
            this.$message({
                message: "只能上传excel、doc、pdf 格式!",
                type: "warning"
            });
        }
        return (extension || extension2|| extension3 || extension4 || extension5 || extension6 || extension7);
    },
  }
};
</script>

<style lang="scss">

</style>
