import axios from 'axios';
// export const publicUrl = '/api'; //本地
export const publicUrl = 'https://www.fcsleep.com.cn/api/public/index.php'; //线上
//数据大屏
export const ScreeApi = `${publicUrl}/screenapi/Overview/ScreeApi`; //数据汇总接口

//数据中心
export const DataAggregation = `${publicUrl}/adminapi/Overview/DataAggregation`; //数据汇总接口
export const DynamicStatis = `${publicUrl}/adminapi/Overview/DynamicStatis`; //动态统计折线图接口
export const DiseaseSummary = `${publicUrl}/adminapi/Overview/DiseaseSummary`; //疾病分类数量接口
export const BusinessList = `${publicUrl}/adminapi/Overview/BusinessList`; //疾病分类数量接口
export const RegistUserList = `${publicUrl}/adminapi/Overview/RegistUserList`; //注册用户接口
export const FreezeO = `${publicUrl}/adminapi/Overview/Freeze`; //用户冻结接口
export const UnFreezeO = `${publicUrl}/adminapi/Overview/UnFreeze`; //用户解冻接口
export const ShowUserBasicInfo = `${publicUrl}/adminapi/Overview/ShowUserBasicInfo`; //注册用户查看基本信息接口
export const UserRecordsListO = `${publicUrl}/adminapi/Overview/UserRecordsList`; //注册用户查看病案信息接口（档案列表）
export const UserRecordInfo = `${publicUrl}/adminapi/Overview/UserRecordInfo`; //注册用户查看病案信息接口（详细信息）
export const TransactionRecords = `${publicUrl}/adminapi/Overview/TransactionRecords`; //交易记录接口
export const FreezTrader = `${publicUrl}/adminapi/Overview/FreezTrader`; //冻结交易接口
export const UnFreezTrader = `${publicUrl}/adminapi/Overview/UnFreezTrader`; //解冻交易接口
export const RefundTrader = `${publicUrl}/adminapi/Overview/RefundTrader`; //交易退款接口
export const ApprovalOrder = `${publicUrl}/adminapi/Overview/ApprovalOrder`; //审核通过接口
export const ExportTraders = `${publicUrl}/adminapi/Overview/ExportTraders`; //导出交易记录列表接口
export const UserAreaList = `${publicUrl}/adminapi/General/UserAreaList`; //平台统计注册用户常住地址下拉接口
export const HomeDoctorList = `${publicUrl}/adminapi/General/HomeDoctorList`; //平台统计注册用户家庭医生下拉接口
export const TreaderType = `${publicUrl}/adminapi/General/TreaderType`; //平台统计交易记录交易类型下拉框接口
export const OrderStatusO = `${publicUrl}/adminapi/General/OrderStatus`; //平台统计交易记录订单状态下拉框接口

//业务统计
export const DataAggregationY = `${publicUrl}/adminapi/Business/DataAggregation`; //数据汇总接口
export const DynamicStatisY = `${publicUrl}/adminapi/Business/DynamicStatis`; //动态统计折线图接口
export const DiseaseSummaryY = `${publicUrl}/adminapi/Business/DiseaseSummary`; //疾病分类数量接口
export const BusinessListY = `${publicUrl}/adminapi/Business/BusinessList`; //业务统计接口
export const TransactionRecordsY = `${publicUrl}/adminapi/Business/TransactionRecords`; //交易记录接口
export const TreaderTypeY = `${publicUrl}/adminapi/General/TreaderType`; //业务统计交易记录交易类型下拉框接口
export const OrderStatusY = `${publicUrl}/adminapi/General/OrderStatus`; //业务统计交易记录订单状态下拉框接口

//患者管理 
export const UserRecordsList = `${publicUrl}/adminapi/Patient/UserRecordsList`; //患者列表接口
export const ExportPatientU = `${publicUrl}/adminapi/Patient/ExportPatient`; //导出患者管理列表接口
export const LabelList = `${publicUrl}/adminapi/General/LabelList`; //患者管理可疑症状下拉接口

//医生
export const DoctorList = `${publicUrl}/adminapi/Doctor/DoctorList`; //已入驻医生列表接口
export const DoctorFreeze = `${publicUrl}/adminapi/Doctor/Freeze`; //医生冻结接口
export const DoctorUnFreeze = `${publicUrl}/adminapi/Doctor/UnFreeze`; //医生解冻接口
export const DoctorInfo = `${publicUrl}/adminapi/Doctor/DoctorInfo`; //医生基本信息查询接口
export const UpdateDoctorInfo = `${publicUrl}/adminapi/Doctor/UpdateDoctorInfo`; //修改医生信息接口
export const DeleteImg = `${publicUrl}/adminapi/Doctor/DeleteImg`; //删除图片接口
export const AddDoctor = `${publicUrl}/adminapi/Doctor/AddDoctor`; //新增医生接口
export const DoctorAccount = `${publicUrl}/adminapi/Doctor/DoctorAccount`; //医生账户接口
export const DeleteAccount = `${publicUrl}/adminapi/Doctor/DeleteAccount`; //删除医生账户接口
export const DoctorTraderList = `${publicUrl}/adminapi/Doctor/DoctorTraderList`; //交易记录接口
export const ExportDoctorTraders = `${publicUrl}/adminapi/Doctor/ExportDoctorTraders`; //医生交易数据导出接口
export const CheckCount = `${publicUrl}/adminapi/Doctor/CheckCount`; //待审核数量接口
export const CheckList = `${publicUrl}/adminapi/Doctor/CheckList`; //待审核医生列表接口
export const UpdateHospital = `${publicUrl}/adminapi/Doctor/UpdateHospital`; //.待审核编辑医生信息接口（审核接口）
export const DoctorCheckList = `${publicUrl}/adminapi/Doctor/DoctorCheckList`; //审批记录接口
export const PositionList = `${publicUrl}/adminapi/General/PositionList`; //医生管理职称列表下拉接口
export const OrganizationList = `${publicUrl}/adminapi/General/OrganizationList`; //医生管理机构下拉接口
export const PermanentAddress = `${publicUrl}/adminapi/General/PermanentAddress`; //医生管理负责区域下拉接口
export const HospitalListD = `${publicUrl}/adminapi/General/HospitalList`; //医生管理医院名称下拉接口
export const UploadHeadImage = `${publicUrl}/adminapi/System/UploadHeadImage`; //上传头像接口
export const UploadPosition = `${publicUrl}/adminapi/System/UploadPosition`; //医生上传职称附件
export const UploadCert = `${publicUrl}/adminapi/System/UploadCert`; //上传证书图片接口
export const UploadCard = `${publicUrl}/adminapi/System/UploadCard`; //上传身份证照片接口

//登录  
export const Login = `${publicUrl}/adminapi/Login/Login`; //登录
export const LogOut = `${publicUrl}/adminapi/Login/LogOut`; //退出登录
export const EditUserPassword = `${publicUrl}/adminapi/Setup/EditUserPassword`; //登录状态修改密码
export const RestUserPassword = `${publicUrl}/adminapi/Login/RestUserPassword`; //重置密码
export const GetPhoneCode = `${publicUrl}/adminapi/Login/GetPhoneCode`; //获取手机验证码
export const FindUserRole = `${publicUrl}/adminapi/Setup/FindUserRole`; //导航-查询用户的角色
export const FindUserHospitalName = `${publicUrl}/adminapi/Setup/FindUserHospitalName`; //查询当前登录用户的中心名称
export const CutHospital = `${publicUrl}/adminapi/Setup/CutHospital`; //切换中心

// 系统设置
// 多中心
export const HospitalList = `${publicUrl}/adminapi/Setup/HospitalList`; //中心列表
export const StartHospital = `${publicUrl}/adminapi/Setup/StartHospital`; //启动中心
export const DelHospital = `${publicUrl}/adminapi/Setup/DelHospital`; //冻结中心
export const HospitalInfo = `${publicUrl}/adminapi/Setup/HospitalInfo`; //中心详情
export const BusinessesList = `${publicUrl}/adminapi/Setup/BusinessesList`; //业务权限列表
export const EditHospital = `${publicUrl}/adminapi/Setup/EditHospital`; //修改中心信息
export const AddHospital = `${publicUrl}/adminapi/Setup/AddHospital`; //添加中心
export const UploadHispitalLogo = `${publicUrl}/adminapi/System/UploadHispitalLogo`; //上传中心logo

// 账号
export const EditAccountUser = `${publicUrl}/adminapi/Setup/EditAccountUser`; //修改管理账号
export const AccountList = `${publicUrl}/adminapi/Setup/AccountList`; //账号管理列表
export const AddAccount = `${publicUrl}/adminapi/Setup/AddAccount`; //添加管理账号
export const DelAccountUser = `${publicUrl}/adminapi/Setup/DelAccountUser`; //冻结账户
export const StartAccountUser = `${publicUrl}/adminapi/Setup/StartAccountUser`; //启用账号
export const AccountUserInfo = `${publicUrl}/adminapi/Setup/AccountUserInfo`; //后台管理员账号详情
export const RoleUserLists = `${publicUrl}/adminapi/Setup/RoleUserLists`; //角色列表不带删除

// 角色
export const NodeList = `${publicUrl}/adminapi/Setup/NodeList`; //权限列表
export const EditRoleUser = `${publicUrl}/adminapi/Setup/EditRoleUser`; //修改角色信息
export const RoleUserInfo = `${publicUrl}/adminapi/Setup/RoleUserInfo`; //角色详情
export const StartRoleUser = `${publicUrl}/adminapi/Setup/StartRoleUser`; //启用角色
export const DelRoleUser = `${publicUrl}/adminapi/Setup/DelRoleUser`; //冻结角色
export const AddRoleUser = `${publicUrl}/adminapi/Setup/AddRoleUser`; //新增角色
export const RoleUserList = `${publicUrl}/adminapi/Setup/RoleUserList`; //角色列表

// 量表价格设置
export const ScaleTitleLists = `${publicUrl}/adminapi/Setup/ScaleTitleLists` // 量表列表
export const ScaleTitleInfo = `${publicUrl}/adminapi/Setup/ScaleTitleInfo` // 量表详情
export const EditScaleTitle = `${publicUrl}/adminapi/Setup/EditScaleTitle` // 修改设置量表信息


//内容管理 
//平台管理
export const IntroInfo = `${publicUrl}/adminapi/Intro/IntroInfo` // 详情
export const IntroSave = `${publicUrl}/adminapi/Intro/IntroSave` // 简介保存
// 科普
export const DelArticle = `${publicUrl}/adminapi/Article/DelArticle`; //删除内容科普信息
export const EditArticle = `${publicUrl}/adminapi/Article/EditArticle`; //修改内容科普信息
export const ArticleList = `${publicUrl}/adminapi/Article/ArticleList`; //内容科普管理列表
export const AddArticle = `${publicUrl}/adminapi/Article/AddArticle`; //添加内容科普内容
export const ArticleInfo = `${publicUrl}/adminapi/Article/ArticleInfo`; //内容科普详情
export const UploadNewsImg = `${publicUrl}/adminapi/System/UploadNewsImg`; //上传科普图片
// 康复
export const FoundLineList = `${publicUrl}/adminapi/Recure/FoundLineList`; //康复管理列表
export const AddFoundLine = `${publicUrl}/adminapi/Recure/AddFoundLine`; //添加康复治疗
export const FoundLineInfo = `${publicUrl}/adminapi/Recure/FoundLineInfo`; //康复治疗详情
export const EditFoundLine = `${publicUrl}/adminapi/Recure/EditFoundLine`; //修改康复治疗内容
export const LowerFoundLine = `${publicUrl}/adminapi/Recure/LowerFoundLine`; //下架康复
export const UpperFoundLine = `${publicUrl}/adminapi/Recure/UpperFoundLine`; //上架康复治疗
export const DelFoundLine = `${publicUrl}/adminapi/Recure/DelFoundLine`; //删除康复治疗
export const FoundDetailList = `${publicUrl}/adminapi/Recure/FoundDetailList`; //康复治疗详情列表
export const AddFoundDetail = `${publicUrl}/adminapi/Recure/AddFoundDetail`; //上传康复治疗详情（使用编辑器）--新增
export const FoundDetailInfo = `${publicUrl}/adminapi/Recure/FoundDetailInfo`; //康复治疗详情（使用编辑器）
export const EditFoundDetail = `${publicUrl}/adminapi/Recure/EditFoundDetail`; //修改康复治疗信息（使用编辑器）
export const AddFoundDetailMusic = `${publicUrl}/adminapi/Recure/AddFoundDetailMusic`; //上传康复治疗详情（音乐）
export const FoundDetailMusicInfo = `${publicUrl}/adminapi/Recure/FoundDetailMusicInfo`; //康复治疗详情（音乐）
export const EditFoundDetailMusic = `${publicUrl}/adminapi/Recure/EditFoundDetailMusic`; //修改康复治疗信息（音乐）
export const DelFoundDetail = `${publicUrl}/adminapi/Recure/DelFoundDetail`; //删除康复治疗信息（音乐及编辑器）
export const UploadFoundImage = `${publicUrl}/adminapi/System/UploadFoundImage`; //上传康复治疗的图片
export const UploadFoundMusic = `${publicUrl}/adminapi/System/UploadFoundMusic`; //上传康复治疗音乐
export const MusicType = `${publicUrl}adminapi/Recure/MusicType`; //音乐分类

// 广告
export const AddBanner = `${publicUrl}/adminapi/Banner/AddBanner`; //发布广告添加图片
export const BannerInfo = `${publicUrl}/adminapi/Banner/BannerInfo`; //广告图片详情
export const EditBanner = `${publicUrl}/adminapi/Banner/EditBanner`; //修改图片
export const LowerBanner = `${publicUrl}/adminapi/Banner/LowerBanner`; //下架图片
export const DelBanner = `${publicUrl}/adminapi/Banner/DelBanner`; //删除图片
export const UpperBanner = `${publicUrl}/adminapi/Banner/UpperBanner`; //上架图片
export const BannerList = `${publicUrl}/adminapi/Banner/BannerList`; //广告管理列表
export const UploadBannerImage = `${publicUrl}/adminapi/System/UploadBannerImage`; //上传广告图片
export const VueNodeList = `${publicUrl}/adminapi/Banner/VueNodeList`; //栏目列表
export const FindNodeValueList = `${publicUrl}/adminapi/Banner/FindNodeValueList`; //查询对应的列表数据

// 通知
export const LowerNotice = `${publicUrl}/adminapi/Article/LowerNotice`; //下架通知
export const NoticeList = `${publicUrl}/adminapi/Article/NoticeList`; //通知管理列表
export const AddNotice = `${publicUrl}/adminapi/Article/AddNotice`; //添加通知信息
export const NoticeInfo = `${publicUrl}/adminapi/Article/NoticeInfo`; //通知消息详情
export const EditNotice = `${publicUrl}/adminapi/Article/EditNotice`; //修改通知消息
export const UpperNotice = `${publicUrl}/adminapi/Article/UpperNotice`; //上架通知
export const DelNotice = `${publicUrl}/adminapi/Article/DelNotice`; //删除通知

//业务管理 
// 挂号
export const RegisterList = `${publicUrl}/adminapi/Treatment/RegisterList`; //挂号列表
export const DoctorListT = `${publicUrl}/adminapi/Treatment/DoctorList`; //预约专家列表
export const AgreeRegister = `${publicUrl}/adminapi/Treatment/AgreeRegister`; //同意挂号
export const RefuseRegister = `${publicUrl}/adminapi/Treatment/RefuseRegister`; //拒绝挂号
export const StatusList = `${publicUrl}/adminapi/Treatment/StatusList`; //状态列表
export const ExportRegister = `${publicUrl}/adminapi/Treatment/ExportRegister`; //导出
// 转诊
export const AgreeReferral = `${publicUrl}/adminapi/Treatment/AgreeReferral`; //同意操作
export const ReferralList = `${publicUrl}/adminapi/Treatment/ReferralList`; //转诊列表
export const RefuseReferral = `${publicUrl}/adminapi/Treatment/RefuseReferral`; //拒绝操作
export const ReferralStatusList = `${publicUrl}/adminapi/Treatment/ReferralStatusList`; //状态
export const ExportReferral = `${publicUrl}/adminapi/Treatment/ExportReferral`; //导出
// 咨询
export const ConsultList = `${publicUrl}/adminapi/Consult/ConsultList`; //咨询列表接口
export const ConsultLabel = `${publicUrl}/adminapi/Consult/ConsultLabel`; //已结束咨询标签查询
export const ConsultDoctorUser = `${publicUrl}/adminapi/Consult/ConsultDoctorUser`; //未结束咨询-》查询医生用户信息
// export const OrderStatus = `${publicUrl}/adminapi/General/OrderStatus`; //订单状态接口
export const OrderStatus = `${publicUrl}/adminapi/Consult/OrderStatus`; //订单状态接口
export const DealList = `${publicUrl}/adminapi/General/DealList`; //未处理已处理combobox接口
export const LabelListZI = `${publicUrl}/adminapi/Consult/LabelList`; //标签接口
export const ConsultDoctorLists = `${publicUrl}/adminapi/Consult/ConsultDoctorLists`; //咨询医生id

//聊天
export const ReferralUpdate = `${publicUrl}/chatapi/Chat/ReferralUpdate`; //聊天信息中修改转诊信息
export const AddReferral = `${publicUrl}/chatapi/Chat/AddReferral`; //聊天中新增预约转诊
export const ReferralInfo = `${publicUrl}/chatapi/Chat/ReferralInfo`; //聊天信息中查询转诊书信息
export const CreateTimUserSig = `${publicUrl}/chatapi/Usersig/CreateTimUserSig`; //IM获取UserSig
export const UploadChatVoice = `${publicUrl}/chatapi/System/UploadChatVoice`; //聊天上传语音接口
export const UploadChatImage = `${publicUrl}/chatapi/System/UploadChatImage`; //聊天上传图片接口
export const SendGroupMsg = `${publicUrl}/chatapi/Group/SendGroupMsg`; //发送群聊消息接口
export const ShowMessages = `${publicUrl}/chatapi/Chat/ShowMessages`; //消息读档接口
export const SendMessage = `${publicUrl}/chatapi/Chat/SendMessage`; //私聊消息接口
export const MessagesCount = `${publicUrl}/chatapi/Chat/MessagesCount`; //查询历史聊天数据总数接口
export const VoiceUpdate = `${publicUrl}/chatapi/Chat/VoiceUpdate`; //播放语音时，更新语音状态接口
export const ScaleListChat = `${publicUrl}/findapi/Index/ScaleList`; //聊天中量表列表接口
export const HospitalListZZ = `${publicUrl}/doctorapi/Auth/HospitalList`; //医院列表
export const HDoctorList = `${publicUrl}/doctorapi/Auth/HDoctorList`; //通过医院选择医生
export const ConsultMember = `${publicUrl}/doctorapi/Group/ConsultMember`; //会诊成员接口
export const ExitConsult = `${publicUrl}/chatapi/Group/ExitConsult`; //成员退出接口
export const FinishConsult = `${publicUrl}/doctorapi/Group/FinishConsult`; //结束咨询接口
// export const MultiaccountImport = `${publicUrl}/chatapi/Account/MultiaccountImport`; //IM账号批量导入接口

// 训练
export const AgreeTrainOrder = `${publicUrl}/adminapi/Scaler/AgreeTrainOrder`; //同意训练
export const TrainOrderList = `${publicUrl}/adminapi/Scaler/TrainOrderList`; //预约训练列表
export const HospitalListS = `${publicUrl}/adminapi/Scaler/HospitalList`; //预约中心列表
export const LineList = `${publicUrl}/adminapi/Scaler/LineList`; //预约类型列表
export const RefuseTrainOrder = `${publicUrl}/adminapi/Scaler/RefuseTrainOrder`; //拒绝训练
export const ExportTrain = `${publicUrl}/adminapi/Scaler/ExportTrain`; //导出
//测评
export const ScaleLists = `${publicUrl}/adminapi/Scaler/ScaleLists`; //测评问卷列表
export const ScalerList = `${publicUrl}/adminapi/Scaler/ScalerList`; //测评列表
export const ScalerInfo = `${publicUrl}/adminapi/Scaler/ScalerInfo`; //测评详情
export const ScaleStatusList = `${publicUrl}/adminapi/Scaler/ScaleStatusList`; //测评状态列表
export const ExportScale = `${publicUrl}/adminapi/Scaler/ExportScale`; //导出
// 症状统计
export const StartSymptom = `${publicUrl}/adminapi/Manage/StartSymptom`; //启用
export const SymptomList = `${publicUrl}/adminapi/Manage/SymptomList`; //列表
export const AddSymptom = `${publicUrl}/adminapi/Manage/AddSymptom`; //添加
export const SymptomInfo = `${publicUrl}/adminapi/Manage/SymptomInfo`; //详情
export const EditSymptom = `${publicUrl}/adminapi/Manage/EditSymptom`; //修改
export const DelSymptom = `${publicUrl}/adminapi/Manage/DelSymptom`; //冻结
// 训练类型
export const EditTrain = `${publicUrl}/adminapi/Manage/EditTrain`; //修改
export const DelTrain = `${publicUrl}/adminapi/Manage/DelTrain`; //冻结
export const StartTrain = `${publicUrl}/adminapi/Manage/StartTrain`; //解冻
export const TrainInfo = `${publicUrl}/adminapi/Manage/TrainInfo`; //详情
export const AddTrain = `${publicUrl}/adminapi/Manage/AddTrain`; //新增
export const TrainsList = `${publicUrl}/adminapi/Manage/TrainsList`; //列表
export const UploadTrainImage = `${publicUrl}/adminapi/System/UploadTrainImage`; //上传图片

// PSG检测
export const PsgPatientsList = `${publicUrl}/adminapi/Treatment/PsgPatientsList`; //列表
export const FindRecordList = `${publicUrl}/adminapi/Treatment/FindRecordList`; //用户信息
export const FindRecordPsgInfo = `${publicUrl}/adminapi/Treatment/FindRecordPsgInfo`; //详情
export const AddPsgPatients = `${publicUrl}/adminapi/Treatment/AddPsgPatients`; //添加
export const EditPsgPatients = `${publicUrl}/adminapi/Treatment/EditPsgPatients`; //修改
export const DelPsgPatients = `${publicUrl}/adminapi/Treatment/DelPsgPatients`; //删除
export const UploadRecordPsg = `${publicUrl}/adminapi/System/UploadRecordPsg`; //上传psg监测数据

// 课程管理
export const ClassScalerList = `${publicUrl}/adminapi/Classscaler/ClassScalerList`; // 课程测评列表
export const ClassScalerInfo = `${publicUrl}/adminapi/Classscaler/ClassScalerInfo`; // 测评详情
export const ClassStatisticsList = `${publicUrl}/adminapi/Classscaler/ClassStatisticsList`; // 测评统计信息
export const GetOptionList = `${publicUrl}/adminapi/Classscaler/GetOptionList`; // 获取选项的列表
export const GradeList = `${publicUrl}/adminapi/Classscaler/GradeList`; // 年级
export const ExportClassStatistics = `${publicUrl}/adminapi/Classscaler/ExportClassStatistics`; // 统计导出
export const YinziNameList = `${publicUrl}/adminapi/Classscaler/YinziNameList`; // 评测结果列表
export const ScaleLevelList = `${publicUrl}/adminapi/Classscaler/ScaleLevelList`; // 评测等级列表

export const doctorArrangeList = `${publicUrl}/adminapi/Setup/doctorArrangeList`; // 门诊详情
export const doctorDetailServiceTime = `${publicUrl}/adminapi/Setup/doctorDetailServiceTime`; // 门诊排版
export const doctorEditServiceTime = `${publicUrl}/adminapi/Setup/doctorEditServiceTime`; // 修改排班
export const doctorEditSeries = `${publicUrl}/adminapi/Setup/doctorEditSeries`; // 门诊排班
export const GetTitleList = `${publicUrl}/adminapi/Setup/GetTitleList`; // 获取表头

// 睡眠调摄
export const clocksList = `${publicUrl}/adminapi/Platform/clocksList` // 列表（1正念疗法，2音乐疗法，3放松疗法）
export const getCategory = `${publicUrl}/adminapi/Platform/getCategory` // 栏目分类
export const addClocksInfo = `${publicUrl}/adminapi/Platform/addClocksInfo` // 新增项
export const getOneClocksInfo = `${publicUrl}/adminapi/Platform/getOneClocksInfo` // 获取单条数据信息
export const editOneClocksInfo = `${publicUrl}/adminapi/Platform/editOneClocksInfo` // 编辑保存
export const upDownOneClocks = `${publicUrl}/adminapi/Platform/upDownOneClocks` // 上下架单个数据项
export const clocksListLocation = `${publicUrl}/adminapi/Platform/clocksListLocation` // 上下移动单个数据项
export const uploadFile = `${publicUrl}/adminapi/Platform/uploadFile` // 上传视频
export const multiUploadFile = `${publicUrl}/file/upload/multiUploadFile` // 上传视频