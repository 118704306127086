<template>
    <!-- 数据统计页面 -->
    <div id="dataCenter" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="24" class="data-center" style="margin-right:0px;margin-left:0px">
                    <el-col :span="8" class="box" v-for="(item,index) in total" >
                        <el-row :gutter="24">
                            <el-col :span="24" >
                                <div @click="skipPage(item)" class="pointer">
                                    <div class="info middle i-block">
                                        <p>{{item.count}}</p>
                                        <div>
                                            {{item.title}}
                                            <el-button type="text" v-if="index == 0">详情</el-button>
                                            <el-button type="text" v-else style="color:#fff;">详情</el-button>
                                        </div>
                                    </div>
                                    <div class="icon middle i-block">
                                        <img
                                            v-if="item.names == 'totals'"
                                            src="../../assets/image/total_price.png"
                                        />
                                        <img
                                            v-else-if="item.names == 'users'"
                                            src="../../assets/image/user_icon.png"
                                        />
                                        <img
                                            v-else="item.names == 'servers'"
                                            src="../../assets/image/server_icon.png"
                                        />
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container mains" :style="{height:contentHeight+'px'}">
                <!-- 数据图 -->
                <el-row :gutter="24" style="margin-right:0px;margin-left:0px">
                    <el-col :span="18" style="padding-left:0px;">
                        <!-- 动态数据 折线图-->
                        <div class="chats">
                            <div class="name">
                                <div class="i-block">动态数据</div>
                                <div class="i-block fr">
                                    <el-radio-group
                                        class="chats-radio"
                                        v-model="num"
                                        size="mini"
                                        @change="changes"
                                    >
                                        <el-radio-button :label="7">近7日</el-radio-button>
                                        <el-radio-button :label="30">近30日</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="chat-box" v-loading="chat1">
                                <div id="dynamicData" style="width:100%;height:300px"></div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6" style="padding-right:0px;">
                        <!-- 疾病分类 饼图-->
                        <div class="classify">
                            <div class="name">疾病分类</div>
                            <div class="chat-box" v-loading="chat2">
                                <div id="classify" style="width:100%;height:300px"></div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <!-- 底部预览 -->
                <el-row :gutter="24" class="business" style="margin-right:0px;margin-left:0px;">
                    <div class="name">业务统计</div>
                    <el-col class="business-box" style="padding:20px 20px;">
                        <el-row :gutter="24" >
                            <el-col :span="4" v-for="(item,index) in bus" class="items" style="padding-left: 30px">
                                <div class="i-block" @click="skipPageBusiness(item)" v-if="item.flag" >
                                    <p>{{item.name}} <el-button type="text">详情</el-button></p>
                                    <div>{{item.add}}
                                        <span>总计：{{item.count}}</span>
                                    </div>
                                </div>
                                <!-- <div class="i-block" v-if="!item.flag" >
                                    <p>{{item.name}} <el-button type="text">详情</el-button></p>
                                    <div>{{item.add}}
                                        <span>总计：{{item.count}}</span>
                                    </div>
                                </div> -->
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import { DataAggregationY } from "../../api/api";
import { DynamicStatisY } from "../../api/api";
import { DiseaseSummaryY } from "../../api/api";
import { BusinessListY } from '../../api/api';
import echarts from "echarts";
import { constants } from 'crypto';
import { setTimeout } from 'timers';
export default {
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            total: [
                // {
                //     title: "总交易额",
                //     names: "totals",
                //     count: 0
                // },
                {
                    title: "患者总计",
                    names: "users",
                    count: 0
                },
                {
                    title: "医务人员总数",
                    names: "servers",
                    count: 0
                }
            ],
            bus:[],
            chat1: false,
            chat2: false,
            num: 7 //默认7天
        };
    },
    created() {
        this.getDataAggregation();
        this.getTags();
        this.getdynamic();
        this.getBusiness();

    },
    mounted() {
        var that = this;
        that.common.Count.noPageResize(that);
        window.onresize = function temp() {
            that.common.Count.noPageResize(that);
        };
    },

    methods: {
        //跳转页面
        skipPage(item) {
            var paths;
            if (item.names == "totals") {  
                paths = "businessList";
            }else if (item.names == "users") {  
                paths = "userManageList";
            }
            this.$router.push({
                name:paths,
            });
        },
        //业务统计页面
        skipPageBusiness(item){
            console.log(item)
            this.$router.push({
                name:item.url,
            });
        },
        //动态数据图表
        dynamicLine(db) {
            // 基于准备好的dom，初始化echarts实例
            let myChart = echarts.init(document.getElementById("dynamicData"));
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: "axis"
                },
                backgroundColor: "#fff",
                legend: {
                    top: "3%",
                    data: db.text
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: db.date
                },
                yAxis: {
                    type: "value"
                },
                series: [
                    {
                        name: db.text[0],
                        type: "line",
                        data: db.consult
                    },
                    {
                        name: db.text[1],
                        type: "line",
                        data: db.patient
                    }
                ]
            });
        },
        //疾病分类图表
        drawLine(db) {
            // 基于准备好的dom，初始化echarts实例
            let myChart = echarts.init(document.getElementById("classify"));
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "疾病分类",
                    x: "center"
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                series: [
                    {
                        name: "访问来源",
                        type: "pie",
                        radius: "70%",
                        center: ["50%", "60%"],
                        data:db.dbs,
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ],
                color: db.color,
            });
        },
        //获取总数
        getDataAggregation() {
            var that = this;
            this.$axios.post(DataAggregationY, {}).then(
                res => {
                    if (res.data.code == 1) {
                        // this.total[0].count = res.data.data.gmv.gmv_count;
                        // this.total[1].count = res.data.data.patient.patient_count;
                        // this.total[2].count = res.data.data.doctor.doctor_count;
                        this.total[0].count = res.data.data.patient.patient_count;
                        this.total[1].count = res.data.data.doctor.doctor_count;
                    }
                },
                err => {}
            );
        },
        //疾病分类
        getTags() {
            var that = this;
            this.chat2 = true;
            this.$axios.post(DiseaseSummaryY, {}).then(
                res => {
                    if (res.data.code == 1) {
                        //疾病分类
                        this.drawLine(res.data.data);
                        this.chat2 = false;
                    }
                },
                err => {
                    this.chat2 = false;
                }
            );
        },
        //动态数据
        getdynamic() {
            var that = this;
            this.chat1 = true;
            this.$axios.post(DynamicStatisY, { num: this.num }).then(
                res => {
                    if (res.data.code == 1) {
                        //疾病分类
                        this.dynamicLine(res.data.data);
                        this.chat1 = false;
                    }
                },
                err => {
                    this.chat1 = false;
                }
            );
        },
        //切换统计
        changes(val) {
            this.num = val;
            this.getdynamic();
        },
        //获取业务统计
        getBusiness(){
            var that = this;
            this.$axios.post(BusinessListY, {}).then(
                res => {
                    if (res.data.code == 1) {
                        this.bus = res.data.data;
                    }
                },
                err => {}
            );
        },
    }
};
</script>
<style lang="scss" scoped>
.communal .top-fixed .container {
    padding: 0px 0px;
    background: #f4f4f4;
}
.communal .content .container {
    background: transparent;
}
.box {
    padding: 20px 30px;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-right: 4%;
    width: 22%;
}
.box:last-child{
    margin-right: 0px;
}
.info {
    position: relative;
    width: 70%;
    padding-left: 10%;
    p {
        margin: 0px;
        font-size: 30px;
        font-weight: bold;
    }
    div {
        font-size: 14px;
        color: #999999;
        letter-spacing: 0;
        margin-top: 10px;
    }
}
.icon {
    width: 19%;
}
.name {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #999999;
    letter-spacing: 0;
}
.chat-box {
    margin-top: 10px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    height:300px
}
.mains{
    position: relative;
    .business{
        // position: absolute;
        // bottom: 60px;
        // left: 0px;
        margin-top:60px;
        width: 100%;
        .business-box{
            margin-top: 10px;
            background: #fff;
            // padding: 20px 50px;
            border-radius: 6px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
        }
        .items{
            // display: inline-block;
            border-right: 1px solid #f4f4f4;
            // padding-right: 80px;
            // margin-right: 80px;
            // width: 8%;
            p{
                margin-top: 0px;
                font-size: 14px;
                color: #666666;
                letter-spacing: 0;
            }
            div{
                font-size: 30px;
                color: #333333;
                letter-spacing: 0;
                line-height: 32px;
            }
            span{
                font-size: 12px;
                color: #999999;
                letter-spacing: 0;
                margin-left: 10px;
            }
        }
        .items:nth-child(6){
            margin-right: 0px;
            padding-right: 0px;
            border-right: 0px;
        }
    }
}
</style>
