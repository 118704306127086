<template>
<!-- 系统设置-中心设置 -->
    <div  class="communal system-account" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success"  style="margin-left: 0;" @click="addAlter('新增中心','')" >新增中心</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                        <el-table :data="list" style="width: 100%" :height="contentHeight" :row-class-name="tableRowClassName" :empty-text="tableTip">
                            <el-table-column prop="title" label="中心名称" min-width="170" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="tel" label="联系电话" min-width="160"></el-table-column>
                            <el-table-column prop="username" label="联系人" min-width="100"></el-table-column>
                            <el-table-column prop="telphone" label="联系人联系方式" min-width="160"></el-table-column>
                            <el-table-column prop="addtime" label="新增时间" min-width="160"></el-table-column>
                            <el-table-column prop="status" label="状态" min-width="100"></el-table-column>
                            <el-table-column label="操作" fixed="right" width="120">
                                <template slot-scope="scope" >
                                    <el-button type="text" @click="addAlter('中心信息',scope.row.hospital_id)">编辑</el-button>
                                    <el-button type="text" @click.stop="operation(scope.row.hospital_id,'冻结')" 
                                        v-if="scope.row.status=='正常' && hospitalName.hospital_title != scope.row.title ">冻结</el-button>
                                    <el-button type="text" @click.stop="operation(scope.row.hospital_id,'解冻')" v-if="scope.row.status=='已冻结'">解冻</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100,200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 新增中心 start -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="590px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
                    <el-form-item label="中心名称" prop="centerName">
                        <el-input v-model="ruleForm.centerName" placeholder="请输入中心名称,20字以内" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="简称" prop="abbreviation">
                        <el-input v-model="ruleForm.abbreviation"   placeholder="请输入该中心简称,20字以内" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="上传logo" prop="uploadPictures">
                        <uoloadSingleImg 
                            v-model="ruleForm.uploadPictures" 
                            ref="uoloadSingleImg" 
                            form="上传" 
                            :uploadUrl="uploadUrl" 
                            :fileList="fileList" @childByValue="childByValue" :tip="tip" :uploadSize="2" 
                            :uoloadSingleType="'img'" 
                            :uoloadSingleStatus='uoloadSingleStatus'
                            v-if="dialogVisible"
                        ></uoloadSingleImg>        
                    </el-form-item>
                    <el-form-item label="中心联系电话" prop="centerTelphone">
                        <el-input v-model="ruleForm.centerTelphone"  placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="username">
                        <el-input v-model="ruleForm.username" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人联系电话" prop="telphone">
                        <el-input v-model="ruleForm.telphone"  maxlength="11" type='number' placeholder="请输入联系人联系电话"></el-input>
                    </el-form-item>
                     <el-form-item label="中心地址" prop="address">
                        <el-input v-model="ruleForm.address" type="textarea" placeholder="请输入中心地址"></el-input>
                    </el-form-item>
                    <el-form-item label="权限" prop="treeId" >
                          <el-input v-model="ruleForm.treeId"  v-show="false"></el-input>
                          <el-tree
                                :data="jurisdictionList"
                                show-checkbox
                                default-expand-all
                                node-key="business_id"
                                ref="tree"
                                highlight-current
                                :props="defaultProps"
                                @check="treeChange"
                                >
                                </el-tree>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                <div><el-button type="primary"  @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 新增中心 end -->
        <!-- 调用按钮操作性弹框 -->
        <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import operationBtn from "../../components/operationBtn";
import { HospitalList } from "../../api/api"; //列表
import { StartHospital } from "../../api/api";//启动中心
import { DelHospital } from "../../api/api"; //冻结中心
import { HospitalInfo } from "../../api/api"; //中心详情
import { BusinessesList } from "../../api/api"; //业务权限列表
import { AddHospital } from "../../api/api"; //新增
import { EditHospital } from "../../api/api"; //修改
import { UploadHispitalLogo } from "../../api/api"; //上传logo
import { FindUserHospitalName } from "../../api/api"; //名称

export default {
    components: {
        operationBtn,
        uoloadSingleImg
    },
  data() {
    var checkName = (rule, value, callback, text) => {
      this.common.Validate.checkName(rule, value, callback, '中心名称');
    };
    var checkUsername = (rule, value, callback, text) => {
      this.common.Validate.checkName(rule, value, callback, '联系人');
    };
    var checkTelphone = (rule, value, callback, text) => {
      this.common.Validate.checkTelphone(rule, value, callback, '联系人联系电话');
    };
    var checkCenterTelphone = (rule, value, callback, text) => {
      this.common.Validate.checkPhoneAndTell(rule, value, callback, '中心联系电话');
    };
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        centerName: "", //中心名称
        abbreviation:'',//简称
        centerTelphone:'',//中心联系电话
        username:'',//联系人
        telphone: "", //联系人联系电话
        address:'',//中心地址
        treeId: "", //权限
        uploadPictures: "" //图片
      },
      rules: {
        centerName: [
          { required: true, trigger: "change", validator: checkName }
        ],
        abbreviation:[
          { required: true, message: "请输入该中心简称",trigger: "change", }
        ],
        username: [
          { required: true, trigger: "change", validator: checkUsername }
        ],
        telphone: [
          { required: true, trigger: "change", validator: checkTelphone }
        ],
        centerTelphone: [
          { required: true, trigger: "blur", validator: checkCenterTelphone }
        ],
        address: [{ required: true, message: "请输入中心地址", trigger: "change" }],
        treeId: [{ required: true, message: "请选择权限", trigger: "change" }],
        uploadPictures: [
          { required: true, message: "请上传logo", trigger: "change" }
        ]
      },
      id: "", //保存点击行的账号id
      tableTip: "系统正在检索中…",
         // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName:'',//请求参数名字
            // 操作性按钮弹框值   end
      jurisdictionList:[],//权限列表
      defaultProps: {
          children: 'list',
          label: 'title'
        },
      uploadUrl: "",
      tip: "请上传长宽1:1比例的logo，2m以内", //上传提示信息
      fileList: [], //默认显示图片
      oloadSingleStatus: "",
      hospitalName: "", //中心名称

    };
  },
  created() {
    this.uploadUrl = UploadHispitalLogo;
    this.getList();
    this.getFindUserHospitalName();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
    that.getBusinessesList();
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(HospitalList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无中心";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无中心";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无中心";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    submitForm(formName) {
        console.log(this.dialogTitle);
      this.$refs[formName].validate(valid => {
        if (valid) {
            var postUrl;
            var db = {
                title : this.ruleForm.centerName,//	中心名称
                abbreviation:this.ruleForm.abbreviation,//简称
                tel	: this.ruleForm.centerTelphone,//		中心联系电话
                username : this.ruleForm.username,//	联系人
                telphone : this.ruleForm.telphone,//	联系人联系方式
                address	: this.ruleForm.address,//	中心地址
                businesses	: this.ruleForm.treeId,//	业务权限
                logo:this.ruleForm.uploadPictures, //图片地址
            };
          if (this.dialogTitle == "中心信息") {
            //修改
            db.hospital_id=this.id
            postUrl=EditHospital
          } else {
            //新增
            postUrl=AddHospital
          }
           this.$axios.post(postUrl, db).then(
              res => {
                if (res.data.code == 1) {
                  this.getList();
                  this.dialogVisible = false;
                } else {
                  this.$message.error(res.data.msg);
                }
              },
              err => {
                this.$message.error(err.msg);
              }
            );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addAlter(btnName, id) {
      //查看,修改   新增中心
      this.dialogTitle = btnName;
      this.id = id;
      if (btnName == "中心信息") {
        //详情
        this.uoloadSingleStatus = "edit";
        var db = {
          hospital_id: id 
        };
        this.$axios.post(HospitalInfo, db).then(
          res => {
            if (res.data.code == 1) { //中心详情
              this.ruleForm.centerName=res.data.data.title;//中心名称
              this.ruleForm.abbreviation=res.data.data.abbreviation;//简称
              this.ruleForm.centerTelphone=res.data.data.tel;//中心联系电话
              this.ruleForm.username=res.data.data.username;//联系人
              this.ruleForm.telphone=res.data.data.telphone;//联系人联系电话
              this.ruleForm.address=res.data.data.address;//中心地址
              //   权限
              this.$nextTick(()=>{
                this.$refs.tree.setCheckedKeys(res.data.data.businesses);
              });
              this.ruleForm.treeId=res.data.data.businesses.join(',');
            //   logo
              this.fileList = res.data.data.logo;
              var imgUrl = [];
              for (var i = 0; i < this.fileList.length; i++) {
                imgUrl.push(this.fileList[i].url);
              }
              this.ruleForm.uploadPictures = imgUrl.join(",");
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      }else{
          this.uoloadSingleStatus = "add";
      }
      this.dialogVisible = true;
      this.clearForm();
    },
    operation(operationId, operationName) {
            //对数据的操作-按钮   operationName-按钮名称    operationId-操作id
            this.operationPsotName='hospital_id';
            if (operationName == "冻结") {
                this.issue = "确定冻结该中心？";
                this.hintTxt = "冻结后该中心关联的所有功能将不可用";
                this.operationPsotUrl = DelHospital;
            } else {
                this.issue = "确定解冻该中心？";
                this.hintTxt = "解冻后该中心管理的所有功能恢复正常";
                this.operationPsotUrl = StartHospital;
            }
               this.operationId = operationId; //修改组件id
               this.showHide = true; //显示组件
        },
     childByOperationBtn: function(showHide, dataCode) {
            //接收按钮式操作子组件传值
            this.showHide = showHide;
            if (dataCode == 1) {
                //接口调用成功，调用其他接口
                this.getList();
            }
        },
    getBusinessesList(){ //业务权限列表
         this.$axios.post(BusinessesList, {}).then(
            res => {
              if (res.data.code == 1) {
                this.jurisdictionList = res.data.data;
              } else {
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
    },
    treeChange(){ //选中的权限
        if(this.$refs.tree.getCheckedKeys().length>0){
            this.ruleForm.treeId=this.$refs.tree.getCheckedKeys().join(',');
        }else{
            this.ruleForm.treeId='';
        }
    },
    clearForm(){//重置表单
        if (this.$refs["ruleForm"] != undefined) {
          this.$refs["ruleForm"].resetFields();
        }
    },
    childByValue(val, type) {
      this.fileList = [val];
      this.ruleForm.uploadPictures = val;
      console.log(this.ruleForm.uploadPictures);
    },
    getFindUserHospitalName() {
        //中心名称
        this.$axios.post(FindUserHospitalName, {}).then(
            res => {
                if (res.data.code == 1) {
                    this.hospitalName = res.data.data;
                }
            },
            err => {
                this.$message.error(err.msg);
            }
        );
    },
  }
};
</script>

<style lang="scss">
.system-account {
  .login-hint {
    line-height: 40px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    padding-left: 6px;
    i {
      padding-right: 6px;
      font-size: 14px;
    }
  }
}
</style>
