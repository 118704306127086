<template>
<!-- 统计症状管理 -->
    <div  class="communal drill-type" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success"  style="margin-left: 0;" @click="addAlter('新增训练类型','')" >新增类型</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :row-class-name="tableRowClassName"
                            :empty-text="tableTip"
                            >
                            <el-table-column
                            prop="title"
                            label="名称"
                           
                            >
                            </el-table-column>
                            <el-table-column
                            prop="times"
                            label="可预约时间"
                            
                            >
                            </el-table-column>
                            <el-table-column
                            prop="username"
                            label="新增人"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="新增时间"
                            min-width="100"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            label="状态"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right" width="150">
                            <template slot-scope="scope">
                                <el-button type="text" @click="addAlter('训练类型信息',scope.row.id)">查看</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'冻结')" v-if="scope.row.status=='正常'">冻结</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'解冻')" v-if="scope.row.status=='已冻结'">解冻</el-button>
                            </template>
                            </el-table-column>
                            </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100,200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 新增训练类型 start -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="640px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="ruleForm.name" maxlength="20" placeholder="请输入类型名称,20字以内"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="address">
                        <el-input v-model="ruleForm.address" maxlength="20" placeholder="请输入地址"></el-input>
                    </el-form-item>
                     <el-form-item label="上传背景图" prop="uploadPictures">
                        <uoloadSingleImg 
                           v-model="ruleForm.uploadPictures" 
                           ref="uoloadSingleImg" 
                           form="上传" 
                           :uploadUrl="uploadUrl" 
                           :fileList="fileList" 
                           @childByValue="childByValue" 
                           :uploadSize="2"
                           :tip="tip" 
                           :danger=true
                           :uoloadSingleType="'img'" 
                           :uoloadSingleStatus='uoloadSingleStatus'
                        ></uoloadSingleImg>
                    </el-form-item>
                    <el-form-item
                        v-for="(item, index) in ruleForm.domainsTime"
                        :key="index"
                        :label="'可预约时间'"
                        class="domains-time"
                    >
                      <el-row>
                          <el-col :span="8">
                              <el-form-item   :key="index"   :prop="'domainsTime.' + index + '.startTime'"     :rules="{required: true, message: '可预约开始时间不能为空', trigger: 'blur'}">
                                      <el-time-select
                                        class="el-long"
                                        placeholder="起"
                                        v-model="item.startTime"
                                        :picker-options="{
                                        start: '00:00',
                                        step: '00:60',
                                        end: '23:00'
                                        }">
                                      </el-time-select>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-form-item   :key="index"   :prop="'domainsTime.' + index + '.endTime'"     :rules="{required: true, message: '可预约结束时间不能为空', trigger: 'blur'}">
                                      <el-time-select
                                        class="el-long"
                                        placeholder="止"
                                        v-model="item.endTime"
                                        :picker-options="{
                                        start: '00:00',
                                        step: '00:60',
                                        end: '23:00',
                                        minTime: item.startTime
                                        }">
                                      </el-time-select>
                              </el-form-item>
                          </el-col>
                          <el-col :span="8">
                              <el-button @click.prevent="addDomain" icon="el-icon-plus" class="mf-10"></el-button>
                              <el-button @click.prevent="removeDomain(item)" icon="el-icon-minus" ></el-button>
                          </el-col>
                      </el-row>
                    </el-form-item>
                </el-form>

            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                 <div><el-button type="primary"  @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 新增训练类型 end -->
        <operationBtn  
          v-if="showHide"  
          :issue='issue' 
          :hintTxt='hintTxt' 
          :showHide="showHide" 
          :operationId='operationId'  
          :operationPsotUrl='operationPsotUrl' 
          :operationPsotName="operationPsotName"
          @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import uoloadSingleImg from "../../components/uploadSingleImg";
import { TrainsList } from "../../api/api"; //列表
import { EditTrain } from "../../api/api"; //修改
import { DelTrain } from "../../api/api"; //冻结
import { StartTrain } from "../../api/api"; //解冻
import { TrainInfo } from "../../api/api"; //详情
import { AddTrain } from "../../api/api"; //新增
import { UploadTrainImage } from "../../api/api"; //上传
export default {
  components: {
    operationBtn,
    uoloadSingleImg
  },
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      id: "", //保存点击行的账号id
      tableTip: "系统正在检索中…",
      // 操作性按钮弹框值   start
      issue: "",
      hintTxt: "",
      showHide: false,
      operationId: "",
      operationPsotUrl: "",
      operationPsotName: "",
      // 操作性按钮弹框值   end
      // 新增弹框
      dialogVisible: false, //弹框
      dialogTitle: "",
      tip: "请上传694*240尺寸的图片", //上传提示信息
      uploadUrl: "",
      uoloadSingleStatus: "",
      fileList: [], //默认显示图片
      danger: true,
      ruleForm: {
        name: "", //名称
        address:'',
        uploadPictures: [], //上传图片
        domainsTime: [
          //预约时间
          { startTime: "", endTime: "" }
        ]
      },
      startTime: "",
      endTime: "",
      rules: {
        name: [
          { required: true, message: "请输入类型名称", trigger: "change" }
        ],
        address:[
          { required: true, message: "请输入地址", trigger: "change" }
        ],
        uploadPictures: [
          { required: true, message: "请上传背景图片", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.uploadUrl = UploadTrainImage;
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(TrainsList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无训练类型";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无训练类型";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无训练类型";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    submitForm(formName) {
      //新建账号
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.dialogTitle == "训练类型信息") {
            //   修改
            postUrl = EditTrain;
            db = {
              title: this.ruleForm.name, //	标题
              address:this.ruleForm.address,//地址
              img: this.ruleForm.uploadPictures, //	图片
              time: JSON.stringify(this.ruleForm.domainsTime), //	预约时间
              id: this.id //记录id
            };
          } else {
            //新增
            postUrl = AddTrain;
            db = {
              title: this.ruleForm.name, //	标题
              address:this.ruleForm.address,//地址
              img: this.ruleForm.uploadPictures, //	图片
              time: JSON.stringify(this.ruleForm.domainsTime) //	预约时间
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              if (res.data.code == 1) {
                this.getList();
                this.dialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addAlter(btnName, id) {
      //查看,修改   新增训练类型
      this.dialogTitle = btnName;
      this.id = id;
      if (btnName == "训练类型信息") {
        //详情
        this.uoloadSingleStatus = "edit";
        var db = {
          id: id
        };
        this.$axios.post(TrainInfo, db).then(
          res => {
            if (res.data.code == 1) {
              if (res.data.data.times.length < 0) {
                this.ruleForm.domainsTime = res.data.data.times; //时间
              } else {
                this.ruleForm.domainsTime = [{ startTime: "", endTime: "" }]; //时间
              }

              this.fileList = res.data.data.img;
              var imgUrl = [];
              for (var i = 0; i < this.fileList.length; i++) {
                imgUrl.push(this.fileList[i].url);
              }
              this.ruleForm.uploadPictures = imgUrl.join(",");
              this.ruleForm.name = res.data.data.title;
              this.ruleForm.address = res.data.data.address;
              this.ruleForm.domainsTime = res.data.data.times;
              this.dialogVisible = true;
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      } else {
        //新增
        this.uoloadSingleStatus = "add";
        this.clearForm();
        this.ruleForm.uploadPictures = [];
        this.dialogVisible = true;
      }
    },
    childByOperationBtn: function(showHide, dataCode) {
      //接收按钮式操作子组件传值
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    },
    //接受图片子组件传回来的值
    childByValue(val, type) {
      this.fileList = [val];
      this.ruleForm.uploadPictures = val;
    },
    operation(operationId, operationName) {
      //对数据的操作-按钮
      this.operationPsotName = "id";
      this.operationId = operationId; //修改组件id
      if (operationName == "冻结") {
        this.issue = "确定冻结该预约类型？";
        this.hintTxt = "冻结后用户将不可预约该训练类型";
        this.operationPsotUrl = DelTrain;
      } else {
        this.issue = "确定解冻该预约类型？";
        this.hintTxt = "解冻后用户可继续预约该训练";
        this.operationPsotUrl = StartTrain;
      }
      this.showHide = true; //显示组件
    },
    removeDomain(item) {
      //删除可预约时间
      if (this.ruleForm.domainsTime.length <= 1) {
        this.$message({
          message: "请至少填写一个可预约时间",
          type: "warning"
        });
      } else {
        var index = this.ruleForm.domainsTime.indexOf(item);
        if (index !== -1) {
          this.ruleForm.domainsTime.splice(index, 1);
        }
      }
    },
    addDomain() {
      //添加可预约时间
      this.ruleForm.domainsTime.push({
        startTime: "",
        endTime: ""
      });
    },
    clearForm() {
      //重置表单
      console.log(this.$refs["ruleForm"])
      if (this.$refs["ruleForm"] != undefined) {
        this.$refs["ruleForm"].resetFields();
      }
    }
  }
};
</script>

<style lang="scss">
.drill-type {
  .domains-time .el-form-item__label::before {
    content: "*";
    color: #ff4d52;
    margin-right: 4px;
  }
}
</style>
