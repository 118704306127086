<template>
    <div class="details-content">
        <!-- 新增及编辑   -->
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane label="量表结果" name="first" lazy>
               <div class="details-container">
                   <h5>量表结果</h5>
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
                        <el-table class="month-datatab" :data="ruleForm.list" border  style="width: 100%" :header-cell-style="{ background: '#F2F2F2', color: '#333' }">
                           <el-table-column label="量表结果" width="200">
                                <template slot-scope="scope">
                                    <!-- :prop="'list.' + scope.$index + '.month_1'" :rules="rules.month_1" -->
                                    <el-form-item class="p-t-20" >
                                        <el-input placeholder="12" v-model="scope.row.month_1" type="number" size="small" />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="得分区间" width="200">
                                <template slot-scope="scope">
                                    <el-form-item class="p-t-20" >
                                        <el-input v-model="scope.row.month_2" type="number" size="small" />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="评估说明" width="200">
                                <template slot-scope="scope">
                                    <el-form-item class="p-t-20" >
                                        <el-input v-model="scope.row.month_3" type="number" size="small" />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="量表结果" width="200">
                                <template slot-scope="scope">
                                    <el-form-item class="p-t-20" >
                                        <el-input v-model="scope.row.month_4" type="number" size="small" />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="量表结果" width="200">
                                <template slot-scope="scope">
                                    <el-form-item class="p-t-20" >
                                        <el-input v-model="scope.row.month_1" type="number" size="small" />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button class="mt-10" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                   </el-form>
               </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lineId: this.$route.query.lineId, //康复行id  
            activeName: "first",
            ruleForm:{
                 list:[
                    {
                        month_1	:'0',//	1月指标对应的数量
                        month_2	:'0',//	2月指标对应的数量
                        month_3	:'0',//	3月指标对应的数量
                        month_4	:'0',//	4月指标对应的数量
                        month_5	:'0',//	5月指标对应的数量
                        month_6	:'0',//	6月指标对应的数量
                        month_7	:'0',//	7月指标对应的数量
                    }
                ],
            },
            rules:{}
        };
    },
    methods:{
        goBack(){
            this.$router.push({ name: "scaleMangeList" });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
    },
}
</script>

<style lang="scss">
.result-datatab{
  .el-form-item__content{
    margin-left: 0px !important
  }
}
</style>
