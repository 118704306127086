<template>
    <!-- 挂号管理 -->
    <div class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <!-- -->
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="7">预约日期：</el-col>
                    <el-col :span="4">预约专家：</el-col>
                    <el-col :span="4">预约状态：</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="7">
                        <el-date-picker
                            class="el-long"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="起"
                            end-placeholder="终"
                            v-model="search.date"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-select clearable v-model="search.expert" placeholder="请选择预约专家">
                            <el-option
                                v-for="(val,index) in doctorList"
                                :key="index"
                                :label="val.username"
                                :value="val.doctor_id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-select clearable v-model="search.status" placeholder="请选择预约状态">
                            <el-option
                                v-for="(val,index) in statusList"
                                :key="index"
                                :label="val.title"
                                :value="val.status_id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-input v-model="search.content" placeholder="请输入关键字查询"></el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button type="success" plain @click="serach">搜索</el-button>
                        <el-button type="success" plain @click="exportData">导出</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <template>
                    <el-table
                        ref="multipleTable"
                        :data="list"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :height="contentHeight"
                        @selection-change="selectionChange"
                        :empty-text="tableTip"
                        :cell-class-name="tableCellClassName"
                    >
                        <el-table-column type="selection" width="60"></el-table-column>
                        <el-table-column prop="username" label="姓名" width="120"></el-table-column>
                        <el-table-column prop="sex" label="性别" width="120"></el-table-column>
                        <el-table-column prop="cardnum" label="身份证" width="220"></el-table-column>
                        <el-table-column prop="telphone" label="联系方式" width="160"></el-table-column>
                        <el-table-column prop="date" label="提交预约时间" width="160"></el-table-column>
                        <el-table-column prop="doctor_name" label="预约专家" width="200"></el-table-column>
                        <el-table-column prop="addtime" label="预约时间" width="190"></el-table-column>
                        <el-table-column prop="status" label="预约状态" min-width="120">
                            <template slot-scope="scope" >
                                {{scope.row.status}}
                                <el-tooltip v-if="scope.row.status=='已拒绝'" class="item" effect="dark" :content="scope.row.result" placement="top-start">
                                  <i class="el-icon-info"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="120">
                            <template slot-scope="scope" v-if="scope.row.status=='未确认'">
                                <el-button type="text" @click.stop="operation(scope.row.id,'同意')">同意</el-button>
                                <el-button type="text" @click.stop="operation(scope.row.id,'拒绝')">拒绝</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 2000]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>
        <!-- 弹框  start -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="480px" top="20%">
            <div style="padding:30px 60px 0">
                <div class="p-b-10 font-s14 c66">拒绝原因</div>
                <el-input type="textarea" rows="5" v-model="refuseContent" maxlength="200" class="mb-20" placeholder="请输入拒绝用户转诊原因，200字以内"></el-input>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </div>
                <div>
                    <el-button type="primary" @click="refuseBtn">确 定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 弹框  end -->
        <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
            :parameter='parameter'
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { DoctorListT } from "../../api/api"; //预约专家列表
import { RegisterList } from "../../api/api"; //挂号列表
import { AgreeRegister } from "../../api/api"; //同意挂号
import { RefuseRegister } from "../../api/api"; //拒绝挂号
import { StatusList } from "../../api/api"; //状态
import { ExportRegister } from "../../api/api"; //导出

export default {
    components: {
        operationBtn
    },
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            totals: 0,
            pages: "1",
            psize: 10,
            currentPage: 1, //默认显示页
            search: {
                //搜索
                date: "", //日期
                expert: "", //预约专家
                status: "", //预约状态
                content: "" //文本内容
            },
            list: [],
            refuseId: "", //行拒绝id
            dialogVisible: false,
            refuseContent: "", //拒绝内容
            // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName: "", //请求参数名字
            parameter:'',
            // 操作性按钮弹框值   end
            tableTip: "系统正在检索中…",
            doctorList: [], //预约专家
            statusList: [], //状态列表
            exportDataSel: [] //导出的数据
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };
        this.getDoctorListT();
        this.getStatusList();
    },
    methods: {
        handleCurrentChange(val) {
            this.pages = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.psize = val;
            this.getList();
        },
        serach() {
            //搜索
            this.getList("搜索");
        },
        getList(operationName) {
            var fromDate = "";
            var toDate = "";
            if (this.search.date != "") {
                fromDate = this.search.date[0];
                toDate = this.search.date[1];
            }
            var that = this;
            var db = {
                from_date: fromDate, //	起始时间
                to_date: toDate, //	结束时间
                doctor_id: this.search.expert, //	医生的id
                status_id: this.search.status, //	预约类型
                pages: this.pages,
                pnums: this.psize,
                txt: this.search.content //搜索内容
            };
            this.$axios.post(RegisterList, db).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.list = res.data.data;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = res.data.count;
                            if (operationName == undefined) {
                                this.tableTip = "暂无挂号记录";
                            } else {
                                this.tableTip = "暂无搜索结果";
                            }
                        }
                    } else {
                        this.list = [];
                        this.tableTip = "暂无挂号记录";
                    }
                },
                err => {
                    this.list = [];
                    this.tableTip = "暂无挂号记录";
                }
            );
        },
        getDoctorListT() {
            //预约专家
            this.$axios.post(DoctorListT, {}).then(
                res => {
                    if (res.data.code == 1) {
                        this.doctorList = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
                err => {
                    this.$message.error(err.msg);
                }
            );
        },
        getStatusList() {
            //状态
            this.$axios.post(StatusList, {}).then(
                res => {
                    if (res.data.code == 1) {
                        this.statusList = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
                err => {
                    this.$message.error(err.msg);
                }
            );
        },
        operation(operationId,operationName){ //对数据的操作-按钮      
            //对数据的操作-按钮
            this.operationPsotName = "id";
            this.operationId = operationId; //修改组件id
            this.parameter = {
                id:operationId,
            };
            if (operationName == "同意") {
                this.issue = "确定同意挂号申请？";
                this.hintTxt = "同意该患者按约定时间来院挂号";
                this.operationPsotUrl = AgreeRegister;
                this.showHide = true; //显示组件
            } else {
                //拒绝
                this.dialogVisible = true;
            }
        },
        refuseBtn () {
            //拒绝
            var db = {
                id: this.operationId, //记录id
                txt: this.refuseContent //拒绝原因
            };
            this.$axios.post(RefuseRegister, db).then(
                res => {
                    if (res.data.code == 1) {
                        this.getList();
                        this.dialogVisible = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
                err => {
                    this.$message.error(err.msg);
                }
            );
        },
        selectionChange(sel) {
            //表格选中获取当前选中行
            this.exportDataSel = sel;
        },
        exportData() {
            //导出
            if (this.exportDataSel.length <= 0) {
                this.$message.info("至少选择一个用户进行导出");
            } else {
                var ids = [];
                for (var i = 0; i < this.exportDataSel.length; i++) {
                    ids.push(this.exportDataSel[i].id);
                }
                var db = {
                    ids: ids.join(",")
                };
                this.$axios
                    .post(ExportRegister, db, { responseType: "arraybuffer" })
                    .then(
                        res => {
                            let blob = new Blob([res.data], {
                                type: "application/vnd.ms-excel"
                            });
                            const fileName = res.headers[
                                "content-disposition"
                            ].match(/filename=(\S*).xls/)[1];
                            const elink = document.createElement("a");
                            elink.download = JSON.parse(fileName) + ".xls";
                            elink.href = window.URL.createObjectURL(blob);
                            elink.click();
                            window.URL.revokeObjectURL(elink.href);
                        },
                        err => {}
                    );
            }
        },
        childByOperationBtn: function(showHide, dataCode) {
            //接收按钮式操作子组件传值
            this.showHide = showHide;
            if (dataCode == 1) {
                this.getList();
            }
        },
        tableCellClassName({ row, rowIndex, column, columnIndex }) {
            if (row.status == "未确认" && columnIndex > 0) {
                return "bold";
            }
        }
    }
};
</script>

<style lang="scss">
</style>
