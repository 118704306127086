<template>
  <div>
    <adjust/>
  </div>
</template>
<script>
import adjust from './components/adjustCure.vue'
export default {
  components: {
    adjust
  },
  data () {
    return {

    }
  }
}
</script>
