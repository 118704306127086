<template>
    <div class="details-content">
        <!-- 新增及编辑  通知 -->
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane :label="labelTitle" name="first" lazy>
               <div class="details-container">
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                       <el-row>
                           <el-col :span="12">
                               <el-form-item label="新增标题" prop="msgName">
                                    <el-input v-model="ruleForm.msgName" maxlength="20" placeholder="请输入标题名称，20字以内"></el-input>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                            <el-form-item label="标题图片">
                                    <uoloadSingleImg 
                                      v-model="ruleForm.uploadPictures" 
                                      ref="uoloadSingleImg" 
                                      form="上传" 
                                      :uploadUrl="uploadUrl" 
                                      :uploadSize="2" 
                                      :fileList="fileList" 
                                      @childByValue="childByValue" 
                                      :tip="tip"
                                      :uoloadSingleStatus='uoloadSingleStatus'
                                    ></uoloadSingleImg>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                                <el-form-item label="内容" prop="content">
                                     <div class="editor-container" style="line-height:22px;">
                                        <UE :defaultMsg=defaultMsg :config=config ref="ue" v-model="ruleForm.content" v-if="ue"></UE>
                                     </div>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24" class="text-left">
                                <el-button  @click="submitForm('ruleForm')">确定</el-button>
                           </el-col>
                       </el-row>
                   </el-form>
               </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import UE from "../../components/editor";
import { UploadFoundImage } from "../../api/api";
import { AddFoundDetail } from "../../api/api"; //新增
import { EditFoundDetail } from "../../api/api"; //修改
import { FoundDetailInfo } from "../../api/api"; //详情
export default {
  components: {
    UE,
    uoloadSingleImg
  },
    data() {
            var checkContent = (rule, value, callback, text) => {
                // 获取富文本内容
                let contentUE = this.$refs.ue.getUEContent();
                if (contentUE != "" && contentUE != undefined) {
                    this.ruleForm.content = contentUE;
                    callback();
                } else {
                    callback(new Error("请填写内容"));
                }
            };
        return {
            lineId: this.$route.query.lineId, //康复行id 
            detailId: this.$route.query.detailId, //详情行id 
            lineType: this.$route.query.lineType, //类型   2为音乐
            activeName: "first",
            defaultMsg: "", //富文本默认值
            config: {
                initialFrameWidth: null,
                initialFrameHeight: 350
            },
            ruleForm:{
                msgName:'',//新增标题
                content:'',//内容
                uploadPictures:'',//标题图片
            },
            rules:{
                msgName: [{ required: true, message: "请输入新增标题", trigger: "change" }],
                content: [{ required: true, trigger: "change", validator: checkContent }],
            },
            tip:'请上传300*180尺寸的图片', //上传提示信息
            fileList:[],//默认显示图片
            uploadUrl:"",//上传单一图片地址
            uoloadSingleStatus:'',
            ue: false,
            labelTitle:'',
        };
    },
    created(){
         this.uploadUrl=UploadFoundImage;
         if(this.detailId!=''){ //详情
            this.labelTitle='编辑';
            this.uoloadSingleStatus='edit';
            var db = { id: this.detailId };
            this.$axios.post(FoundDetailInfo, db).then(
                res => {
                if (res.data.code == 1) {
                    this.ruleForm.msgName = res.data.data.title;
                    this.ruleForm.content = res.data.data.content;
                    this.defaultMsg = res.data.data.content;
                    this.ue = true;
                    this.fileList = res.data.data.img;
                    var imgUrl = [];
                    if(this.fileList.length>0){
                        for (var i = 0; i < this.fileList.length; i++) {
                            imgUrl.push(this.fileList[i].url);
                        }
                    }
                    this.ruleForm.uploadPictures = imgUrl.join(",");
                } else {
                    this.$message.error(res.data.msg);
                }
                },
                err => {
                this.$message.error(err.msg);
                }
            );
         }
         else{
             this.labelTitle='新增'
            this.uoloadSingleStatus='add'
            this.ue = true;
         }
    },
    methods:{
        goBack(){
            this.$router.push({ name: "contentRecoveryCheck" ,query:{lineId:this.lineId,lineType:this.lineType}});
        },
        submitForm(formName) { //保存
            this.$refs[formName].validate((valid) => {
            if (valid) {
               var postUrl;
               var db; 
               if(this.detailId==''){ //新增
                   postUrl=AddFoundDetail;
                   db={
                        title	:this.ruleForm.msgName,//	标题
                        img	:this.ruleForm.uploadPictures,//	图片地址
                        content	:this.$refs.ue.getUEContent(),//	内容
                        line_id	:this.lineId,//	上级康复记录id
                   }
               }else{ //修改
                    postUrl=EditFoundDetail;
                    db={
                        title	:this.ruleForm.msgName,//	标题
                        img	:this.ruleForm.uploadPictures,//	图片地址
                        content	:this.$refs.ue.getUEContent(),//	内容
                        id	:this.detailId,//	康复记录id
                    }
               }
                this.$axios.post(postUrl, db).then(
                    res => {
                        if (res.data.code == 1) {
                          this.goBack();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    },
                    err => {
                        this.$message.error(err.msg);
                    }
               );
            } else {
                console.log('error submit!!');
                return false;
            }
            });
      },
        childByValue(val,type){
            this.fileList = [val];
            this.ruleForm.uploadPictures = val;
        },
    },
}
</script>

<style lang="scss">
</style>
