<template>
    <!-- 用户管理列表页面 -->
    <div  id="doctorManageList" class="communal" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="24">
                        <el-input  placeholder="请输入关键字查询" v-model="form.txt"></el-input>
                        <el-button type="success" plain @click="onSubmit">搜索</el-button>
                    </el-col>
                </el-row>     
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        style="width: 100%"
                        :height="contentHeight"
                    >
                        <el-table-column prop="username" label="姓名" min-width="120">
                        </el-table-column>
                        <el-table-column prop="telphone" label="联系方式" min-width="150"></el-table-column>
                        <el-table-column prop="card_number" label="身份证号" min-width="170"></el-table-column>
                        <el-table-column prop="open_id" label="微信id" min-width="290"></el-table-column>
                        <el-table-column prop="addtime" label="注册日期" min-width="180"></el-table-column>
                        <el-table-column prop="status_text" label="状态" min-width="130"></el-table-column>
                        <el-table-column prop="name" label="操作" fixed="right" min-width="100">
                            <template slot-scope="scope">
                                <el-button type="text" @click.stop="skipInfo(scope.row.doctor_user_id,scope.row.doctor_info_id,scope.row.status_text)">{{scope.row.status_text == '正常' ? '审核': '查看'}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { CheckList } from "../../api/api";
import { DoctorFreeze } from "../../api/api";
import { DoctorUnFreeze } from "../../api/api";
import { constants } from 'crypto';
export default {
    components:{

    },
    data(){
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form:{
                txt:'',//文字
            },
            list:[],//表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip:'系统正在检索中…',
        }
    },
    created() {
    },
    mounted() {
        var that=this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };
        this.getList();
    },

    methods: {
        //获取医生列表
        getList(){
            var that = this;
            var db = {
                txt:this.form.txt,
                pnums:this.psize,
                pages:this.pages,
            };
            this.$axios.post(CheckList,db).then(res => {
                    if(res.data.code == 1){
                        if(res.data.data.length > 0){
                            this.list = res.data.data;
                            this.totals = res.data.count;
                        }else{
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = '无相关结果';
                        }
                    }else{
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = '无相关结果';
                    }
                        
                }, err => {
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = '无相关结果';
                });
        },
        //查看跳转
        skipInfo(uid,iid,status){
            this.$router.push({
                path: "/doctorManage/viewInfoD",
                query: { 'doctor_info_id': iid,'doctor_user_id':uid,'from':'doctorManageD','dstatus':status}
            });
        },
        //搜索
        onSubmit(){
            this.pages = 1;
            this.getList();
        },
        handleCurrentChange(val){
            this.pages = val;
            this.getList();
        },
        handleSizeChange(val){
            this.psize = val;
            this.getList();
        },
    },
    
}
</script>
<style lang="scss">

</style>
