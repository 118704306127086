<template>
  <div class="service">
    <span>门诊医生：{{ doctor_name }}</span>
    <el-button class="fr" size="small" plain @click="goBack"
      >返回 <i class="el-icon-arrow-right"></i
    ></el-button>
    <div>
      <table border="1" cellspacing="0" bordercolor="#ddd">
        <thead>
          <th v-for="(items, indexs) in titleList" :key="indexs" class="th">
            {{ items }}
          </th>
        </thead>
        <tbody>
          <tr v-for="(items, indexs) in patientList" :key="indexs">
            <td class="td">{{ items.time }}</td>
            <td
              v-for="(items2, indexs2) in items.week"
              :key="indexs2"
              class="td"
            >
              <el-checkbox
                v-model="items2.is_select"
                @change="changeCheckbox($event, items2)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-20">
        <el-switch
          v-model="is_series"
          active-color="rgba(24, 144, 255, 1)"
          inactive-color="rgba(153, 153, 153, 1)"
          @change="changeSwitch(is_series)"
        >
        </el-switch
        ><span class="mf-20">延续此门诊排版时间</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  doctorDetailServiceTime,
  doctorEditServiceTime,
  doctorEditSeries,
  GetTitleList,
} from "../../api/api";
export default {
  data() {
    return {
      titleList: ["", "周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      patientList: [],
      doctor_info_id: this.$route.query.doctor_info_id,
      doctor_name: this.$route.query.doctor_name,
      value: true,
      is_series: false,
    };
  },
  created() {
    this.getDoctorInfo();
    this.getTitle();
    console.log(this.doctor_info_id);
    console.log(this.doctor_name);
  },
  methods: {
    getDoctorInfo() {
      this.$axios
        .post(doctorDetailServiceTime, { doctor_info_id: this.doctor_info_id })
        .then(
          (res) => {
            if (res.data.code == "1") {
              // console.log(JSON.stringify(res.data.data.list));
              this.patientList = res.data.data.list;
              this.is_series = res.data.data.is_series;
            }
          },
          (err) => {
            this.$message.error(err.msg);
          }
        );
    },
    getTitle() {
      this.$axios.post(GetTitleList, {}).then((res) => {
        if (res.data.code == "1") {
          this.titleList = res.data.data;
        }
      });
    },
    changeCheckbox(event, item) {
      console.log(item.is_select);
      var db = {
        doctor_info_id: this.doctor_info_id,
        time: item.time,
        date: item.date,
        is_select: item.is_select,
      };
      console.log(db);
      this.$axios.post(doctorEditServiceTime, db).then(
        (res) => {
          if (res.data.code == "1") {
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    goBack() {
      this.$router.push({
        name: "mzxq",
      });
    },
    changeSwitch(val) {
      console.log(val);
      var db = {
        doctor_info_id: this.doctor_info_id,
        is_series: val === true ? 1 : 2,
      };
      console.log(db);
      this.$axios.post(doctorEditSeries, db).then(
        (res) => {
          if (res.data.code == "1") {
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.service {
  padding: 20px;
}
table {
  // border: 1px solid #ddd;
  width: 80%;
  margin-top: 10px;
}
.th,
td {
  // width: 13%;
  text-align: center;
  padding: 10px;
}
.th {
  // background: #f2f2f2;
}
.td {
  // background: #f6f6f6;
}
</style>