<template>
  <div class="index">
    <!-- 页面模板 -->
    <el-container>
      <el-header class="index-header">
        <div class="content clearfix">
          <div class="logo bottom">
            <img src="../../assets/image/logo-index.png" class="bottom">
            <span class="font-s14 cff mf-20 bottom i-block" style="height: 20px;font-size:18px">{{hospitalName.hospital_title}}</span>
          </div>
          <!-- @click="exit" -->
          <div class="operation bottom">
            <!-- 操作 -->
            <div class="fr">
              <el-dropdown @command="accountBtn">
                <span class="el-dropdown-link cff ">
                  <img src="../../assets/image/dropdown-link.png" class="middle">
                  {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="修改密码">修改密码</el-dropdown-item>
                  <el-dropdown-item command="切换中心" v-if="isCenter==true">切换中心</el-dropdown-item>
                  <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 数据大屏 -->
            <!-- <div class="fr data-screen" @click="skipShowBig">
              <img src="../../assets/image/data-screen.png" class="middle"> 心理数据监控大屏
            </div> -->
          </div>
        </div>
      </el-header>
      <el-container class="index-container">
        <el-aside width="200px" class="index-aside">
          <!-- menu start -->
          <el-row class="menu">
            <el-col :span="24">
              <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @select="handleSelect" :default-openeds="['医生管理','业务管理','课程管理','内容管理','系统设置']">
                <!-- menu start -->
                <div v-for="(item,index) of menu" :key="index" v-if="menu.length>0">
                  <el-submenu :index="item.name" v-if="item.list.length>0" class="more">
                    <template slot="title">
                      <i :class="item.icon"></i>
                      <span>{{item.name}}</span>
                    </template>
                    <el-menu-item v-for="(item,index) of item.list" :index="item.sign" :key="index">
                      <router-link :to="item.link">{{item.name}}{{item.prompt}}</router-link>
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item :index="item.sign" v-if="item.list.length==0">
                    <router-link :to="item.link" class="c33"> <i :class="item.icon"></i>{{item.name}}</router-link>
                  </el-menu-item>
                </div>
                <!-- menu end -->
              </el-menu>
            </el-col>
          </el-row>
          <!-- menu end -->
        </el-aside>
        <el-main class="index-main">
          <!-- 路径 -->
          <div class="crumb">
            <template v-if="$route.meta.name" v-for="(item,index) in $route.meta.name">
              <span v-html="item"></span>
            </template>
          </div>
          <router-view class="index-view" :parentMsg="parentMsg" v-if="parentMsg.length>0"></router-view>
        </el-main>
      </el-container>
    </el-container>
    <!-- 切换中心 -->
    <el-dialog title="切换中心" :visible.sync="dialogVisible" width="480px" top="20%" class="communal">
      <div style="padding:30px 60px 0px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
          <el-form-item prop="hospitalId">
            <el-radio-group v-model="ruleForm.hospitalId">
              <div v-for="(val,index) in hospitalListData" style="padding-bottom:20px">
                <el-radio :label="val.hospital_id">{{val.title}}</el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </el-form>

      </div>
      <!-- 按钮 -->
      <div class="account-footer clearfix">
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
        <div>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogPassWord" width="480px" top="20%" class="communal">
      <div style="padding:30px 40px 0px">
        <el-form :model="rulePasswordFrom" :rules="rulePassword" ref="rulePasswordFrom" label-width="130px" class="demo-ruleForm">
          <el-form-item prop="password" label="输入旧密码">
            <el-input type="password" v-model="rulePasswordFrom.password" placeholder="输入旧密码"></el-input>
          </el-form-item>
          <el-form-item prop="newpassword" label="设置新密码">
            <el-input type="password" v-model="rulePasswordFrom.newpassword" placeholder="请输入6-12位新密码"></el-input>
          </el-form-item>
          <el-form-item prop="repassword" label="再次输入新密码">
            <el-input type="password" v-model="rulePasswordFrom.repassword" placeholder="再次输入新密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="account-footer clearfix">
        <div>
          <el-button @click="dialogPassWord = false">取 消</el-button>
        </div>
        <div>
          <el-button type="primary" @click="submitFormPasswoed('rulePasswordFrom')">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { LogOut } from "../../api/api";
import { FindUserRole } from "../../api/api";
import bus from "../../components/bug.js";
import { constants } from "crypto";
import { HospitalList } from "../../api/api"; //中心列表
import { FindUserHospitalName } from "../../api/api"; //名称
import { CutHospital } from "../../api/api"; //切换中心
import { EditUserPassword } from "../../api/api"; //登录状态修改密码
export default {
  data() {
    var checkPwd = (rule, value, callback, text) => {
      this.common.Validate.checkPwd(rule, value, callback, "密码");
    };
    var checkConfirmPwd = (rule, value, callback, text) => {
      if (!value) {
        return callback(new Error("请输入确认密码"));
      } else {
        setTimeout(() => {
          var strCheck = /^\d{6,12}$|^(?!\d+$)\w{6,12}$/;
          if (strCheck.test(value) == false) {
            callback(new Error("请输正确的格式，6-12位数字加字母！"));
          } else {
            if (this.rulePasswordFrom.newpassword == value) {
              callback();
            } else {
              callback(new Error("两次密码不一致，请重新输入！"));
            }
          }
        }, 500);
      }
    };
    return {
      activeIndex: "",
      userName: sessionStorage.getItem("username").replace(/\"/g, ""),
      menuIndex: "", //页面刷新显示值
      menu: [], //导航
      isCenter: "", //切换中心   判断是否有中心设置权限
      dialogVisible: false,
      hospitalListData: [], //中心列表
      ruleForm: {
        hospitalId: "", //选择的中心
      },
      rules: {
        hospitalId: [
          { required: true, message: "请选择切换中心", trigger: "change" },
        ],
      },
      hospitalName: "", //中心名称
      parentMsg: [], //传入子元素权限值

      dialogPassWord: false, //修改密码
      rulePasswordFrom: {
        password: "",
        newpassword: "",
        repassword: "",
      },
      rulePassword: {
        password: [
          { required: true, trigger: "change", message: "请输入旧密码" },
        ],
        newpassword: [
          { required: true, trigger: "change", validator: checkPwd },
        ],
        repassword: [
          { required: true, trigger: "change", validator: checkConfirmPwd },
        ],
      },
    };
  },
  computed: {
    activeInfo() {
      // 计算常亮标识
      const route = this.$route;
      const { meta } = route;
      return meta.activeInfo;
    },
  },
  created() {
    this.getFindUserRole();
    this.getFindUserHospitalName();
  },
  mounted() {
    this.getHospitalList();
  },
  methods: {
    skipShowBig() {
      var path = "/dataShowBig/dataShowBigIndex";
      var { href } = this.$router.resolve({
        path: path,
      });
      window.open(href, "_blank");
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    getFindUserHospitalName() {
      //中心名称
      this.$axios.post(FindUserHospitalName, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.hospitalName = res.data.data;
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    getFindUserRole() {
      this.$axios.post(FindUserRole, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.menu = res.data.data; //获取菜单
            this.parentMsg = res.data.data;
            this.isCenter = false;
            if (res.data.data.length > 0) {
              // 默认导航高亮
              if (
                res.data.data[0].sign != "" &&
                res.data.data[0].sign != null
              ) {
                this.activeIndex = res.data.data[0].sign;
                this.$router.push({ path: res.data.data[0].link });
              } else {
                this.activeIndex = res.data.data[0].list[0].sign;
                this.$router.push({ path: res.data.data[0].list[0].link });
              }
              // 是否有切换中心的权限
              for (var i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].node_id == 7) {
                  for (var j = 0; j < res.data.data[i].list.length; j++) {
                    if (res.data.data[i].list[j].node_id == 21) {
                      this.isCenter = true;
                    }
                  }
                }
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    getHospitalList() {
      //中心列表
      this.$axios.post(HospitalList, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.hospitalListData = res.data.data;
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    accountBtn(command) {
      //账号操作
      if (command == "退出登录") {
        this.exit();
      } else if (command == "切换中心") {
        this.dialogVisible = true;
      } else {
        this.rulePasswordFrom.password = "";
        this.rulePasswordFrom.newpassword = "";
        this.rulePasswordFrom.repassword = "";
        this.dialogPassWord = true;
      }
    },
    exit() {
      //退出
      var that = this;
      this.$confirm("确定退出该系统？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that.$axios.post(LogOut, {}).then(
          (res) => {
            that.loading = true;
            if (res.data.code == 1) {
              sessionStorage.removeItem("username");
              // that.$router.push("/login");
              that.$router.push({ name: "login", query: { from: "退出" } });
            } else {
              that.$message.error(res.data.msg);
            }
          },
          (err) => {
            that.$message.error(err.data.msg);
          }
        );
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var db = {
            hospital_id: this.ruleForm.hospitalId,
          };
          this.$axios.post(CutHospital, db).then(
            (res) => {
              if (res.data.code == 1) {
                this.dialogVisible = false;
                this.getFindUserHospitalName();
                location.reload();
              }
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            },
            (err) => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitFormPasswoed(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var db = {
            old_password: this.rulePasswordFrom.password,
            password: this.rulePasswordFrom.newpassword,
            repassword: this.rulePasswordFrom.repassword,
          };
          this.$axios.post(EditUserPassword, db).then(
            (res) => {
              if (res.data.code == 1) {
                this.dialogPassWord = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            },
            (err) => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.index {
  .content {
    overflow-y: hidden;
  }
  height: calc(100vh);
  .index-header {
    //顶部
    height: 72px !important;
    background: -webkit-linear-gradient(right, #25bbdb 0%, #40b0de 100%);
    background: -o-linear-gradient(right, #25bbdb 0%, #40b0de 100%);
    background: -moz-linear-gradient(right, #25bbdb 0%, #40b0de 100%);
    background: -ms-linear-gradient(right, #25bbdb 0%, #40b0de 100%);
    background: linear-gradient(right, #25bbdb 0%, #40b0de 100%);
    padding: 0 40px 0 30px;
    min-width: 1150px;
    .content {
      padding-top: 18px;
      .logo,
      .operation {
        width: 50%;
      }
      .logo {
        float: left;
        margin-top: 2px;
      }
      .operation {
        float: right;
        font-size: 18px;
        color: yellow;
        cursor: pointer;
        .data-screen {
          margin-right: 47px;
          border: 1px solid yellow;
          border-radius: 6px;
          padding: 2px 12px 2px;
        }
      }
    }
  }
  .index-container {
    //下方容器
    background: #f4f4f4;
    height: calc(100vh - 72px);
    padding: 0 30px 0 0;
    .index-aside {
      //导航
      background: #ffffff;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
      // border-radius: 6px;
      .el_icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-top: -3px;
        margin-right: 17px;
      } //   二级颜色
      .el-submenu .el-menu-item:hover,
      .el-submenu .el-menu-item:focus {
        outline: none;
        background: transparent;
      } // 一级
      .el-menu-item:hover,
      .el-menu-item:focus {
        outline: none;
        background: #e6faf4;
        a {
          color: #999999;
        }
      } // 有子菜单
      .more {
        .el-menu-item.is-active a {
          color: #25bbdb;
        }
        a {
          display: block;
          color: #999999; // width: 120px;
        }
      } // 无子菜单
      .alone {
        a {
          color: #303133;
          display: block;
        }
        .router-link-active {
          color: #ffffff;
        }
      }
      .icon-system {
        //系统
        background: url("../../assets/image/system.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-content {
        //内容
        background: url("../../assets/image/content.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-business {
        //业务
        background: url("../../assets/image/business.png") no-repeat;
        background-size: 18px 18px;
      } //
      .icon-doctor {
        //医生
        background: url("../../assets/image/doctor.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-user {
        //患者
        background: url("../../assets/image/user.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-businessCenter {
        //业务统计
        background: url("../../assets/image/businessCenter.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-data {
        //平台统计
        background: url("../../assets/image/data.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-courseManage {
        //平台统计
        background: url("../../assets/image/courseManage.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-scaleMange {
        //量表管理
        background: url("../../assets/image/scaleMange.png") no-repeat;
        background-size: 18px 18px;
      }
    }
    .index-main {
      //内容
      padding: 30px 0;
      margin-left: 30px;
      min-width: 890px; //内容最小宽度
      .crumb {
        font-size: 18px;
        color: #999999;
        letter-spacing: 0;
        height: 35px;
      }
      .crumb span:after {
        content: "/";
        margin: 0 5px;
        font-weight: normal !important;
        color: #999999 !important;
      }
      .crumb span:last-child:after {
        display: none;
      }
      .index-view {
        background: #ffffff;
        // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        height: calc(100vh - 167px);
        overflow-y: auto; // min-width: 890px;
      }
    }
    .bg-color {
      background: rgba(37, 187, 219, 0.6);
      span {
        color: rgba(255, 255, 255, 1);
      }
      .icon-user {
        //患者
        background: url("../../assets/image/user-active.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-businessCenter {
        //业务统计
        background: url("../../assets/image/businessCenter-active.png")
          no-repeat;
        background-size: 18px 18px;
      }
      .icon-data {
        //业务统计
        background: url("../../assets/image/data-active.png") no-repeat;
        background-size: 18px 18px;
      }
      .icon-scaleMange {
        //量表管理
        background: url("../../assets/image/scaleMange-active.png") no-repeat;
        background-size: 18px 18px;
      }
    } // 导航滚动条
    .index-aside::-webkit-scrollbar {
      width: 0px;
      background: #ffffff;
    }
  }
}
.blue-color {
  color: red;
}
</style>
