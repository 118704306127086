<template>
  <!-- 转诊管理 -->
  <div class="details-content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.c_name" :name="item.pid+''" />
      <div style="padding:20px 30px">
        <el-row>
          <el-col :span="6">
            <span class="span">创建时间</span>
            <el-date-picker v-model="search.date" :picker-options="pickerOptions0" clearable style="width:266px" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="止"
              value-format="yyyy-MM-dd" size="small" @change="getList('search')" />
          </el-col>
          <el-col v-if="activeName == 4" :span="5">
            <span class="span">类别</span>
            <el-select v-model="search.type" clearable placeholder="请选择" size="small" class="sm-long" @change="getList('search')">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-col>
          <el-col :span="5">
            <span class="span">状态</span>
            <el-select v-model="search.status" clearable placeholder="请选择" size="small" class="sm-long" @change="getList('search')">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-col>
          <el-col :span="5">
            <div>
              <el-input v-model="search.txt" placeholder="请输入搜索内容" class="sm-long" @change="getList('search')" size="small"><i slot="prefix" class="el-input__icon el-icon-search" />
              </el-input>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="resetTable()">重置</el-button>
          </el-col>
        </el-row>
        <!-- 新增或导入导出 -->
        <div class="mt-10">
          <el-button type="primary" size="small" @click="onClick('新增','')">新增</el-button>
        </div>
        <div class="mt-10">
          <el-table ref="multipleTable" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%;" :height="514"
            :header-cell-style="{background:'#F2F2F2',color:'#333',textAlign:'center'}" :cell-style="{textAlign:'center'}" border>
            <el-table-column prop="addtime" label="创建时间" min-width="120" show-overflow-tooltip />
            <el-table-column prop="title" v-if="activeName != 2" label="名称" min-width="60" show-overflow-tooltip />
            <el-table-column prop="title" v-if="activeName == 2" label="音乐名称" min-width="60" show-overflow-tooltip />
            <el-table-column prop="category_name" v-if="activeName == 4" label="类别" min-width="100" show-overflow-tooltip />
            <el-table-column prop="price" label="价格" min-width="100" show-overflow-tooltip />
            <el-table-column prop="people" label="收听数" v-if="activeName == 1 || activeName == 2" min-width="50" show-overflow-tooltip />
            <el-table-column prop="people" label="浏览量" v-if="activeName == 3 || activeName == 4" min-width="50" show-overflow-tooltip />
            <el-table-column prop="share" label="分享数" min-width="50" show-overflow-tooltip />
            <!-- <el-table-column label="位置" min-width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="img">
                  <img src="../../assets/down.png" alt="" @click="moveOn(scope.row)">
                </span>
                <span class="img">
                  <img src="../../assets/up.png" alt="" @click="moveDown(scope.row)">
                </span>
              </template>
            </el-table-column> -->
            <el-table-column prop="sale_value" label="状态" min-width="100" show-overflow-tooltip />
            <el-table-column label="操作" fixed="right" min-width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button type="text" @click="onClick('查看',scope.row)">查看</el-button>
                <el-button v-show="scope.row.is_sale == '2'" type="text" @click="onClick('上架',scope.row)">上架
                </el-button>
                <el-button v-show="scope.row.is_sale == '1'" type="text" @click="onClick('下架',scope.row)">下架
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 100-->
        <div ref="paging" class="paging">
          <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 50, 100, 2000]" :page-size="psize" layout="total, sizes, prev, pager, next, jumper" :total="totals"
            @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
      </div>
    </el-tabs>
  </div>
</template>
<script>
import { clocksList, upDownOneClocks, getCategory } from '../../api/api'
import common from '../../components/common'
export default {
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        },
      },
      activeName: '1',
      btype: this.$route.query.type,
      tabList: [], // tab列表数据
      search: {
        type: '', // 类别
        status: '', // 状态
        txt: '', // 搜索
        date: '',
      },
      dates: '',
      totals: 3,
      currentPage: 1, // 当前页
      psize: 10,
      pages: '1',
      list: [], // 表格数据
      loading: false,
      statusList: [
        {
          // 下拉框状态数据
          value: '0',
          label: '全部',
        },
        {
          value: '1',
          label: '已上架',
        },
        {
          value: '2',
          label: '已下架',
        },
      ],
      typeList: [
        // 下拉框类别数据
        {
          value: '2',
          label: '食物属性',
        },
        {
          value: '3',
          label: '生活建议',
        },
      ],
    }
  },
  created() {
    this.activeName =
      this.$route.query.type === undefined ? '1' : this.$route.query.type
  },
  mounted() {
    var that = this
    that.common.Count.pageResize(that)
    window.onresize = function temp() {
      that.common.Count.pageResize(that)
    }
    this.getTabData()
    this.getList()
  },
  methods: {
    handleClick(target) {
      this.activeName = target.name
      this.getList()
    },
    handleCurrentChange(val) {
      this.pages = val
      this.getList()
    },
    handleSizeChange(val) {
      this.psize = val
      this.pages = 1
      this.currentPage = 1
      this.getList()
    },
    // 获取tab数据
    getTabData() {
      this.$axios.post(getCategory, {}).then(
        (res) => {
          console.log(res)
          if (res.data.code == '1') {
            this.tabList = res.data.data.data
          }
        },
        (err) => {
          this.$message.error(err.msg)
        }
      )
    },
    // 获取列表数据
    getList(btnName) {
      if (btnName === 'search') {
        this.pages = 1
        this.currentPage = 1
      }
      if (this.search.date !== null) {
        if (this.search.date.length > 0) {
          this.dates = this.search.date.join('~')
        }
      }
      var db = {
        pid: this.activeName,
        keywords: this.search.txt,
        between_time: this.dates,
        diet_cate: this.search.type,
        status: this.search.status,
        pages: this.pages,
        pnums: this.psize,
      }
      this.loading = true
      this.common.communalApi.getTableList(
        {
          url: clocksList,
          db: db,
        },
        this.getData
      )
    },
    getData(list, totals) {
      this.$set(this, 'list', list)
      this.$set(this, 'totals', totals)
      this.loading = false
    },
    // 搜索事件
    // txtInput: common.tools.debounce(function (e) {
    //   this.getList('search')
    // }),
    // 重置
    resetTable() {
      this.search.date = ''
      this.dates = ''
      this.search.status = ''
      this.search.txt = ''
      this.getList('search')
    },
    onClick(btnName, row) {
      if (btnName === '上架' || btnName === '下架') {
        var db = {
          option_id: row.option_id,
          status: btnName === '下架' ? 2 : 1,
        }
        
         this.$confirm(`确认${btnName}该条数据吗？`, '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            this.$axios.post(upDownOneClocks, db).then((res) => {
              console.log(res);
              if (res.data.code == '1') {
                this.$message({
                  type: 'success',
                  message: res.data.msg,
                })
                // callback() // 重新调用列表
                this.getList()
              } else {
                that.$message.error(res.data.msg)
              }
            })
          })
          .catch(() => {})
        // this.common.communalApi.buttonMsg(
        //   {
        //     url: upDownOneClocks,
        //     db: db,
        //     title: '提示',
        //     content1: `确认${btnName}该条数据吗？`,
        //   },
        //   this,
        //   this.getList
        // )
      } else {
        var name = ''
        var type = this.activeName
        var id = ''
        if (btnName === '查看') {
          id = row.option_id
        }
        if (type === '1') {
          name = 'relaxDetail'
        } else if (type === '2') {
          name = 'musicDetail'
        } else if (type === '3') {
          name = 'emotionDetail'
        }
        this.$router.push({
          name: name,
          query: {
            type: type,
            id: id,
          },
        })
      }
    },
    // 上移
    moveOn(row) {
      const index = this.list.findIndex((x) => x.option_id === row.option_id)
      var lists = this.list
      if (index === 0) {
        this.$message({
          message: '处于顶端，不能继续上移',
          type: 'warning',
        })
      } else {
        // 选中的上一行
        const len = lists[index - 1]
        // 将选中的一行往上移
        this.$set(lists, index - 1, lists[index])
        // 将选中的上一行往下移
        this.$set(lists, index, len)
      }
    },
    // 下移
    moveDown(row) {
      const index = this.list.findIndex((x) => x.option_id === row.option_id)
      var lists = this.list
      if (index === lists.length - 1) {
        this.$message({
          message: '处于低端，不能继续下移',
          type: 'warning',
        })
      } else {
        // 选中的下一行
        const len = lists[index + 1]
        // 将选中的一行往下移
        this.$set(lists, index + 1, lists[index])
        // 将选中的下一行往上移
        this.$set(lists, index, len)
      }
    },
  },
}
</script>

<style lang="scss">
.span {
  margin-right: 10px;
}
</style>
