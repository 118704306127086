<template>
    <div class="upload1">
        <!--WJ：2019.06.22
             多图上传公用组件
             备注：限制类型为图片；限制上传数量可更改；有查看、删除功能
         -->
          <el-upload :action="actionUrl()" list-type="picture-card" class="uploadHead1"
               :on-preview="handlePictureCardPreview" 
               :on-remove="handleRemove" 
               :on-success="handleAvatarSuccess"
			   :before-upload="beforeAvatarUpload"  
               :limit="limit"
               :on-exceed="handleExceed"
               :file-list="imgList"
                >
			  <i class="el-icon-plus">上传</i>
			</el-upload>
      <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" >
      </el-dialog>
    </div>
</template>

<script>
import { publicUrl } from "../api/api.js";

export default {
  name: "upload",
  data() {
    return {
      dialogImageUrl: "", //上传
      dialogVisible: false, //上传
      imageUrl: "", //上传成功
      imageUrlOne: [], //上传成功时保存图片  提交
      imageUrlTwo: [], //移除成功时保存图片
      imageUrlOneS: [],
      imageUrlTwo2: [],
      limit: 0 //限制上传的数量
    };
  },
  props: {
    fileName: "", //文件名   判断上传用哪个接口
    imgList: "" //图片默认显示
  },
  created() {
  },
  methods: {
    actionUrl() {
      //上传地址
      if (this.fileName == "content") {
        //内容管理
        this.limit = 1;
        return publicUrl + "/adminapi/System/UploadArticleImage";
      }
      if (this.fileName == "topic") {
        //话题管理
        this.limit = 5;
        return publicUrl + "/adminapi/System/UploadImage";
      }

      if(this.fileName == 'zc'){
        //职称
        this.limit = 500;
        return publicUrl + "/adminapi/System/UploadPosition";
      }

      if(this.fileName == 'zs'){
        //证书
        this.limit = 500;
        return publicUrl + "/adminapi/System/UploadCert";
      }
    },
    handleRemove(file, fileList) {  
      //移除
      if(file.response != undefined){
        var responseNewImg = file.response;
        this.imageUrlTwo2 = [];
        for (var i = 0; i < this.imageUrlOne.length; i++) {
          if (this.imageUrlOne[i] != responseNewImg.data) {
            this.imageUrlTwo2.push(this.imageUrlOne[i]);
          }
        }
        this.imageUrlOne = this.imageUrlTwo2;
        let reImg = file.name;
        this.imageUrlTwo = [];
        for (var i = 0; i < this.imageUrlOneS.length; i++) {
          if (this.imageUrlOneS[i] != reImg) {
            this.imageUrlTwo.push(this.imageUrlOneS[i]);
          }
        }
        this.imageUrlOneS = this.imageUrlTwo;
        this.$emit("childByValue", this.imageUrlOne,this.fileName);
      }else{
        this.imageUrlOne = fileList;
        for (var i = 0; i < this.imageUrlOne.length; i++) {
          if (this.imageUrlOne[i].url != file.url) {
            this.imageUrlOne.splice(i,0)
          }
        }
        this.$emit("childByValue", this.imageUrlOne,this.fileName);
      }
    },
    handlePictureCardPreview(file) { 
      //点击文件列表中已上传的文件时的钩子
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(res,file);
      this.imageUrl = URL.createObjectURL(file.raw);
      var reImg = res.data;
      let imgName = reImg;
      this.imageUrlOne = this.imgList;
      this.imageUrlOne.unshift(imgName);
      console.log(this.imgList);
      this.$emit("childByValue", this.imageUrlOne,this.fileName);
      let imgName2 = file.name;
      this.imageUrlOneS.unshift(imgName2);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit}  个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeAvatarUpload(file) {
      for (var i = 0; i < this.imageUrlOneS.length; i++) {
        if (this.imageUrlOneS[i] == file.name) {
          this.$message({
            message: "该图片已上传！",
            type: "warning"
          });
          return false;
        }
      }
      const extension =
        file.name.substring(file.name.lastIndexOf(".")) === ".jpg";
      const extension2 =
        file.name.substring(file.name.lastIndexOf(".")) === ".png";
      const extension3 =
        file.name.substring(file.name.lastIndexOf(".")) === ".gif";
      const extension4 =
        file.name.substring(file.name.lastIndexOf(".")) === ".JPG";
      const extension5 =
        file.name.substring(file.name.lastIndexOf(".")) === ".PNG";
      const extension6 =
        file.name.substring(file.name.lastIndexOf(".")) === ".GIF";
      const extension7 =
        file.name.substring(file.name.lastIndexOf(".")) === ".JPEG";
      const extension8 =
        file.name.substring(file.name.lastIndexOf(".")) === ".jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (
        !extension &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6 &&
        !extension7 &&
        !extension8
      ) {
        this.$message({
          message: "上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
          type: "warning"
        });
      }
      if (!isLt2M) {
        this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "warning"
        });
      }
      return (
        (extension && isLt2M) ||
        (extension2 && isLt2M) ||
        (extension3 && isLt2M) ||
        (extension4 && isLt2M) ||
        (extension5 && isLt2M) ||
        (extension6 && isLt2M) ||
        (extension7 && isLt2M) ||
        (extension8 && isLt2M)
      );
    }
  }
};
</script>

<style lang="scss">
.upload1 {
  .el-upload--picture-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  .el-upload--picture-card i {
    font-size: 14px;
  }
  .el-upload-list--picture-card {
    width: 80px;
    height: 80px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
    margin: 0px 8px 0px 0px;
  }
}
</style>
