<template>
    <div>
        <!-- 数据统计中心 -->
        <router-view :parentMsg="parentMsg"/>
    </div>
</template>

<script>
export default {
     props: {
        parentMsg:''
    }
}
</script>

<style lang="scss" scoped>
</style>
