<template>
  <!-- 康复管理 -->
  <div class="communal" ref="communal">
    <!-- 顶部固定 -->
    <div class="top-fixed" ref="topFixed">
      <div class="container fixed-style">
        <el-row>
          <el-col :span="5" class="text-left">
            <el-button type="success" style="margin-left: 0;" @click="addEdit('','')">新增康复</el-button>
          </el-col>
          <el-col :span="19">
            <el-input placeholder="请输入关键字查询" v-model="content"></el-input>
            <el-button type="success" plain @click="serach">搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="interval"></div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="container" :style="{height:contentHeight+'px'}">
        <template>
          <el-table
            :data="list"
            style="width: 100%"
            :height="contentHeight"
            :row-class-name="tableRowClassName"
            :empty-text="tableTip"
          >
            <el-table-column prop="title" label="科普标题"></el-table-column>
            <el-table-column prop="addtime" label="发布时间"></el-table-column>
            <el-table-column prop="statusname" label="状态"></el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click.stop="addEdit(scope.row.line_id,scope.row.type)">查看</el-button>
                <el-button type="text" @click.stop="edit(scope.row.line_id)">编辑</el-button>
                <el-button
                  type="text"
                  @click.stop="operation(scope.row.line_id,'上架')"
                  v-if="scope.row.status!=1"
                >上架</el-button>
                <el-button
                  type="text"
                  @click.stop="operation(scope.row.line_id,'下架')"
                  v-if="scope.row.status==1"
                >下架</el-button>
                <el-button
                  type="text"
                  @click.stop="operation(scope.row.line_id,'删除')"
                  v-if="scope.row.status!=1"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <!-- 分页 100-->
    <div class="paging" ref="paging">
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-sizes="[10, 50, 100, 2000]"
        :page-size="psize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totals"
      ></el-pagination>
    </div>
    <!-- 弹框  start -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" top="20%">
      <div style="padding:30px 60px 0">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="ruleForm.name" maxlength="20" placeholder="请输入类型名称,20字以内"></el-input>
          </el-form-item>
          <el-form-item label="上传背景图片" prop="uploadPictures">
            <uoloadSingleImg
              v-model="ruleForm.uploadPictures"
              ref="uoloadSingleImg"
              form="上传"
              :uploadUrl="uploadUrl"
              :fileList="fileList"
              @childByValue="childByValue"
              :tip="tip"
              :uploadSize="2"
              :uoloadSingleType="'img'"
              :uoloadSingleStatus="uoloadSingleStatus"
              v-if="dialogVisible"
            ></uoloadSingleImg>
          </el-form-item>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="account-footer clearfix">
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
        <div>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="loading"
          >{{loading?'保存中':'确定'}}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹框  end -->
    <operationBtn
      v-if="showHide"
      :issue="issue"
      :hintTxt="hintTxt"
      :showHide="showHide"
      :operationId="operationId"
      :operationPsotUrl="operationPsotUrl"
      :operationPsotName="operationPsotName"
      @childByOperationBtn="childByOperationBtn"
    ></operationBtn>
  </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import uoloadSingleImg from "../../components/uploadSingleImg";
import { UploadFoundImage } from "../../api/api"; //上传
import { FoundLineList } from "../../api/api"; //列表
import { UpperFoundLine } from "../../api/api"; //上架
import { LowerFoundLine } from "../../api/api"; //下架
import { DelFoundLine } from "../../api/api"; //删除
import { AddFoundLine } from "../../api/api"; //添加
import { EditFoundLine } from "../../api/api"; //修改
import { FoundLineInfo } from "../../api/api"; //详情

export default {
  components: {
    uoloadSingleImg,
    operationBtn
  },
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      content: "", //搜索框内容
      tableTip: "系统正在检索中…",
      list: [], //数据
      ruleForm: {
        name: "", //名称
        uploadPictures: [] //上传图片
      },
      rules: {
        name: [
          { required: true, message: "请输入类型名称", trigger: "change" }
        ],
        uploadPictures: [
          { required: true, message: "请上传背景图片", trigger: "change" }
        ]
      },
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
      //  上传
      dialogVisible: false, //弹框
      dialogTitle: "",
      tip: "请上传694*240尺寸的图片", //上传提示信息
      fileList: [], //默认显示图片
      danger: true,
      uploadUrl: "", //上传单一图片地址
      loading: false
    };
  },
  created() {
    this.uploadUrl = UploadFoundImage;
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(FoundLineList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无类型";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无类型";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无类型";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      //根据某种状态置灰
      if (row.status == "2") {
        return "freeze";
      }
    },
    addEdit(id, type) {
      //新增
      this.lineId = id;
      this.dialogTitle = "新增康复";
      this.clearForm();
      if (id == "") {
        //新增
        this.uoloadSingleStatus = "add";
        this.dialogTitle = "新增康复";
        this.dialogVisible = true;
      } else {
        //修改-查看
        this.$router.push({
          name: "contentRecoveryCheck",
          query: { lineId: id, lineType: type }
        });
      }
    },
    edit(id) {
      //编辑
      this.lineId = id;
      this.clearForm();
      var db = {
        line_id: id
      };
      this.$axios.post(FoundLineInfo, db).then(
        res => {
          if (res.data.code == 1) {
            this.dialogTitle = "康复信息";
            this.ruleForm.name = res.data.data.title;
            this.fileList = res.data.data.img;
            var imgUrl = [];
            if (this.fileList.length > 0) {
              for (var i = 0; i < this.fileList.length; i++) {
                imgUrl.push(this.fileList[i].url);
              }
            }
            this.ruleForm.uploadPictures = imgUrl.join(",");
            this.dialogVisible = true;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    },
    submitForm(formName) {
      //确定
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.lineId == "") {
            //新增
            postUrl = AddFoundLine;
            db = {
              title: this.ruleForm.name,
              img: this.ruleForm.uploadPictures
            };
          } else {
            //修改
            postUrl = EditFoundLine;
            db = {
              title: this.ruleForm.name,
              img: this.ruleForm.uploadPictures,
              line_id: this.lineId
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              this.loading = true;
              if (res.data.code == 1) {
                this.getList();
                this.dialogVisible = false;
                this.clearForm();
                this.loading = false;
              } else {
                this.$message.error(res.data.msg);
                this.loading = false;
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //接受图片子组件传回来的值
    childByValue(val, type) {
      this.fileList = [val];
      this.ruleForm.uploadPictures = val;
    },
    operation(operationId, operationName) {
      //对数据的操作-按钮
      this.operationPsotName = "line_id";
      this.operationId = operationId;
      if (operationName == "上架") {
        this.issue = "确定上架该治疗方式？";
        this.hintTxt = "上架后该康复可在用户端正常浏览";
        this.operationPsotUrl = UpperFoundLine;
      } else if (operationName == "下架") {
        this.issue = "确定下架该治疗方式？";
        this.hintTxt = "下架后该康复将从用户端下架隐藏";
        this.operationPsotUrl = LowerFoundLine;
      } else {
        //删除
        this.issue = "确定删除该治疗方式？";
        this.hintTxt = "删除前请将该治疗方式下的内容清空";
        this.operationPsotUrl = DelFoundLine;
      }
      this.showHide = true; //显示组件
    },
    childByOperationBtn: function(showHide, dataCode) {
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    },
    clearForm() {
      if (this.$refs["ruleForm"] != undefined) {
        this.$refs["ruleForm"].resetFields();
        this.fileList = [];
        this.ruleForm.uploadPictures = "";
      }
    }
  }
};
</script>

<style lang="scss">
</style>
