<template>
    <div>
        <!-- 数据大屏 -->
        <router-view/>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
