<template>
  <div ref="communal" class="communal">
    <div ref="topFixed" class="top-fixed">
      <div class="container clearfix">
        <div class="el-long">
          <div class="fr">
            <div class="i-block mr-10">
              <el-input v-model="search.txt" placeholder="请输入关键字搜索" size="small" style="display:inline-block;width:75%" />
            </div>
            <div class="i-block">
              <el-button type="success" size="small" plain @click="getList('search')">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="interval"></div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- <el-table ref="refTable" v-loading="loading" border :data="list"  style="width: 100%" :height="contentHeight"
         > -->
      <div class="container" :style="{height:contentHeight+'px'}">
        <el-table :data="list" style="width: 100%" :height="contentHeight" :empty-text="tableTip">

          <el-table-column prop="title" label="量表名称" min-width="200" show-overflow-tooltip />
          <el-table-column prop="typename" label="所属分类" min-width="120" show-overflow-tooltip />
          <el-table-column prop="price" label="价格(元)" min-width="80" show-overflow-tooltip />
          <el-table-column label="操作" fixed="right" width="120" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="tableBtn(scope.row,'编辑')">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div ref="paging" class="paging">
      <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 50, 100, 2000]" :page-size="psize" layout="total, sizes, prev, pager, next, jumper" :total="totals"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
    <!-- 编辑 -->
    <!-- <scaleEdit ref="scaleEdit" /> -->

    <!-- 编辑 -->
    <div>
      <el-dialog v-loading="loading" title="量表设置" :visible.sync="dialogVisible" width="450px" top="20%">
        <div style="padding:20px 30px 0">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
            <el-form-item label="量表名称：">
              {{ ruleForm.title }}
            </el-form-item>
            <el-form-item label="价格" prop="price">
              <el-input v-model="ruleForm.price" class="el-long" />
            </el-form-item>
            <el-form-item label="量表描述" prop="desc">
              <el-input v-model="ruleForm.desc" class="el-long" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <!-- 按钮 -->
        <div class="account-footer clearfix">
          <div>
            <el-button @click="handleClose">取 消</el-button>
          </div>
          <div>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          </div>
        </div>
      </el-dialog>
      <!--  end -->
    </div>
  </div>
</template>
<script>
import { ScaleTitleLists, ScaleTitleInfo, EditScaleTitle } from "../../api/api";
// import scaleEdit from './components/scaleEdit'
export default {
  components: {
    // scaleEdit
  },
  data() {
    var checkPrice2 = (rule, value, callback, text) => {
      this.common.Validate.checkPrice2(rule, value, callback, "费用");
    };
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      topHeight: "", // 顶部搜索的高度
      mainHeight: "", // 获取当前容器的高度
      pagingHeight: "", // 获取分页的高度
      contentHeight: 0, // 内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, // 默认显示页
      // 表格
      list: [{ name: "1" }],
      loading: false,
      // 搜索
      search: {
        txt: "",
      },
      tableTip: "系统正在检索中…",
      loading: false,
      dialogVisible: false,
      row: "", // 传递的数据
      ruleForm: {
        title: "",
        price: "",
        // keyword: ''
      },
      rules: {
        price: {
          required: true,
          trigger: ["blur", "change"],
          validator: checkPrice2,
        },
      },
    };
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.pages = "1";
      this.currentPage = 1;
      this.getList();
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        txt: this.search.txt, //搜索内容
      };
      console.log(db);
      this.$axios.post(ScaleTitleLists, db).then(
        (res) => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无账号记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无账号记录";
          }
        },
        (err) => {
          this.list = [];
          this.tableTip = "暂无账号记录";
        }
      );
    },
    tableBtn(row, btnName) {
      // 表格操作按钮、新增
      // console.log(row)
      if (btnName === "编辑") {
        this.dialogVisible = true;
        this.row = row;
        this.detail();
      }
    },
    handleClose() {
      this.row = "";
      this.loading = false;
      this.dialogVisible = false;
    },
    detail() {
      // 详情
      var db = {
        scale_id: this.row.scale_id,
      };
      this.$axios.post(ScaleTitleInfo, db).then(
        (res) => {
          this.loading = true;
          console.log(res);
          if (res.data.code == "1") {
            this.ruleForm = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.$message.error("网络异常、请重试");
        }
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var db = this.ruleForm;
          db.scale_id = this.row.scale_id;
          this.$axios.post(EditScaleTitle, db).then(
            (res) => {
              this.loading = true;
              if (res.data.code === "1") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getList();
                this.row = "";
                this.loading = false;
                this.clearForm();
                this.dialogVisible = false;
              } else {
                this.$message.error("网络异常、请重试");
                this.loading = false;
              }
            },
            (err) => {
              this.$message.error("网络异常、请重试");
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clearForm() {
      var form = {
        title: "",
        price: "",
        // keyword: ''
      };
      this.ruleForm = form;
    },
  },
};
</script>

<style lang="scss">
</style>
