<template>
    <!-- 咨询管理列表页面 -->
    <div id="userManageList" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="5">咨询日期：</el-col>
                    <el-col :span="4">咨询状态：</el-col>
                    <el-col :span="4">咨询类型：</el-col>
                    <el-col :span="4">咨询医生：</el-col>
                    <!-- <el-col :span="5">处理状态：</el-col> -->
                </el-row>
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="5">
                        <el-date-picker
                            v-model="form.dateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            style="width:70%;"
                            class="el-long"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <!-- <el-select
                            v-model="form.tag"
                            class="el-long"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in tags"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select> -->
                        <el-select
                            v-model="form.tag"
                            class="el-long"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in tags"
                                :key="item.status"
                                :label="item.name"
                                :value="item.status"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-select
                            v-model="form.type"
                            class="el-long"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option label="图文" value="1"></el-option>
                            <el-option label="远程" value="2"></el-option>
                            <el-option label="会诊" value="3"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <!-- <el-select
                            v-model="form.deal"
                            class="el-long"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in dealList"
                                :key="item.content"
                                :label="item.content"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <el-select
                            v-model="form.doctor_info_id"
                            class="el-long"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in doctorList"
                                :key="item.doctor_info_id"
                                :label="item.doctorname"
                                :value="item.doctor_info_id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-input
                            class="el-long"
                            v-model="form.txt"
                            placeholder="请输入关键字查询"
                            @keyup.enter.native="onSubmit"
                        ></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="primary" plain @click="onSubmit">搜索</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        style="width: 100%"
                        :height="contentHeight"
                        :cell-class-name="tableRowClassName"
                    >
                        <el-table-column prop="user_name" label="咨询账号" min-width="100"></el-table-column>
                        <el-table-column prop="telphone" label="手机号码" min-width="150"></el-table-column>
                        <el-table-column prop="doctor_name" label="咨询医生" min-width="100"></el-table-column>
                        <el-table-column prop="typename" label="咨询类型" min-width="100"></el-table-column>
                        <el-table-column prop="status" label="咨询状态" width="130">
                            <template slot-scope="scope">{{ scope.row.status == 1 ? '进行中' : '结束'}}</template>
                        </el-table-column>
                        <el-table-column prop="addtime" label="咨询开始时间" min-width="180"></el-table-column>
                        <el-table-column prop="lately_time" label="最近沟通时间" min-width="180"></el-table-column>
                        <el-table-column prop="end_time" label="咨询结束时间" min-width="180"></el-table-column>
                        <el-table-column prop="name" label="操作" fixed="right" min-width="100">
                            <template slot-scope="scope">
                                <el-button type="text" @click.stop="skipInfo(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <!-- 私聊 -->
            <chat
                ref="chat"
                :rightPage="rightPage"
                @closeMould="closeMould"
                :item="item"
            ></chat>
            <!-- 群聊 -->
            <!-- <groupChat
                ref="groupChat"
                :rightPage="rightPage"
                @closeMould="closeMould"
                :item="item"
            ></groupChat> -->
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { OrderStatus } from "../../api/api";
import { ConsultList } from "../../api/api";
import { Freeze } from "../../api/api";
import { UnFreeze } from "../../api/api";
import { UnAdmin } from "../../api/api";
import { SetAdmin } from "../../api/api";
import { SmsTemplates } from "../../api/api";
import { SendSms } from "../../api/api";
import { ExportLists } from "../../api/api";
import { DealList } from "../../api/api";
import { ConsultDoctorLists } from "../../api/api";
import { constants } from "crypto";
import chat from "../../components/chat/chat";
import groupChat from "../../components/chat/groupChat";
export default {
    components: {
        chat,
        groupChat,
    },
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form: {
                dateTime: [], //日期
                tag: "", //症状标签
                txt: "", //文字
                deal: "", //处理状态
                type: "",//类型
                doctor_info_id:"",//医生
            },
            doctorList:[],//咨询医生列表
            dealList: [], //咨询状态列表
            tags: [], //症状标签列表
            list: [], //表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip: "系统正在检索中…",
            groupOrChat: "",
            /**
             * 聊天组件
             */
            rightPage: false,
            item: {} //详情参数
            // item:{"consult_id":"108","user_id":"6","user_name":"daisy","telphone":"17557286224","doctor_name":"胡彤","doctor_info_id":"6","status":"1","status_text":"未结束","addtime":"2019.06.15 10:45:21","lately_time":"2019.06.21 11:23:48","end_time":"-"}
        };
    },
    created() {
        this.getTags();
        this.dealTags();
        this.getDoctor();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };
        this.getUserList();
    },
    methods: {
        //获取用户列表
        getUserList() {
            var that = this;
            var db = {
                from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                deal: this.form.deal,
                status: this.form.tag,
                txt: this.form.txt,
                pnums: this.psize,
                pages: this.pages,
                type: this.form.type,//类型
                doctor_info_id:this.form.doctor_info_id,//医生
            };
            this.$axios.post(ConsultList, db).then(
                res => {
                    if (res.data.code == 1) {
                        if (res.data.data.length > 0) {
                            this.list = res.data.data;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = "无咨询记录";
                        }
                    } else {
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = "无咨询记录";
                    }
                },
                err => {
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = "无相关结果";
                }
            );
        },
        //获取订单状态
        getTags() {
            var that = this;

            this.$axios.post(OrderStatus, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.tags = res.data.data;
                        } else {
                            this.tags = [];
                        }
                    } else {
                        this.tags = [];
                    }
                },
                err => {
                    this.tags = [];
                }
            );
        },
        //获取咨询医生
        getDoctor(){
            var that = this;

            this.$axios.post(ConsultDoctorLists, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.doctorList = res.data.data;
                        } else {
                            this.doctorList = [];
                        }
                    } else {
                        this.doctorList = [];
                    }
                },
                err => {
                    this.doctorList = [];
                }
            );
        },
        //获取处理状态
        dealTags() {
            var that = this;

            this.$axios.post(DealList, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.dealList = res.data.data;
                        } else {
                            this.dealList = [];
                        }
                    } else {
                        this.dealList = [];
                    }
                },
                err => {
                    this.dealList = [];
                }
            );
        },
        //点击表格行
        tableRowClick(row, index, e) {
            this.$refs.refTable.toggleRowExpansion(row);
        },
        tableRowClassName({ row, rowIndex, column, columnIndex }) {
            if (row.status == "1" && columnIndex == 3) {
                return "green";
            }
        },
        //查看
        skipInfo(db) {
            /**
             * 点击查看详情时
             *
             * 把模态框显示
             * 把参数赋值给子组件
             * 调用组件的获取高度方法
             * 调用组件的医生列表和用户列表
             */
            if (db.type == 1) {
                this.groupOrChat = 1;
            } else if (db.type == 3) {
                this.groupOrChat = 3;
            }
            this.rightPage = !this.rightPage;
            this.item = db;
            //图文、远程
            this.$refs.chat.getHeight();
            if (db.status == 1) {
                this.$refs.chat.getChatPersonList(this.item);
            } else {
                this.$refs.chat.getCount();
            }
            // if(db.type == 1 || db.type == 2){
            //     //图文、远程
            //     this.$refs.chat.getHeight();
            //     if (db.status == 1) {
            //         this.$refs.chat.getChatPersonList(this.item);
            //     } else {
            //         this.$refs.chat.getCount();
            //     }
            // }else {
            //     this.$refs.groupChat.getHeight();
            //     //群聊
            //     if (db.status == 1) {
            //         this.$refs.groupChat.getChatPersonList(this.item);
            //     } else {
            //         this.$refs.groupChat.getCount();
            //     }
            // }
            
        },
        //搜索
        onSubmit() {
            this.pages = 1;
            this.getUserList();
        },
        handleCurrentChange(val) {
            this.pages = val;
            this.getUserList();
        },
        handleSizeChange() {
            this.psize = val;
            this.getUserList();
        },

        /**
         * 聊天组件
         */
        closeMould(val) {
            this.rightPage = !this.rightPage;
            this.$refs.chat.logout();
            this.$refs.chat.removeEventListener();
            this.$refs.chat.list = [];
        }
    }
};
</script>
<style lang="scss">
// .label {
//     font-size: 14px;
//     color: #666666;
//     margin-right: 10px;
// }
// .search-btn {
//     position: absolute;
//     right: 0px;
//     bottom: 0px;
// }
// .table {
//     height: calc(100vh - 167px - 100px - 40px - 20px);
//     .el-icon-da {
//         width: 14px;
//         height: 14px;
//         margin-left: 5px;
//         // background: url('../../assets/image/case_icon.png');
//         vertical-align: middle;
//     }
//     .props-rows {
//         padding: 10px 0px 10px 45px;
//         .title {
//             color: #999999;
//             margin-right: 20px;
//         }
//         .rows-tag {
//             color: #666666;
//             margin-right: 20px;
//         }
//     }
// }
</style>
