<template>
    <el-dialog :visible="dialogVisible" class="chat-imgs" @close="closeModal">
        <div class="imgs-box">
            <img class="dialog-img" width="100%" :src="dialogImageUrl" alt="" >
            <i class="el-icon-error" @click="closeModal"></i>
        </div>
    </el-dialog>
</template>
<script>
import { FinishConsult } from "../../api/api";
import { scrypt } from "crypto";
export default {
    components:{
    },
    props: {
        dialogVisible:Boolean,//是否显示
        dialogImageUrl:String,//图片地址
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods:{  
        closeModal(){
            this.$emit('closeModal',false)
        },
    },
}
</script>
<style lang="scss">
</style>
